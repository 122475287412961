import React, { useCallback } from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import mixpanel from "mixpanel-browser"

import { actionCreators as userActions } from "../../ducks/user"
import LoginWidgetPresenter from "./LoginWidgetPresenter"

function LoginWidget({
  user,
  location,
  // actions
  logOut,
  doPush,
}) {
  function handleLogOut() {
    mixpanel.track("Log Out")

    logOut()
  }

  const handleSelectAccount = useCallback(() => {
    doPush({
      pathname: "/select-account",
      state: { from: location },
    })
  }, [doPush, location])

  return (
    <LoginWidgetPresenter
      user={user}
      onLogOut={handleLogOut}
      onSelectAccount={handleSelectAccount}
    />
  )
}

const mapStateToProps = ({ user: { user }, router: { location } }) => ({
  user,
  location,
})

export default connect(mapStateToProps, { doPush: push, ...userActions })(LoginWidget)
