import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import ReactPlaceholder from "react-placeholder"
import clsx from "clsx"
import { useDispatch } from "react-redux"

import { actionCreators as matchProductActions } from "../../../ducks/matches"

import styles from "./ProductComparisonTableBodyCell.module.scss"

import { ComparisonData } from "./ComparisonDataPropType"

import useCustomerMatchLabels from "../../../hooks/use-customer-match-labels"
import useRestricted from "../../../hooks/use-restricted"
import useShops from "../../../hooks/use-shops"

import { currencyToSymbol } from "../../../util/currencies"
import { ORIGIN_COMPARISON } from "../../../util/match-origin"
import { sourceCode2data } from "../../../util/match-source"
import { PERM_UPDATE_MATCHES } from "../../../util/permissions"
import { contentString, formatEan } from "../../../util/strings"
import { getBestProductContentV1, getContentsV1AsArray } from "../../../util/volumes"

import { NutrientsList } from "../../nutrients"
import FactorInput from "../../factor-input/FactorInput"
import NutriScore from "../../nutri-score"
import MultiSelect from "../../multi-select/MultiSelect"
import { stringToColor } from "../../../util/color"

const NAME_MAPPER = {
  adjusted: "Adjusted",
  brand: "Brand",
  matchType: "Match Type",
  comment: "Comments",
  content: "Content",
  country: "Region",
  description: "Description",
  ean: "EAN",
  ingredients: "Ingredients",
  matchLabel: "Match Label",
  nutrients: "Nutrients",
  nutriScore: "Nutri-Score",
  price: "Price",
  retailer: "Retailer",
  source: "Match Source",
  xFactor: "x Factor",
}

function Placeholder() {
  return (
    <ReactPlaceholder
      className="rounded"
      color="#f5f5f5"
      showLoadingAnimation
      style={{ width: "6rem", height: "4rem" }}
      type="rect"
    />
  )
}

function Adjusted(data, isMatch) {
  let productData = data?.ref?.data
  let multFactor = productData?.mult_factor
  let price = productData?.listings[0]?.price
  if (isMatch) {
    productData = data?.match?.data
    multFactor = productData?.matched?.mult_factor
    price = productData?.match?.price
  }
  const ajustedPrice = price * (multFactor || 0)

  return !ajustedPrice ? null : <strong>€{Number(ajustedPrice).toFixed(2)}</strong>
}

function Comment(data, isMatch) {
  const dispatch = useDispatch()
  const isUpdater = useRestricted({ requiredPermissions: PERM_UPDATE_MATCHES })
  const isDisabled = !data?.match?.data?.matched?.id
  const comment = isMatch
    ? data?.match?.data?.matched?.comment
    : data?.ref?.data?.comment
  const referenceProductId = data?.ref?.data?.id
  const daltixProductId = data?.match?.data?.match?.id

  const [commentText, setCommentText] = useState(comment)

  const handleCommentChange = ({ target: { value } }) => setCommentText(value)

  const onCommentChange = (newComment) => {
    dispatch(
      matchProductActions.updateMatchBasicInfo({
        source: ORIGIN_COMPARISON,
        referenceProductId,
        daltixProductId,
        comment: newComment,
      }),
    )
  }

  const handleBlur = () => {
    if (commentText !== comment) {
      const finalComment = commentText.trim() === "" ? "" : commentText
      onCommentChange(finalComment)
      setCommentText(finalComment)
    }
  }

  if (!isMatch) {
    return null
  }

  return (
    <div
      className={`quality-indicator-root ${isUpdater || !isDisabled ? "editable" : ""}`}
      style={{ paddingBottom: "2.5rem" }}
    >
      <div className="comment-container">
        <textarea
          disabled={!isUpdater || isDisabled}
          className="comment"
          placeholder="Add a comment"
          value={commentText}
          rows={2}
          onChange={handleCommentChange}
          onPaste={handleCommentChange}
          onBlur={handleBlur}
        />
      </div>
    </div>
  )
}

function Content(content) {
  return !content ? null : getContentsV1AsArray(content).map(contentString).join(" / ")
}

function EANS(eans) {
  return !eans ? null : (
    <div className={styles.scrollable}>
      {eans?.map((ean) => (
        <>
          {formatEan(ean)}
          <br />
        </>
      ))}
    </div>
  )
}

function MatchLabel(data, isMatch) {
  function multiSelectRenderer(label, selected, renderOptions) {
    if (selected.length === 0) {
      return <div className="labels-label">{label}</div>
    }

    return (
      <div className="labels-root">
        {selected
          .slice()
          .sort()
          .map((value) => (
            <span
              key={value}
              className="dx-label"
              style={{
                backgroundColor:
                  value === "do-not-align" ? "#999999" : stringToColor(value),
              }}
            >
              {/**
               * In case a customer requests the deletion of a label.
               * Some some products might still have that label.
               * We need to handle these cases by returning a null render
               */}
              {renderOptions.find(
                ({ label: optLabel, value: optValue }) =>
                  optValue === value || optLabel === value,
              )?.label ?? null}
            </span>
          ))}
      </div>
    )
  }

  const dispatch = useDispatch()
  const isUpdater = useRestricted({ requiredPermissions: PERM_UPDATE_MATCHES })
  const isDisabled = !data?.match?.data?.matched?.id
  const customerQualityLabels = useCustomerMatchLabels()
  const options = customerQualityLabels.map(({ alias, slug }) => ({
    label: alias,
    value: slug,
  }))
  const labels = data?.match?.data?.matched?.labels
  const optionSlugs = customerQualityLabels.map((x) => x.slug)
  const [contemporaryLabels, setLabels] = useState(
    labels?.filter((label) => optionSlugs.includes(label)) || [],
  )

  const referenceProductId = data?.ref?.data?.id
  const daltixProductId = data?.match?.data?.match?.id

  const handleLabelsChanged = ({ target: { value } }) => {
    dispatch(
      matchProductActions.updateMatchBasicInfo({
        source: ORIGIN_COMPARISON,
        referenceProductId,
        daltixProductId,
        labels: value,
      }),
    )
    setLabels(value)
  }

  if (!isMatch) {
    return null
  }

  return (
    <div
      className={`quality-indicator-root ${isUpdater || !isDisabled ? "editable" : ""}`}
    >
      <MultiSelect
        className="labels"
        label="select labels"
        value={contemporaryLabels}
        optionKey={({ value }) => value}
        optionLabelRenderer={({ label }) => label}
        options={options}
        displaySelectAllOption={false}
        onChange={handleLabelsChanged}
        renderValue={multiSelectRenderer}
        selectProps={{
          readOnly: !isUpdater || isDisabled,
        }}
      />
    </div>
  )
}

function MatchType(data, isMatch) {
  const matchType = isMatch
    ? data?.match?.data?.matched?.match_type
    : data?.ref?.data?.match_type

  return !matchType ? null : matchType
}

function Nutrients(nutrients) {
  return !nutrients ? null : <NutrientsList value={nutrients} noPadding />
}

function NutritionScore(nutriscore) {
  return !nutriscore ? null : <NutriScore value={nutriscore} />
}

function Price(data, isMatch) {
  let productData = data?.ref?.data
  let strike = false
  let price = productData?.listings[0]?.price
  if (isMatch) {
    productData = data?.match?.data?.match
    price = productData?.price
    const multFactor = data?.match?.data?.matched?.mult_factor
    strike = multFactor !== undefined && multFactor !== null && multFactor !== 1
  }
  if (strike) {
    return !price ? null : <s>€{price}</s>
  }

  return !price ? null : `€${price}`
}

function Retailer(shop) {
  const { getByCode } = useShops()
  return !shop ? null : getByCode(shop).name
}

function Source(data, isMatch) {
  const source = isMatch
    ? data?.match?.data?.matched?.match_source
    : data?.ref?.data?.match_source
  return !source ? null : (
    <span className={styles.badge}>
      {sourceCode2data(source).badgeLabel.slice(0, 3)}
    </span>
  )
}

function XFactor(data, isMatch) {
  const dispatch = useDispatch()
  const isUpdater = useRestricted({ requiredPermissions: PERM_UPDATE_MATCHES })
  const isDisabled = !data?.match?.data?.matched?.id

  const multFactor = data?.match?.data?.matched?.mult_factor
  const enforcedFactor = multFactor || 1
  const isNullFactor = multFactor === undefined || multFactor === null

  const refProd = data?.ref?.data
  const referenceProductId = refProd?.id
  const refProdName = refProd?.name
  const refProdImages = refProd?.images
  const listings = refProd?.listings[0]
  const refProdPrice = listings?.price
  const refProdCurrency = listings?.currency
  const refProdNormalizedUnit = refProd?.normalizedUnit
  const refProdNormalizedPrice = refProd?.normalizedPrice
  const refProdUrl = refProd?.url
  const refProdBrand = refProd?.brand
  const refProdContentsV1 = refProd?.contents_v1
  const refProdCurrencySymbol = currencyToSymbol(refProdCurrency)
  const refProdBestContent = useMemo(
    () => (refProdContentsV1 ? getBestProductContentV1(refProdContentsV1) : {}),
    [refProdContentsV1],
  )
  const {
    multiplier: refProdContentMultiplier = 1,
    value: refProdContentValue,
    unit: refProdContentUnit,
  } = refProdBestContent

  const dxProd = data?.match?.data?.match
  const daltixId = dxProd?.id
  const dxProdName = dxProd?.name
  const dxProdImages = dxProd?.images
  const dxProdUrl = dxProd?.url
  const dxProdPrice = dxProd?.price
  const dxProdCurrency = dxProd?.currency
  const dxProdBrand = dxProd?.brand
  const contentsV1 = dxProd?.contents_v1
  const dxProdCurrencySymbol = currencyToSymbol(dxProdCurrency)
  const dxProdBestContent = useMemo(
    () => (contentsV1 ? getBestProductContentV1(contentsV1) : {}),
    [contentsV1],
  )
  const {
    multiplier: dxProdContentMultiplier = 1,
    value: dxProdContentValue,
    unit: dxProdContentUnit,
  } = dxProdBestContent

  const handleFactorChange = (newFactor) => {
    dispatch(
      matchProductActions.updateMatchBasicInfo({
        daltixProductId: daltixId,
        factor: newFactor,
        referenceProductId,
        source: ORIGIN_COMPARISON,
      }),
    )
  }

  if (!isMatch) {
    return data?.ref?.data?.mult_factor
  }

  return (
    <div className="dx-factor">
      <span className="dx-factor-part">
        <FactorInput
          className="dx-factor-button"
          readOnly={!isUpdater || isDisabled}
          productName={refProdName}
          productPicture={refProdImages}
          productCurrency={refProdCurrencySymbol}
          productPrice={refProdPrice}
          productNormalizedPrice={refProdNormalizedPrice}
          productNormalizedUnit={refProdNormalizedUnit}
          productUnit={refProdContentUnit}
          productBrand={refProdBrand}
          productUrl={refProdUrl}
          matchName={dxProdName}
          matchUnit={dxProdContentUnit}
          matchPicture={dxProdImages}
          matchPrice={dxProdPrice}
          matchCurrency={dxProdCurrencySymbol}
          matchBrand={dxProdBrand}
          matchUrl={dxProdUrl}
          defaultProductMultiplier={refProdContentMultiplier}
          defaultProductContent={refProdContentValue}
          defaultMatchMultiplier={dxProdContentMultiplier}
          defaultMatchValue={dxProdContentValue}
          currentFactor={enforcedFactor}
          isNullFactor={isNullFactor}
          onChange={handleFactorChange}
        />
      </span>
    </div>
  )
}

function ShowData({ data, type, empty, isMatch }) {
  let value = null
  let capitalize = true

  let productData = data?.ref?.data
  if (isMatch) {
    productData = data?.match?.data?.match
  }

  switch (type) {
    case "adjusted":
      value = Adjusted(data, isMatch)
      break
    case "content":
      value = Content(productData?.contents_v1)
      break
    case "comment":
      value = Comment(data, isMatch)
      break
    case "country":
      capitalize = false
      value = productData?.country_array?.pop() || productData?.country
      break
    case "ingredients":
      capitalize = false
      value = productData?.ingredients
      break
    case "ean":
      value = EANS(productData?.eans)
      break
    case "matchLabel":
      value = MatchLabel(data, isMatch)
      break
    case "matchType":
      value = MatchType(data, isMatch)
      break
    case "nutrients":
      value = Nutrients(productData?.nutrients_v1)
      break
    case "nutriScore":
      value = NutritionScore(productData?.nutriscore || productData?.labels?.nutriscore)
      break
    case "price":
      value = Price(data, isMatch)
      break
    case "retailer":
      value = Retailer(productData?.shop_array?.pop() || productData?.shop)
      break
    case "source":
      value = Source(data, isMatch)
      break
    case "xFactor":
      capitalize = false
      value = XFactor(data, isMatch)
      break
    default:
      value = productData && productData[type]
      break
  }

  return (
    <div
      className={clsx(
        styles.info,
        type === "country" && styles.upper,
        value && capitalize && styles.capitalize,
      )}
    >
      {value || empty}
    </div>
  )
}

ShowData.propTypes = {
  data: ComparisonData.isRequired,
  type: PropTypes.string.isRequired,
  empty: PropTypes.string,
  isMatch: PropTypes.bool,
}

ShowData.defaultProps = {
  empty: " ",
  isMatch: false,
}

function MatchCell({ matchProduct, type }) {
  const isReady = matchProduct.match?.isReady

  if (!isReady) {
    return <Placeholder />
  }

  return (
    <div className={styles.cell}>
      <div className={styles.heading}>
        <h2> </h2>
      </div>
      <ShowData data={matchProduct} type={type} empty="-" isMatch />
    </div>
  )
}

MatchCell.propTypes = {
  matchProduct: ComparisonData.isRequired,
  type: PropTypes.string.isRequired,
}

function ReferenceProductCell({ refProduct, type }) {
  const isReady = refProduct.ref?.isReady

  if (!isReady) {
    return <Placeholder />
  }

  return (
    <div className={styles.cell}>
      <div className={styles.heading}>
        <h2>{NAME_MAPPER[type]}</h2>
      </div>
      <ShowData data={refProduct} type={type} />
    </div>
  )
}

ReferenceProductCell.propTypes = {
  refProduct: ComparisonData.isRequired,
  type: PropTypes.string.isRequired,
}

function ProductComparisonTableBodyCell({ data, isMatch, type }) {
  const outdated = isMatch && data?.match?.data?.match?.outdated

  return (
    <div className={clsx(styles.container, outdated && styles.outdated)}>
      {isMatch ? (
        <MatchCell matchProduct={data} type={type} />
      ) : (
        <ReferenceProductCell refProduct={data} type={type} />
      )}
    </div>
  )
}

ProductComparisonTableBodyCell.propTypes = {
  data: ComparisonData.isRequired,
  isMatch: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
}

export { ProductComparisonTableBodyCell }
