/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import Grid from "@material-ui/core/Grid"

import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"

import { AreaBar, ButtonGroup, Button, Icon } from "@daltix/ui-react"
import PropTypes from "prop-types"
import MultiSelect from "../multi-select/MultiSelect"
import MultiCategoriesSelector from "../matches-review-ref-prod-categories-selector"
import { matchStatusOptionsList } from "../../util/match-status"
import "./MatchesReviewFilterBar.sass"
import useShops from "../../hooks/use-shops"
import { matchProductAvailabilityOptionsList } from "../../util/product-availability"
import {
  defaultProductFactorValues,
  matchProductFactorOptionsList,
} from "../../util/product-factor"
import CountryItem from "./CountryItem"
import { ProductAutocompleteV2 } from "../product-autocomplete"

const MIXPANEL_REVIEW_MATCHES_FILTER_CHANGE = "MT - Review Matches Filter Change"

function MatchesReviewFilterBarPresenter({
  allMatchesShortcutText,
  approvedMatchesShortcutText,
  inReviewMatchesShortcutText,
  selectedMatchSources,
  selectedMatchStatuses,
  availableMatchCountries,
  selectedMatchCountries,
  availableMatchShops,
  selectedMatchShops,
  selectedMatchProductAvailabilities,
  selectedMatchProductFactors,
  refProdSearchTerm,
  refProdAutoCompleteSuggestions,
  matchSourceOptionsList,
  availableRefProdCountries,
  availableRefProdFamilies,
  selectedRefProdCountries,
  selectedRefProdFamilies,
  showFilters,
  // events
  onMatchSourceFilterChange,
  onMatchStatusFilterChange,
  onMatchCountryFilterChange,
  onMatchShopFilterChange,
  onMatchProductAvailabilityChange,
  onMatchProductFactorChange,
  onRefProdCountryFilterChange,
  onRefProdFamilyFilterChange,
  onRefProdSearchTermChange,
  onRefProdSuggestionsFetchRequested,
  onRefProdSuggestionsClearRequested,
  onRefProdSuggestionSelected,
  onClearFilters,
  onShortcutApproved,
  onShortcutInReview,
  onShortcutAllMatches,
  onToggleFilters,
}) {
  const { getByCode } = useShops()

  return (
    <AreaBar title="Review Matches" style={{ overflow: "visible" }}>
      <Button
        className="dx-area-bar-filter-button"
        size="s"
        iconRight={showFilters ? <Icon type="up" /> : <Icon type="down" />}
        onClick={() => onToggleFilters(!showFilters)}
      >
        Filter
      </Button>

      <div className="shortcuts">
        <span className="label">Quick filters:</span>
        <a href="#" title="See all valid matches" onClick={onShortcutAllMatches}>
          <span dangerouslySetInnerHTML={{ __html: allMatchesShortcutText }} />
        </a>
        |
        <a href="#" title="See approved matches" onClick={onShortcutApproved}>
          <span dangerouslySetInnerHTML={{ __html: approvedMatchesShortcutText }} />
        </a>
        |
        <a href="#" title="See matches to approve" onClick={onShortcutInReview}>
          <span dangerouslySetInnerHTML={{ __html: inReviewMatchesShortcutText }} />
        </a>
      </div>

      <ExpansionPanel
        classes={{
          root: "dx-clear-expansion-panel",
          expanded: "dx-expanded",
        }}
        expanded={showFilters}
      >
        <ExpansionPanelSummary />
        <ExpansionPanelDetails>
          <form onSubmit={(e) => e.preventDefault()} style={{ width: "100%" }}>
            <Grid container spacing={16} className="main-content-grid">
              <Grid item md={6} xs={12}>
                <fieldset className="filter-fieldset">
                  <legend>Reference Products Filters</legend>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
                  >
                    {availableRefProdCountries &&
                      availableRefProdCountries.length > 1 && (
                        <div
                          className="dx-form-group dx-form-group-inline"
                          style={{ marginTop: "auto" }}
                        >
                          <MultiSelect
                            className="country-select"
                            itemsClassName="filter-item"
                            label="Country"
                            options={availableRefProdCountries}
                            value={selectedRefProdCountries}
                            onChange={onRefProdCountryFilterChange}
                            optionKey={(country) => country}
                            optionLabelRenderer={(country) => (
                              <span>
                                <CountryItem code={country} />
                              </span>
                            )}
                            analyticsEventName="MT - Review Matches Ref. Filter Change"
                          />
                        </div>
                      )}

                    {availableRefProdFamilies &&
                      availableRefProdFamilies.length > 1 && (
                        <div
                          className="dx-form-group dx-form-group-inline"
                          style={{ marginTop: "auto" }}
                        >
                          <MultiSelect
                            className="country-select"
                            itemsClassName="filter-item"
                            label="Labels"
                            options={availableRefProdFamilies}
                            value={selectedRefProdFamilies}
                            onChange={onRefProdFamilyFilterChange}
                            optionKey={(family) => family}
                            optionLabelRenderer={(family) => <span>{family}</span>}
                          />
                        </div>
                      )}

                    <MultiCategoriesSelector className="category-selector" />
                    <div
                      className="dx-form-group dx-form-group-inline grow"
                      style={{ flexGrow: 1, minWidth: "25rem", marginTop: "4px" }}
                    >
                      <ProductAutocompleteV2
                        placeholder="Filter reference products by name, brand, article nº or EAN..."
                        inputProps={{
                          style: {
                            width: "100%",
                          },
                        }}
                        className="grow"
                        suggestions={refProdAutoCompleteSuggestions}
                        value={refProdSearchTerm}
                        onValueChange={onRefProdSearchTermChange}
                        onSuggestionsFetchRequested={onRefProdSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onRefProdSuggestionsClearRequested}
                        onSelected={onRefProdSuggestionSelected}
                      />
                    </div>
                  </div>
                </fieldset>
              </Grid>
              <Grid item md={6} xs={12}>
                <fieldset className="filter-fieldset">
                  <legend>Matches Filters</legend>
                  <div style={{ display: "flex", flexWrap: "wrap", minWidth: "100%" }}>
                    <div
                      className="dx-form-group dx-form-group-inline"
                      style={{ marginTop: "auto" }}
                    >
                      <MultiSelect
                        itemsClassName="filter-item"
                        label="Status"
                        options={matchStatusOptionsList}
                        value={selectedMatchStatuses}
                        onChange={onMatchStatusFilterChange}
                        optionKey={({ value }) => value}
                        optionLabelRenderer={({ label }) => <span>{label}</span>}
                        analyticsEventName={MIXPANEL_REVIEW_MATCHES_FILTER_CHANGE}
                      />
                    </div>
                    <div className="dx-form-group dx-form-group-inline">
                      <MultiSelect
                        itemsClassName="filter-item"
                        label="Source"
                        options={matchSourceOptionsList}
                        value={selectedMatchSources}
                        onChange={onMatchSourceFilterChange}
                        optionKey={({ value }) => value}
                        optionLabelRenderer={({ label, value }) => (
                          <span>
                            <span className={`dx-legend-box dx-legend-box-${value}`} />{" "}
                            {label}
                          </span>
                        )}
                        analyticsEventName={MIXPANEL_REVIEW_MATCHES_FILTER_CHANGE}
                      />
                    </div>
                    <div className="dx-form-group dx-form-group-inline">
                      <MultiSelect
                        itemsClassName="filter-item"
                        label="Country"
                        options={availableMatchCountries}
                        value={selectedMatchCountries}
                        onChange={onMatchCountryFilterChange}
                        optionKey={({ country }) => country}
                        optionLabelRenderer={({ country }) => (
                          <span>
                            <CountryItem code={country} />
                          </span>
                        )}
                        analyticsEventName={MIXPANEL_REVIEW_MATCHES_FILTER_CHANGE}
                      />
                    </div>
                    <div className="dx-form-group dx-form-group-inline">
                      <MultiSelect
                        itemsClassName="filter-item"
                        label="Shop"
                        options={availableMatchShops}
                        value={selectedMatchShops}
                        onChange={onMatchShopFilterChange}
                        optionKey={({ shop }) => shop}
                        optionLabelRenderer={({ shop: code }) => (
                          <span>{getByCode(code).name}</span>
                        )}
                        analyticsEventName={MIXPANEL_REVIEW_MATCHES_FILTER_CHANGE}
                      />
                    </div>
                    <div className="dx-form-group dx-form-group-inline">
                      <MultiSelect
                        itemsClassName="filter-item"
                        label="Availability"
                        options={matchProductAvailabilityOptionsList}
                        value={selectedMatchProductAvailabilities}
                        onChange={onMatchProductAvailabilityChange}
                        optionKey={({ value }) => value}
                        optionLabelRenderer={({ label }) => <span>{label}</span>}
                        analyticsEventName={MIXPANEL_REVIEW_MATCHES_FILTER_CHANGE}
                      />
                    </div>
                    <div className="dx-form-group dx-form-group-inline">
                      <MultiSelect
                        itemsClassName="filter-item"
                        label="Factor"
                        options={matchProductFactorOptionsList}
                        value={selectedMatchProductFactors}
                        onChange={onMatchProductFactorChange}
                        optionKey={({ value }) => value}
                        optionLabelRenderer={({ label }) => <span>{label}</span>}
                        analyticsEventName={MIXPANEL_REVIEW_MATCHES_FILTER_CHANGE}
                      />
                    </div>
                  </div>
                </fieldset>
              </Grid>
            </Grid>
            <ButtonGroup>
              <Button
                type="button"
                iconRight={<Icon type="eraser" />}
                onClick={onClearFilters}
              >
                Reset Filters
              </Button>
            </ButtonGroup>
          </form>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </AreaBar>
  )
}

MatchesReviewFilterBarPresenter.propTypes = {
  allMatchesShortcutText: PropTypes.string.isRequired,
  approvedMatchesShortcutText: PropTypes.string.isRequired,
  inReviewMatchesShortcutText: PropTypes.string.isRequired,
  selectedMatchSources: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedMatchStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableMatchCountries: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMatchCountries: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableMatchShops: PropTypes.arrayOf(PropTypes.object),
  selectedMatchShops: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedMatchProductAvailabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedMatchProductFactors: PropTypes.arrayOf(PropTypes.string).isRequired,
  refProdSearchTerm: PropTypes.string.isRequired,
  refProdAutoCompleteSuggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  matchSourceOptionsList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  availableRefProdCountries: PropTypes.arrayOf(PropTypes.string),
  availableRefProdFamilies: PropTypes.arrayOf(PropTypes.string),
  selectedRefProdCountries: PropTypes.arrayOf(PropTypes.string),
  selectedRefProdFamilies: PropTypes.arrayOf(PropTypes.string),
  showFilters: PropTypes.bool.isRequired,
  onMatchSourceFilterChange: PropTypes.func.isRequired,
  onMatchStatusFilterChange: PropTypes.func.isRequired,
  onMatchCountryFilterChange: PropTypes.func.isRequired,
  onMatchShopFilterChange: PropTypes.func.isRequired,
  onMatchProductAvailabilityChange: PropTypes.func.isRequired,
  onMatchProductFactorChange: PropTypes.func.isRequired,
  onRefProdCountryFilterChange: PropTypes.func.isRequired,
  onRefProdFamilyFilterChange: PropTypes.func.isRequired,
  onRefProdSearchTermChange: PropTypes.func.isRequired,
  onRefProdSuggestionsFetchRequested: PropTypes.func.isRequired,
  onRefProdSuggestionsClearRequested: PropTypes.func.isRequired,
  onRefProdSuggestionSelected: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onShortcutApproved: PropTypes.func.isRequired,
  onShortcutInReview: PropTypes.func.isRequired,
  onShortcutAllMatches: PropTypes.func.isRequired,
  onToggleFilters: PropTypes.func.isRequired,
}

MatchesReviewFilterBarPresenter.defaultProps = {
  availableMatchShops: undefined,
  availableRefProdCountries: undefined,
  availableRefProdFamilies: undefined,
  selectedRefProdFamilies: undefined,
  selectedRefProdCountries: undefined,
}

export default MatchesReviewFilterBarPresenter
