import {
  MATCH_GET_IDS,
  MATCH_GET_IDS_SUCCESS,
  MATCH_GET_IDS_ERROR,
  MATCH_LIST,
  MATCH_LIST_SUCCESS,
  MATCH_LIST_ERROR,
  MATCH_FILTER_BY_BRAND,
  MATCH_FILTER_BY_SHOP,
  MATCH_FILTER_BY_COUNTRY,
  MATCH_FILTER_BY_STATUS,
  MATCH_FILTER_BY_SOURCE,
  MATCH_FILTER_BY_AVAILABILITY,
  MATCH_LIST_COUNTRIES,
  MATCH_LIST_COUNTRIES_SUCCESS,
  MATCH_LIST_COUNTRIES_ERROR,
  MATCH_STATUS_SUMMARY,
  MATCH_STATUS_SUMMARY_SUCCESS,
  MATCH_STATUS_SUMMARY_ERROR,
  MATCH_CLEAR_LIST,
  MATCH_LIST_SOURCES,
  MATCH_LIST_SOURCES_SUCCESS,
  MATCH_LIST_SOURCES_ERROR,
  MATCH_SET_PAGE,
  MATCH_FILTER_BY_REF_PROD_QUERY,
  MATCH_FILTER_BY_REF_PROD_COUNTRY,
  MATCH_FILTER_BY_REF_PROD_FAMILY,
  MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES,
  MATCH_UPDATE_REF_PROD_L2_CATEGORIES,
  MATCH_UPDATE_REF_PROD_L3_CATEGORIES,
  MATCH_UPDATE_REF_PROD_L4_CATEGORIES,
  MATCH_SET_REVIEW_PAGE_SETTINGS,
  MATCH_GET_SHOPS_SUCCESS,
  MATCH_GET_SHOPS_ERROR,
  MATCH_UPDATE_BASIC,
  MATCH_APPROVE,
  MATCH_REMOVE_APPROVAL,
  MATCH_DISCARD,
  MATCH_RESTORE,
  MATCH_UPDATE_BASIC_SUCCESS,
  MATCH_APPROVE_SUCCESS,
  MATCH_REMOVE_APPROVAL_SUCCESS,
  MATCH_DISCARD_SUCCESS,
  MATCH_RESTORE_SUCCESS,
  MATCH_UPDATE_BASIC_ERROR,
  MATCH_APPROVE_ERROR,
  MATCH_DISCARD_ERROR,
  MATCH_RESTORE_ERROR,
  MATCH_REMOVE_APPROVAL_ERROR,
  MATCH_GET_REF_PROD_CATEGORIES,
  MATCH_GET_REF_PROD_CATEGORIES_SUCCESS,
  MATCH_GET_REF_PROD_CATEGORIES_ERROR,
  MATCH_FILTER_BY_FACTOR,
} from "./action-types"

import {
  REF_PRODUCT_LIST_EXISTING_MATCHES,
  REF_PRODUCT_LIST_EXISTING_MATCHES_SUCCESS,
  REF_PRODUCT_LIST_EXISTING_MATCHES_ERROR,
} from "../reference-products/action-types"

import {
  handleGetMatchesById,
  handleGetMatchesByIdSuccess,
  handleGetMatchesByIdError,
} from "./handlers/get-matches-by-id"

import {
  handleListMatches,
  handleListMatchesSuccess,
  handleListMatchesError,
} from "./handlers/list-matches"

import {
  handleFilterMatchesBySource,
  handleFilterMatchesByStatus,
  handleFilterMatchesByCountry,
  handleFilterMatchesByShop,
  handleFilterMatchesByBrand,
  handleFilterMatchesByAvailability,
  handleFilterMatchesByFactor,
  handleFilterMatchesByRefProdQuery,
  handleFilterMatchesByRefProdCountries,
  handleFilterMatchesByRefProdL1Categories,
  handleFilterMatchesByRefProdL2Categories,
  handleFilterMatchesByRefProdL3Categories,
  handleFilterMatchesByRefProdL4Categories,
  handleUpdateRefProdL2Categories,
  handleUpdateRefProdL3Categories,
  handleUpdateRefProdL4Categories,
  handleFilterMatchesByRefProdFamilies,
} from "./handlers/filter-matches"

import {
  handleUpdateMatchError,
  handleUpdateBasicMatchInfo,
  handleUpdateMatchStatus,
  handleUpdateMatchStatusSuccess,
  handleUpdateBasicMatchInfoSuccess,
} from "./handlers/update-match"

import {
  handleListProductMatches,
  handleListProductMatchesSuccess,
  handleListProductMatchesError,
} from "./handlers/product-match-list"

import {
  handleListCountriesWithMatches,
  handleListCountriesWithMatchesSuccess,
  handleListCountriesWithMatchesError,
} from "./handlers/list-countries"

import {
  handleGetMatchStatusSummary,
  handleGetMatchStatusSummarySuccess,
  handleGetMatchStatusSummaryError,
} from "./handlers/status-summary"

import { handleClearMatchesList } from "./handlers/clear-review-list"

import {
  handleListMatchSources,
  handleListMatchSourcesSuccess,
  handleListMatchSourcesError,
} from "./handlers/match-sources"

import { handleSetMatchListPage } from "./handlers/set-page"
import { defaultMatchStatusesForReview } from "../../util/match-status"
import { handleSetReviewPageSettings } from "./handlers/review-page-settings"
import {
  handleGetShopsFromMatchesError,
  handleGetShopsFromMatchesSuccess,
} from "./handlers/get-shops"

import { initalState as refProdCategoriesInitialState } from "../category"
import {
  handleGetReferenceProductsCategories,
  handleGetReferenceProductsCategoriesError,
  handleGetReferenceProductsCategoriesSuccess,
} from "./handlers/get-ref-prod-categories-tree"
import { getSettings } from "../../util/local-storage"
import { defaultProductAvailabilityValues } from "../../util/product-availability"

const settings = getSettings()

// reducer

const initialState = {
  list: [],
  selectedMatchSources: [],
  selectedMatchStatuses: defaultMatchStatusesForReview,
  availableMatchShops: [],
  selectedMatchShops: [],
  availableMatchCountries: [],
  selectedMatchCountries: [],
  selectedMatchProductAvailabilities:
    settings.pageReviewDefaultMatchedProductsAvailability ||
    defaultProductAvailabilityValues,
  selectedMatchFactors: [],
  validSources: [],
  l2CategoriesOptions: [],
  l3CategoriesOptions: [],
  l4CategoriesOptions: [],
  selectedL1Categories: [],
  selectedL2Categories: [],
  selectedL3Categories: [],
  selectedL4Categories: [],
  selectedRefProdCountries: [],
  matchIdInternalMap: {},
  matches: {},
  reviewPageSettings: {
    showFilters: true,
  },
  pendingChanges: {},
  page: undefined,
  total: undefined,
  referenceProductCategories: refProdCategoriesInitialState,
}

const handlerMap = {
  [MATCH_GET_REF_PROD_CATEGORIES]: handleGetReferenceProductsCategories,
  [MATCH_GET_REF_PROD_CATEGORIES_SUCCESS]: handleGetReferenceProductsCategoriesSuccess,
  [MATCH_GET_REF_PROD_CATEGORIES_ERROR]: handleGetReferenceProductsCategoriesError,

  [MATCH_STATUS_SUMMARY]: handleGetMatchStatusSummary,
  [MATCH_STATUS_SUMMARY_SUCCESS]: handleGetMatchStatusSummarySuccess,
  [MATCH_STATUS_SUMMARY_ERROR]: handleGetMatchStatusSummaryError,

  [MATCH_LIST_SOURCES]: handleListMatchSources,
  [MATCH_LIST_SOURCES_SUCCESS]: handleListMatchSourcesSuccess,
  [MATCH_LIST_SOURCES_ERROR]: handleListMatchSourcesError,

  [MATCH_LIST_COUNTRIES]: handleListCountriesWithMatches,
  [MATCH_LIST_COUNTRIES_SUCCESS]: handleListCountriesWithMatchesSuccess,
  [MATCH_LIST_COUNTRIES_ERROR]: handleListCountriesWithMatchesError,

  [MATCH_UPDATE_BASIC]: handleUpdateBasicMatchInfo,
  [MATCH_APPROVE]: handleUpdateMatchStatus,
  [MATCH_REMOVE_APPROVAL]: handleUpdateMatchStatus,
  [MATCH_DISCARD]: handleUpdateMatchStatus,
  [MATCH_RESTORE]: handleUpdateMatchStatus,

  [MATCH_UPDATE_BASIC_SUCCESS]: handleUpdateBasicMatchInfoSuccess,
  [MATCH_APPROVE_SUCCESS]: handleUpdateMatchStatusSuccess,
  [MATCH_REMOVE_APPROVAL_SUCCESS]: handleUpdateMatchStatusSuccess,
  [MATCH_DISCARD_SUCCESS]: handleUpdateMatchStatusSuccess,
  [MATCH_RESTORE_SUCCESS]: handleUpdateMatchStatusSuccess,

  [MATCH_UPDATE_BASIC_ERROR]: handleUpdateMatchError,
  [MATCH_APPROVE_ERROR]: handleUpdateMatchError,
  [MATCH_REMOVE_APPROVAL_ERROR]: handleUpdateMatchError,
  [MATCH_DISCARD_ERROR]: handleUpdateMatchError,
  [MATCH_RESTORE_ERROR]: handleUpdateMatchError,

  [MATCH_GET_SHOPS_SUCCESS]: handleGetShopsFromMatchesSuccess,
  [MATCH_GET_SHOPS_ERROR]: handleGetShopsFromMatchesError,

  [MATCH_GET_IDS]: handleGetMatchesById,
  [MATCH_GET_IDS_SUCCESS]: handleGetMatchesByIdSuccess,
  [MATCH_GET_IDS_ERROR]: handleGetMatchesByIdError,

  [MATCH_SET_PAGE]: handleSetMatchListPage,

  [MATCH_LIST]: handleListMatches,
  [MATCH_LIST_SUCCESS]: handleListMatchesSuccess,
  [MATCH_LIST_ERROR]: handleListMatchesError,

  [MATCH_CLEAR_LIST]: handleClearMatchesList,

  [REF_PRODUCT_LIST_EXISTING_MATCHES]: handleListProductMatches,
  [REF_PRODUCT_LIST_EXISTING_MATCHES_SUCCESS]: handleListProductMatchesSuccess,
  [REF_PRODUCT_LIST_EXISTING_MATCHES_ERROR]: handleListProductMatchesError,

  [MATCH_FILTER_BY_SOURCE]: handleFilterMatchesBySource,
  [MATCH_FILTER_BY_STATUS]: handleFilterMatchesByStatus,
  [MATCH_FILTER_BY_COUNTRY]: handleFilterMatchesByCountry,
  [MATCH_FILTER_BY_SHOP]: handleFilterMatchesByShop,
  [MATCH_FILTER_BY_AVAILABILITY]: handleFilterMatchesByAvailability,
  [MATCH_FILTER_BY_FACTOR]: handleFilterMatchesByFactor,
  [MATCH_FILTER_BY_BRAND]: handleFilterMatchesByBrand,
  [MATCH_FILTER_BY_REF_PROD_QUERY]: handleFilterMatchesByRefProdQuery,
  [MATCH_FILTER_BY_REF_PROD_COUNTRY]: handleFilterMatchesByRefProdCountries,
  [MATCH_FILTER_BY_REF_PROD_FAMILY]: handleFilterMatchesByRefProdFamilies,
  [MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES]: handleFilterMatchesByRefProdL1Categories,
  [MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES]: handleFilterMatchesByRefProdL2Categories,
  [MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES]: handleFilterMatchesByRefProdL3Categories,
  [MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES]: handleFilterMatchesByRefProdL4Categories,

  [MATCH_UPDATE_REF_PROD_L2_CATEGORIES]: handleUpdateRefProdL2Categories,
  [MATCH_UPDATE_REF_PROD_L3_CATEGORIES]: handleUpdateRefProdL3Categories,
  [MATCH_UPDATE_REF_PROD_L4_CATEGORIES]: handleUpdateRefProdL4Categories,

  [MATCH_SET_REVIEW_PAGE_SETTINGS]: handleSetReviewPageSettings,
}

const reducer = (state = initialState, { type, ...action }) => {
  const enhancedState = {
    ...initialState,
    ...state,
  }

  if (handlerMap[type]) {
    return handlerMap[type](enhancedState, action)
  }

  return enhancedState
}

export default reducer
