import { useCallback, useMemo } from "react"
import useCustomer from "./use-customer"
import { titleCase } from "../util/strings"

function useShops() {
  const { shops = [] } = useCustomer({
    defaultValue: {},
  })

  const items = useMemo(
    () =>
      shops.reduce((acc, { shop, name, logo }) => {
        acc[shop] = { logo, name }

        return acc
      }, {}),
    [shops],
  )

  const getByCode = useCallback(
    (code, fallback = true) => {
      const item = items[code]

      // HACK: this should be removed once the data has been corrected
      if (code === "clp") {
        item.name = "Colruyt Lowest Prices"
        return item
      }
      if (code === "spar") {
        item.logo = "https://i.imgur.com/7Z8UO8m.png"
        return item
      }

      if (!item && fallback) {
        return {
          name: titleCase(code),
          logo: undefined,
        }
      }

      return item
    },
    [items],
  )

  return { items, getByCode }
}

export default useShops
