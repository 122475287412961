import React from "react"

import { Tooltip } from "@mui/material"
import { bool, oneOf, string } from "prop-types"
import * as match from "../../util/match-status"
import * as availability from "../../util/product-availability"
import {
  AvailabilityActive,
  AvailabilityInactive,
  Caret,
  CompareOutlined,
  FlagFilled,
  FlagOutlined,
  OpenInNew,
  StatusApproved,
  StatusApprovedOutlined,
  StatusDiscarded,
  StatusDiscardedOutlined,
  StatusInReview,
  StatusUnmatched,
} from "./CustomIcons"

const sizes = {
  small: 16,
  medium: 20,
}

const paddings = {
  small: 0,
  medium: "2px",
}

const icons = {
  [availability.AVAILABILITY_ACTIVE]: AvailabilityActive,
  [availability.AVAILABILITY_INACTIVE]: AvailabilityInactive,
  [match.STATUS_APPROVED]: StatusApproved,
  [match.STATUS_REVIEW]: StatusInReview,
  [match.STATUS_DISCARDED]: StatusDiscarded,
  [match.STATUS_UNMATCHED]: StatusUnmatched,
  caret: Caret,
  openInNew: OpenInNew,
}

const outlinedIcons = {
  [match.STATUS_APPROVED]: StatusApprovedOutlined,
  [match.STATUS_DISCARDED]: StatusDiscardedOutlined,
  compare: CompareOutlined,
  flag: FlagOutlined,
}

const filledIcons = {
  flag: FlagFilled,
}

/**
 * @typedef { object } Props
 * @property { keyof typeof icons | keyof typeof outlinedIcons } type
 * @property { "small" | "medium" } [size]
 * @property { string } [tooltip]
 * @property { boolean } [outlined]
 * @property { boolean } [filled]
 * @property { string } [testID]
 * @param { Props } props
 */
function DXIcon({ type, size, tooltip, outlined, testID, color, filled, ...props }) {
  const dimension = sizes[size] ?? size
  const padding = paddings[size] ?? 0

  /** @type import("@mui/material/SvgIcon/SvgIcon") */
  const SVGIcon = outlined
    ? outlinedIcons[type]
    : filled
      ? filledIcons[type]
      : icons[type]

  if (!SVGIcon) {
    return null
  }

  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <SVGIcon
        {...props}
        color={color}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: dimension,
          padding,
          width: dimension,
        }}
        data-testid={testID || type}
        viewBox={`0 0 ${dimension} ${dimension}`}
      />
    </Tooltip>
  )
}

DXIcon.propTypes = {
  outlined: bool,
  tooltip: string,
  type: oneOf([...Object.keys(icons), ...Object.keys(outlinedIcons)]).isRequired,
  size: oneOf(["small", "medium"]),
  testID: string,
  color: string,
  filled: bool,
}

DXIcon.defaultProps = {
  filled: false,
  outlined: false,
  tooltip: "",
  testID: "",
  size: "medium",
  color: "inherit",
}

export { DXIcon }
