import { STATUS_APPROVED, STATUS_REVIEW } from "../../../util/match-status"

export const PRODUCT_SEARCH_WIDGET_KEY = "ProductSearchWidget"

export const PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY = "referenceProductId"
export const PRODUCT_SEARCH_WIDGET_REF_PROD_QUERY_KEY = "refProdQuery"

export const PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY = "referenceProductList"
export const PRODUCT_SEARCH_WIDGET_REF_PROD_TOTAL_KEY = "referenceProductTotal"
export const PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY = "referenceProductPage"

export const PRODUCT_SEARCH_WIDGET_SEARCHING_KEY = "searchInProgress"
export const PRODUCT_SEARCH_WIDGET_PRODUCTS_LIST_KEY = "results"
export const PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY = "total"
export const PRODUCT_SEARCH_WIDGET_PRODUCTS_FILTERS_KEY = "searchResultsFilters"
export const PRODUCT_SEARCH_WIDGET_PRODUCTS_PAGE_KEY = "currentPage"
export const PRODUCT_SEARCH_WIDGET_PRODUCTS_QUERY_KEY = "query"

export const PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS = "selectedFilters"
export const PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY = "searchFilterOptions"
export const PRODUCT_SEARCH_WIDGET_PRODUCTS_NO_FILTERS_TOTAL_KEY =
  "noFilterTotalSearchResults"

export const PRODUCT_SEARCH_WIDGET_MATCHES_KEY = "matches"
export const PRODUCT_SEARCH_WIDGET_MATCHES_TOTAL_KEY = "totalMatches"

export const PRODUCT_SEARCH_WIDGET_REF_PROD_SUMMARY_KEY = "referenceProductSummary"

export const EMPTY_FILTERS = {
  status: {},
  availabilities: {},
  brands: {},
  source: {},
  /** Retailers */
  shops: {},
  countries: {},
  factors: {},
  minPrice: undefined,
  maxPrice: undefined,
  content: {
    max: undefined,
    min: undefined,
    unit: undefined,
  },
  minFactor: undefined,
  maxFactor: undefined,
  onlyWithPrice: false,
  onlyWithContent: false,
  onlyWithMF: false,
}

export const INITIAL_FILTERS = {
  ...EMPTY_FILTERS,
  status: { [STATUS_APPROVED]: true, [STATUS_REVIEW]: true },
  availabilities: { active: true },
}
