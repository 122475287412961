import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Route, withRouter } from "react-router-dom"
import { push } from "connected-react-router"
import useRestricted, {
  defaultRequiredFeatureFlags,
  defaultRequiredFeatures,
  defaultRequiredPermissions,
} from "../hooks/use-restricted"

function RestrictedRoute({
  component: Component,
  path,
  location,
  requiredFeatureFlags,
  requiredFeatures,
  requiredPermissions,
  errorRoute,
  ...rest
}) {
  const dispatch = useDispatch()

  const hasAccess = useRestricted({
    requiredFeatureFlags,
    requiredFeatures,
    requiredPermissions,
  })

  useEffect(() => {
    if (!hasAccess) {
      dispatch(push(errorRoute))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAccess, dispatch])

  const render = (props) => (hasAccess ? <Component {...props} /> : null)

  return <Route path={path} render={render} {...rest} />
}

RestrictedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object])
    .isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
    .isRequired,
  errorRoute: PropTypes.string,
  requiredFeatureFlags: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ),
  ]),
  requiredFeatures: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ),
  ]),
  requiredPermissions: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ),
  ]),
}

RestrictedRoute.defaultProps = {
  requiredFeatureFlags: defaultRequiredFeatureFlags,
  requiredFeatures: defaultRequiredFeatures,
  requiredPermissions: defaultRequiredPermissions,
  errorRoute: "/access-denied",
}

export default withRouter(RestrictedRoute)
