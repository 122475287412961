import React, { Suspense, memo } from "react"
import PropTypes from "prop-types"
import { Switch } from "react-router-dom"
import Header from "../../components/header/Header"
import HtmlTitle from "../../components/html-title/HtmlTitle"
import AssortmentAnalysisPanel from "../../components/assortment-analysis/AssortmentAnalysisPanel"
import RestrictedRoute from "../../hocs/restricted-route"

function AssortmentToolPresenter({ routes }) {
  return (
    <>
      <Header className="page-header" title="Assortment" />
      <HtmlTitle>Assortment Tool</HtmlTitle>
      <Suspense>
        <Switch>
          <RestrictedRoute path={routes.analysis} component={AssortmentAnalysisPanel} />
        </Switch>
      </Suspense>
    </>
  )
}

AssortmentToolPresenter.propTypes = {
  routes: PropTypes.shape({
    analysis: PropTypes.string.isRequired,
  }).isRequired,
}

export default memo(AssortmentToolPresenter)
