import queryString from "query-string"
import {
  map,
  withLatestFrom,
  filter,
  debounceTime,
  mergeMap,
  switchMap,
  take,
  mapTo,
  tap,
} from "rxjs/operators"
import { ofType } from "redux-observable"
import { from, of } from "rxjs"
import { LOCATION_CHANGE, push, replace } from "connected-react-router"
import mixpanel from "mixpanel-browser"
import {
  MATCH_LIST,
  MATCH_GET_IDS,
  MATCH_LIST_COUNTRIES,
  MATCH_STATUS_SUMMARY,
  MATCH_LIST_SOURCES,
  MATCH_TRIGGER_LIST,
  MATCH_SET_PAGE,
  MATCH_FILTER_BY_SOURCE,
  MATCH_FILTER_BY_STATUS,
  MATCH_FILTER_BY_COUNTRY,
  MATCH_FILTER_BY_SHOP,
  MATCH_FILTER_BY_AVAILABILITY,
  MATCH_FILTER_BY_BRAND,
  MATCH_FILTER_BY_FACTOR,
  MATCH_FILTER_BY_REF_PROD_QUERY,
  MATCH_START_FILTER_BY_REF_PROD_COUNTRY,
  MATCH_START_FILTER_BY_REF_PROD_FAMILY,
  MATCH_FILTER_BY_REF_PROD_COUNTRY,
  MATCH_FILTER_BY_REF_PROD_FAMILY,
  MATCH_START_FILTER_BY_REF_PROD_L1_CATEGORIES,
  MATCH_START_FILTER_BY_REF_PROD_L2_CATEGORIES,
  MATCH_START_FILTER_BY_REF_PROD_L3_CATEGORIES,
  MATCH_START_FILTER_BY_REF_PROD_L4_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES,
  MATCH_SET_REVIEW_PAGE_SETTINGS,
  MATCH_CLEAR_LIST,
  MATCH_GET_SHOPS,
  MATCH_CREATE_MANUAL,
  MATCH_UPDATE_BASIC,
  MATCH_APPROVE,
  MATCH_REMOVE_APPROVAL,
  MATCH_DISCARD,
  MATCH_RESTORE,
  MATCH_APPROVE_SUCCESS,
  MATCH_REMOVE_APPROVAL_SUCCESS,
  MATCH_DISCARD_SUCCESS,
  MATCH_RESTORE_SUCCESS,
  MATCH_CREATE_MANUAL_SUCCESS,
  MATCH_UPDATE_BASIC_SUCCESS,
  MATCH_GET_REF_PROD_CATEGORIES,
  MATCH_GET_REF_PROD_CATEGORIES_SUCCESS,
  MATCH_UPDATE_REF_PROD_L2_CATEGORIES,
  MATCH_UPDATE_REF_PROD_L3_CATEGORIES,
  MATCH_UPDATE_REF_PROD_L4_CATEGORIES,
} from "./action-types"
import { buildEpic, actionCreators } from "../../util/redux-observable-helpers"
import { PAGE_SIZE } from "./common"
import * as api from "../../api/service"
import {
  listMatches,
  clearMatchesList,
  setMatchesListPage,
  filterMatchesBySource,
  filterMatchesByStatus,
  filterMatchesByCountry,
  filterMatchesByShop,
  filterMatchesByRefProdL1Categories,
  filterMatchesByRefProdL2Categories,
  filterMatchesByRefProdL3Categories,
  filterMatchesByRefProdL4Categories,
  updateRefProdL2Categories,
  updateRefProdL3Categories,
  updateRefProdL4Categories,
  startFilterMatchesByRefProdL1Categories,
  startFilterMatchesByRefProdL2Categories,
  startFilterMatchesByRefProdL3Categories,
  startFilterMatchesByRefProdL4Categories,
  filterMatchesByRefProdQuery,
  startFilterMatchesByRefProdCountries,
  filterMatchesByRefProdCountries,
  filterMatchesByRefProdFamilies,
  setMatchesReviewPageSettings,
  getShopsFromMatches,
  filterMatchesByAvailability,
  filterMatchesByFactor,
  startFilterMatchesByRefProdFamilies,
} from "./action-creators"

import { actionTypes as urlActionTypes, actionCreators as urlActions } from "../url"
import {
  QP_CATEGORY_1,
  QP_CATEGORY_2,
  QP_CATEGORY_3,
  QP_CATEGORY_4,
  QP_FACTOR,
  QP_REF_PROD_COUNTRY,
  QP_REF_PROD_FAMILY,
  QP_QUERY,
  QP_COUNTRY,
  QP_PAGE,
  QP_SHOP,
  QP_SOURCE,
  QP_STATUS,
  QP_FILTERS,
  QP_AVAILABILITY,
} from "../../util/query-param"
import { forceArray } from "../../util/array"
import { updateLocationQuery } from "../../util/location"
import { NULL } from "../../util/constants"
import { toBool } from "../../util/boolean"
import { REF_PRODUCT_SUMMARY_SUCCESS } from "../reference-products/action-types"
import { isInPage, withParent } from "../../util/epics"
import { getOneMatchedProductById } from "../repositories/matched-products/action-creators"

// epics

const listReferenceProductsCategoriesEpic = buildEpic(
  MATCH_GET_REF_PROD_CATEGORIES,
  () => api.listMatchedReferenceProductsCategories(),
)

const getMatchStatusSummaryEpic = buildEpic(MATCH_STATUS_SUMMARY, () =>
  api.getMatchStatusSummary(),
)

const listMatchSourcesEpic = buildEpic(MATCH_LIST_SOURCES, () => api.getMatchSources())

const listCountriesWithMatchesEpic = buildEpic(MATCH_LIST_COUNTRIES, () =>
  api.listCountriesWithMatches(),
)

const createManualMatchEpic = buildEpic(
  MATCH_CREATE_MANUAL,
  ({ referenceProductId, daltixProductId, shop, country, factor }) =>
    api.upsertManualMatch({
      referenceProductId,
      daltixProductId,
      shop,
      country,
      factor,
    }),
)

const updateMatchBasicInfoEpic = buildEpic(
  MATCH_UPDATE_BASIC,
  ({ referenceProductId, daltixProductId, factor, labels, comment }) =>
    api.updateMatchBasicInfo({
      referenceProductId,
      daltixProductId,
      factor,
      labels,
      comment,
    }),
)

const approveMatchEpic = buildEpic(
  MATCH_APPROVE,
  ({ referenceProductId, daltixProductId }) =>
    api.approveMatch({
      referenceProductId,
      daltixProductId,
    }),
)

const removeMatchApprovalEpic = buildEpic(
  MATCH_REMOVE_APPROVAL,
  ({ referenceProductId, daltixProductId }) =>
    api.removeMatchApproval({
      referenceProductId,
      daltixProductId,
    }),
)

const discardMatchEpic = buildEpic(
  MATCH_DISCARD,
  ({ referenceProductId, daltixProductId }) =>
    api.discardMatch({
      referenceProductId,
      daltixProductId,
    }),
)

const restoreMatchEpic = buildEpic(
  MATCH_RESTORE,
  ({ referenceProductId, daltixProductId }) =>
    api.restoreMatch({
      referenceProductId,
      daltixProductId,
    }),
)

const getMatchesByIdEpic = buildEpic(MATCH_GET_IDS, ({ ids }) =>
  api.getMatches(ids.map((id) => ({ id }))),
)

const triggerListMatchesEpic = (action$, state$) =>
  action$.pipe(
    filter(() => {
      const {
        router: {
          location: { pathname },
        },
        user: { user },
        matches: {
          referenceProductCategories: {
            loadingCategoriesTree: loadingRefProdCategoriesTree,
          },
        },
      } = state$.value

      return (
        user !== undefined &&
        !loadingRefProdCategoriesTree &&
        pathname.startsWith("/matches/review")
      )
    }),
    ofType(
      MATCH_TRIGGER_LIST,
      MATCH_SET_PAGE,
      MATCH_FILTER_BY_SOURCE,
      MATCH_FILTER_BY_STATUS,
      MATCH_FILTER_BY_COUNTRY,
      MATCH_FILTER_BY_BRAND,
      MATCH_FILTER_BY_SHOP,
      MATCH_FILTER_BY_AVAILABILITY,
      MATCH_FILTER_BY_FACTOR,
      MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_QUERY,
      MATCH_FILTER_BY_REF_PROD_COUNTRY,
      MATCH_FILTER_BY_REF_PROD_FAMILY,
    ),
    debounceTime(500),
    map((action) => withParent(action, listMatches())),
  )

const listMatchesEpic = buildEpic(
  MATCH_LIST,
  (
    _action$,
    // global state
    {
      matches: {
        page,
        selectedMatchCountries,
        selectedMatchShops,
        selectedMatchBrands,
        selectedMatchStatuses,
        selectedMatchSources,
        selectedMatchProductAvailabilities,
        selectedMatchProductFactors,
        selectedL1Categories,
        selectedL2Categories,
        selectedL3Categories,
        selectedL4Categories,
        refProdQuery,
        selectedRefProdCountries,
        selectedRefProdFamilies,
      },
    },
  ) =>
    api.listMatches({
      page,
      pageSize: PAGE_SIZE,
      sources: selectedMatchSources,
      statuses: selectedMatchStatuses.filter((status) => status !== NULL),
      countries: selectedMatchCountries,
      shops: selectedMatchShops,
      brands: selectedMatchBrands,
      availabilities: selectedMatchProductAvailabilities,
      factors: selectedMatchProductFactors,
      refProdCountries: selectedRefProdCountries,
      refProdFamilies: selectedRefProdFamilies,
      refProdQuery,
      categories: selectedL1Categories,
      l2Categories: selectedL2Categories,
      l3Categories: selectedL3Categories,
      l4Categories: selectedL4Categories,
    }),
  undefined, // validation
  true, // override previous calls
)

const triggerGetShopsFromMatchesEpic = (action$) =>
  action$.pipe(ofType(MATCH_LIST), mapTo(getShopsFromMatches()))

const getShopsFromMatchesEpic = buildEpic(
  MATCH_GET_SHOPS,
  (
    // action
    _,
    // global state
    {
      matches: {
        selectedMatchCountries,
        selectedMatchBrands,
        selectedMatchStatuses,
        selectedMatchSources,
        selectedMatchProductAvailabilities,
        selectedMatchProductFactors,
        selectedL1Categories,
        selectedL2Categories,
        selectedL3Categories,
        selectedL4Categories,
        refProdQuery,
        selectedRefProdCountries,
        selectedRefProdFamilies,
      },
    },
  ) =>
    api.getShopsFromMatches({
      sources: selectedMatchSources,
      statuses: selectedMatchStatuses.filter((status) => status !== NULL),
      countries: selectedMatchCountries,
      brands: selectedMatchBrands,
      availabilities: selectedMatchProductAvailabilities,
      factors: selectedMatchProductFactors,
      refProdCountries: selectedRefProdCountries,
      refProdFamilies: selectedRefProdFamilies,
      refProdQuery,
      categories: selectedL1Categories,
      l2Categories: selectedL2Categories,
      l3Categories: selectedL3Categories,
      l4Categories: selectedL4Categories,
    }),
  undefined, // validation
  true, // override previous calls
)

const loadMatchDataEpic = (action$, _state$) =>
  action$.pipe(
    ofType(
      MATCH_CREATE_MANUAL_SUCCESS,
      MATCH_UPDATE_BASIC_SUCCESS,
      MATCH_APPROVE_SUCCESS,
      MATCH_REMOVE_APPROVAL_SUCCESS,
      MATCH_DISCARD_SUCCESS,
      MATCH_RESTORE_SUCCESS,
    ),
    map(({ payload = {} }) =>
      getOneMatchedProductById({
        id: payload.result?.matchIds[0],
        invalidateCache: true,
      }),
    ),
  )

const clearMatchesReviewListEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      // MATCH_TRIGGER_LIST,
      MATCH_FILTER_BY_SOURCE,
      MATCH_FILTER_BY_STATUS,
      MATCH_FILTER_BY_COUNTRY,
      MATCH_FILTER_BY_BRAND,
      MATCH_FILTER_BY_SHOP,
      MATCH_FILTER_BY_AVAILABILITY,
      MATCH_FILTER_BY_FACTOR,
      MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_QUERY,
      MATCH_FILTER_BY_REF_PROD_COUNTRY,
      MATCH_FILTER_BY_REF_PROD_FAMILY,
    ),
    filter(() => isInPage("/matches/review", state$.value)),
    debounceTime(100),
    map((action) => ({
      ...clearMatchesList(action.options),
      parent: action,
    })),
  )

const updateMatchesListEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      MATCH_APPROVE_SUCCESS,
      MATCH_REMOVE_APPROVAL_SUCCESS,
      MATCH_DISCARD_SUCCESS,
      MATCH_RESTORE_SUCCESS,
    ),
    withLatestFrom(state$),
    map(
      ([
        {
          parent: { reloadList },
        },
        {
          matches: { page },
        },
      ]) => {
        if (reloadList) {
          return listMatches({
            page,
            reload: true,
          })
        }

        return actionCreators.doNothing()
      },
    ),
  )

const startFilterMatchesByRefProdL1CategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(MATCH_START_FILTER_BY_REF_PROD_L1_CATEGORIES),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(
          ofType(MATCH_GET_REF_PROD_CATEGORIES_SUCCESS),
          take(1),
          mapTo(action),
        )
      }

      return of(action)
    }),
    map(({ categories, options }) =>
      filterMatchesByRefProdL1Categories(
        categories,
        state$.value.matches.referenceProductCategories.categoriesTree,
        options,
      ),
    ),
  )

const startFilterMatchesByRefProdL2CategoriesEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_START_FILTER_BY_REF_PROD_L2_CATEGORIES),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(
          ofType(MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES),
          take(1),
          mapTo(action),
        )
      }

      return of(action)
    }),
    map(({ categories, options }) =>
      filterMatchesByRefProdL2Categories(categories, options),
    ),
  )

const startFilterMatchesByRefProdL3CategoriesEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_START_FILTER_BY_REF_PROD_L3_CATEGORIES),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(
          ofType(MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES),
          take(1),
          mapTo(action),
        )
      }

      return of(action)
    }),
    map(({ categories, options }) =>
      filterMatchesByRefProdL3Categories(categories, options),
    ),
  )

const startFilterMatchesByRefProdL4CategoriesEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_START_FILTER_BY_REF_PROD_L4_CATEGORIES),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(
          ofType(MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES),
          take(1),
          mapTo(action),
        )
      }

      return of(action)
    }),
    map(({ categories, options }) =>
      filterMatchesByRefProdL4Categories(categories, options),
    ),
  )

const startFilterMatchesByRefProdCountriesEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_START_FILTER_BY_REF_PROD_COUNTRY),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(ofType(REF_PRODUCT_SUMMARY_SUCCESS), take(1), mapTo(action))
      }

      return of(action)
    }),
    map(({ countries, options }) =>
      filterMatchesByRefProdCountries(countries, options),
    ),
  )

const startFilterMatchesByRefProdFamiliesEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_START_FILTER_BY_REF_PROD_FAMILY),
    switchMap((action) => {
      const {
        options: { fromUrl },
      } = action

      if (fromUrl) {
        return action$.pipe(ofType(REF_PRODUCT_SUMMARY_SUCCESS), take(1), mapTo(action))
      }

      return of(action)
    }),
    map(({ families, options }) => filterMatchesByRefProdFamilies(families, options)),
  )

const updateRefProdL2CategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES),
    map(({ options }) =>
      updateRefProdL2Categories(
        state$.value.matches.referenceProductCategories.categoriesTree,
        options,
      ),
    ),
  )

const updateRefProdL3CategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES),
    map(({ options }) =>
      updateRefProdL3Categories(
        state$.value.matches.referenceProductCategories.categoriesTree,
        options,
      ),
    ),
  )

const updateRefProdL4CategoriesEpic = (action$, state$) =>
  action$.pipe(
    ofType(MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES),
    map(({ options }) =>
      updateRefProdL4Categories(
        state$.value.matches.referenceProductCategories.categoriesTree,
        options,
      ),
    ),
  )

const filterReferenceProductsL2CategoriesAfterUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(MATCH_UPDATE_REF_PROD_L2_CATEGORIES),
    filter(({ options: { fromUrl } }) => !fromUrl),
    map(() =>
      filterMatchesByRefProdL2Categories(state$.value.matches.selectedL2Categories),
    ),
  )

const filterReferenceProductsL3CategoriesAfterUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(MATCH_UPDATE_REF_PROD_L3_CATEGORIES),
    filter(({ options: { fromUrl } }) => !fromUrl),
    map(() =>
      filterMatchesByRefProdL3Categories(state$.value.matches.selectedL3Categories),
    ),
  )

const filterReferenceProductsL4CategoriesAfterUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(MATCH_UPDATE_REF_PROD_L4_CATEGORIES),
    filter(({ options: { fromUrl } }) => !fromUrl),
    map(() =>
      filterMatchesByRefProdL4Categories(state$.value.matches.selectedL4Categories),
    ),
  )

const syncLocationParamsForMatchesReviewEpic = (action$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    filter(
      ({
        payload: {
          isFirstRendering = false,
          action,
          location: { pathname, state: { fromSso = false } = {} },
        },
      }) =>
        (isFirstRendering || action === "POP" || fromSso) &&
        pathname === "/matches/review",
    ),
    mergeMap(
      ({
        payload: {
          location: { search },
        },
      }) => {
        const query = queryString.parse(search)

        const options = {
          fromUrl: true,
        }

        return from([
          filterMatchesByStatus(forceArray(query[QP_STATUS]), options),
          filterMatchesBySource(forceArray(query[QP_SOURCE]), options),
          filterMatchesByCountry(forceArray(query[QP_COUNTRY]), options),
          filterMatchesByShop(forceArray(query[QP_SHOP]), options),
          filterMatchesByAvailability(forceArray(query[QP_AVAILABILITY]), options),
          filterMatchesByFactor(forceArray(query[QP_FACTOR]), options),
          filterMatchesByRefProdQuery(query[QP_QUERY], options),
          startFilterMatchesByRefProdL1Categories(
            forceArray(query[QP_CATEGORY_1]),
            options,
          ),
          startFilterMatchesByRefProdL2Categories(
            forceArray(query[QP_CATEGORY_2]),
            options,
          ),
          startFilterMatchesByRefProdL3Categories(
            forceArray(query[QP_CATEGORY_3]),
            options,
          ),
          startFilterMatchesByRefProdL4Categories(
            forceArray(query[QP_CATEGORY_4]),
            options,
          ),
          startFilterMatchesByRefProdCountries(
            forceArray(query[QP_REF_PROD_COUNTRY]),
            options,
          ),
          startFilterMatchesByRefProdFamilies(
            forceArray(query[QP_REF_PROD_FAMILY]),
            options,
          ),
          setMatchesListPage(+query[QP_PAGE] || 1, options),
          setMatchesReviewPageSettings(
            {
              showFilters: toBool(query[QP_FILTERS]),
            },
            options,
          ),
        ])
      },
    ),
  )

const updateUrlFromStateEpic = (action$, state$) =>
  action$.pipe(
    ofType(LOCATION_CHANGE),
    withLatestFrom(state$),
    filter(
      ([
        {
          payload: {
            action,
            location: { pathname, state: { fromSso = false } = {} },
          },
        },
        {
          app: {
            prevLocation: { pathname: currentPathname },
          },
        },
      ]) =>
        (action === "PUSH" || action === "REPLACE") &&
        pathname === "/matches/review" &&
        pathname !== currentPathname &&
        !fromSso,
    ),
    map(() => urlActions.updateUrlFromState()),
  )

const updateLocationPushForMatchesReviewEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      MATCH_SET_PAGE,
      MATCH_FILTER_BY_SOURCE,
      MATCH_FILTER_BY_STATUS,
      MATCH_FILTER_BY_COUNTRY,
      MATCH_FILTER_BY_SHOP,
      MATCH_FILTER_BY_AVAILABILITY,
      MATCH_FILTER_BY_FACTOR,
      MATCH_FILTER_BY_REF_PROD_QUERY,
      MATCH_FILTER_BY_REF_PROD_COUNTRY,
      MATCH_FILTER_BY_REF_PROD_FAMILY,
      MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES,
      MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES,
    ),
    filter(() => isInPage("/matches/review", state$.value)),
    filter(({ options: { fromUrl } = {} }) => !fromUrl),
    debounceTime(100),
    map(() => {
      const {
        router: { location },
        matches: {
          selectedMatchCountries,
          selectedMatchShops,
          selectedMatchProductAvailabilities,
          selectedMatchProductFactors,
          selectedMatchSources,
          selectedMatchStatuses,
          selectedL1Categories,
          selectedL2Categories,
          selectedL3Categories,
          selectedL4Categories,
          reviewPageSettings: { showFilters },
          refProdQuery,
          selectedRefProdCountries,
          selectedRefProdFamilies,
          page,
        },
      } = state$.value

      return push(
        updateLocationQuery(location, {
          [QP_QUERY]: refProdQuery,
          [QP_FILTERS]: showFilters,
          [QP_REF_PROD_COUNTRY]: selectedRefProdCountries,
          [QP_REF_PROD_FAMILY]: selectedRefProdFamilies,

          [QP_CATEGORY_1]: selectedL1Categories,
          [QP_CATEGORY_2]: selectedL2Categories,
          [QP_CATEGORY_3]: selectedL3Categories,
          [QP_CATEGORY_4]: selectedL4Categories,
          [QP_SOURCE]: selectedMatchSources,
          [QP_STATUS]: selectedMatchStatuses,
          [QP_COUNTRY]: selectedMatchCountries,
          [QP_SHOP]: selectedMatchShops,
          [QP_AVAILABILITY]: selectedMatchProductAvailabilities,
          [QP_FACTOR]: selectedMatchProductFactors,
          [QP_PAGE]: page,
        }),
      )
    }),
  )

const updateLocationReplaceForMatchesReviewEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      MATCH_SET_REVIEW_PAGE_SETTINGS,
      MATCH_CLEAR_LIST,
      urlActionTypes.URL_UPDATE_FROM_STATE,
    ),
    filter(() => isInPage("/matches/review", state$.value)),
    filter(({ options: { fromUrl } = {} }) => !fromUrl),
    map(() => {
      const {
        router: { location },
        matches: {
          selectedMatchCountries,
          selectedMatchShops,
          selectedMatchSources,
          selectedMatchStatuses,
          selectedMatchProductAvailabilities,
          selectedMatchProductFactors,
          selectedL1Categories,
          selectedL2Categories,
          selectedL3Categories,
          selectedL4Categories,
          reviewPageSettings: { showFilters },
          refProdQuery,
          selectedRefProdCountries,
          selectedRefProdFamilies,
          page,
        },
      } = state$.value

      return replace(
        updateLocationQuery(location, {
          [QP_QUERY]: refProdQuery,
          [QP_FILTERS]: showFilters,
          [QP_REF_PROD_COUNTRY]: selectedRefProdCountries,
          [QP_REF_PROD_FAMILY]: selectedRefProdFamilies,
          [QP_CATEGORY_1]: selectedL1Categories,
          [QP_CATEGORY_2]: selectedL2Categories,
          [QP_CATEGORY_3]: selectedL3Categories,
          [QP_CATEGORY_4]: selectedL4Categories,
          [QP_SOURCE]: selectedMatchSources,
          [QP_STATUS]: selectedMatchStatuses,
          [QP_COUNTRY]: selectedMatchCountries,
          [QP_SHOP]: selectedMatchShops,
          [QP_AVAILABILITY]: selectedMatchProductAvailabilities,
          [QP_FACTOR]: selectedMatchProductFactors,
          [QP_PAGE]: page,
        }),
      )
    }),
  )

const reportCreateManualMatchEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_CREATE_MANUAL_SUCCESS),
    tap(
      ({
        parent: { referenceProductId, daltixProductId, shop, country, factor, status },
      }) => {
        mixpanel.track("Create Manual Match", {
          referenceProductId,
          daltixProductId,
          shop,
          country,
          factor,
          status,
        })
        mixpanel.people.increment("manual matches")
      },
    ),
    filter(() => false),
  )

const reportUpdateMatchBasicInfoEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_UPDATE_BASIC_SUCCESS),
    tap(({ parent: { referenceProductId, daltixProductId, factor } }) => {
      mixpanel.track("Update Match", {
        referenceProductId,
        daltixProductId,
        factor,
      })
    }),
    filter(() => false),
  )

const reportMatchApprovalEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_APPROVE_SUCCESS),
    tap(({ parent: { referenceProductId, daltixProductId, status } }) => {
      mixpanel.track("Approve Match", {
        referenceProductId,
        daltixProductId,
        status,
      })
      mixpanel.people.increment("match approvals")
    }),
    filter(() => false),
  )

const reportMatchApprovalRemovedEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_REMOVE_APPROVAL_SUCCESS),
    tap(({ parent: { referenceProductId, daltixProductId, status } }) => {
      mixpanel.track("Remove Approval", {
        referenceProductId,
        daltixProductId,
        status,
      })
      mixpanel.people.increment("removed approvals")
    }),
    filter(() => false),
  )

const reportMatchDiscardedEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_DISCARD_SUCCESS),
    tap(({ parent: { referenceProductId, daltixProductId, status } }) => {
      mixpanel.track("Discard Match", {
        referenceProductId,
        daltixProductId,
        status,
      })
      mixpanel.people.increment("match discards")
    }),
    filter(() => false),
  )

const reportMatchRestoredEpic = (action$) =>
  action$.pipe(
    ofType(MATCH_RESTORE_SUCCESS),
    tap(({ parent: { referenceProductId, daltixProductId, status } }) => {
      mixpanel.track("Restore Match", {
        referenceProductId,
        daltixProductId,
        status,
      })
      mixpanel.people.increment("match restores")
    }),
    filter(() => false),
  )

export default [
  listReferenceProductsCategoriesEpic,
  clearMatchesReviewListEpic,
  triggerListMatchesEpic,
  getMatchStatusSummaryEpic,
  listMatchSourcesEpic,
  listCountriesWithMatchesEpic,
  // matchProductEpic,
  createManualMatchEpic,
  updateMatchBasicInfoEpic,
  approveMatchEpic,
  removeMatchApprovalEpic,
  discardMatchEpic,
  restoreMatchEpic,
  getMatchesByIdEpic,
  listMatchesEpic,
  triggerGetShopsFromMatchesEpic,
  getShopsFromMatchesEpic,
  updateMatchesListEpic,
  syncLocationParamsForMatchesReviewEpic,
  updateUrlFromStateEpic,
  updateLocationPushForMatchesReviewEpic,
  updateLocationReplaceForMatchesReviewEpic,
  startFilterMatchesByRefProdCountriesEpic,
  startFilterMatchesByRefProdFamiliesEpic,
  startFilterMatchesByRefProdL1CategoriesEpic,
  startFilterMatchesByRefProdL2CategoriesEpic,
  startFilterMatchesByRefProdL3CategoriesEpic,
  startFilterMatchesByRefProdL4CategoriesEpic,
  updateRefProdL2CategoriesEpic,
  updateRefProdL3CategoriesEpic,
  updateRefProdL4CategoriesEpic,
  filterReferenceProductsL2CategoriesAfterUpdateEpic,
  filterReferenceProductsL3CategoriesAfterUpdateEpic,
  filterReferenceProductsL4CategoriesAfterUpdateEpic,
  reportCreateManualMatchEpic,
  reportUpdateMatchBasicInfoEpic,
  reportMatchApprovalEpic,
  reportMatchApprovalRemovedEpic,
  reportMatchDiscardedEpic,
  reportMatchRestoredEpic,
  loadMatchDataEpic,
]
