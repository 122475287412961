import { titleCase } from "./strings"
import { KEY_INTERNAL_USER, getValue } from "./local-storage"

export const SOURCE_EAN = "ean"
export const SOURCE_MANUAL = "manual"
export const SOURCE_MIGRATED = "migrated"
export const SOURCE_OTHER = "suggestion"

export const INTERNAL_SOURCE_DX_EAN = "daltix-ean"
export const INTERNAL_SOURCE_DX_COUNTRY = "daltix-country"
export const INTERNAL_SOURCE_DX_SHOP_MAP = "daltix-shopmapping"
export const INTERNAL_SOURCE_CUST_MANUAL = "customer-manual"
export const INTERNAL_SOURCE_CUST_MIGRATED = "customer-migrated"

const INTERNAL_USER = getValue(KEY_INTERNAL_USER)

const summarizedToInternalMap = {
  [SOURCE_EAN]: [
    INTERNAL_SOURCE_DX_EAN,
    INTERNAL_SOURCE_DX_SHOP_MAP,
    INTERNAL_SOURCE_DX_COUNTRY,
  ],
  [SOURCE_MANUAL]: [INTERNAL_SOURCE_CUST_MANUAL],
  [SOURCE_MIGRATED]: [INTERNAL_SOURCE_CUST_MIGRATED],
  [SOURCE_OTHER]: [],
}

const internalToSummarizedMap = (() => {
  const map = {}

  Object.keys(summarizedToInternalMap).forEach((summarizedKey) => {
    summarizedToInternalMap[summarizedKey].forEach((internalKey) => {
      map[internalKey] = summarizedKey
    })
  })

  return map
})()

export function internalToSummarized(internalSources = []) {
  const summarizedSources = []
  let hasOther = false

  internalSources.forEach((internalSource) => {
    const summarizedSource = internalToSummarizedMap[internalSource]
    if (summarizedSource) {
      if (!summarizedSources.includes(summarizedSource)) {
        summarizedSources.push(summarizedSource)
      }
    } else {
      hasOther = true
    }
  })

  if (hasOther) {
    summarizedSources.push(SOURCE_OTHER)
  }

  return summarizedSources
}

export function summarizedToInternal(summarizedSources = []) {
  const internalSources = []

  summarizedSources.forEach((summarizedSource) => {
    summarizedToInternalMap[summarizedSource].forEach((internalSource) => {
      if (!internalSources.includes(internalSource)) {
        internalSources.push(internalSource)
      }
    })
  })

  return internalSources
}

export function isSummarizedSource(source) {
  return Object.keys(summarizedToInternalMap).includes(source)
}

export function allKnownSources() {
  return Object.keys(internalToSummarizedMap)
}

const sourcesMap = {
  [SOURCE_EAN]: {
    code: SOURCE_EAN,
    label: "EAN",
    badgeLabel: "EAN",
    title: "This product was matched by the EAN code",
  },
  [SOURCE_OTHER]: {
    code: SOURCE_OTHER,
    label: "Suggestion",
    badgeLabel: "AI",
    title: "This product was suggested by Daltix AI",
  },
  [SOURCE_MANUAL]: {
    code: SOURCE_MANUAL,
    label: "Manual",
    badgeLabel: "Manual",
    title: "This product was matched manually",
  },
  [SOURCE_MIGRATED]: {
    code: SOURCE_MIGRATED,
    label: "Migrated",
    badgeLabel: "Migrated",
    title: "This match was migrated from customer's existing data",
  },
}

export const sourceCode2data = (source) => {
  let finalSource = source

  if (source && !INTERNAL_USER && !isSummarizedSource(source)) {
    ;[finalSource] = internalToSummarized([source])
  }

  return (
    sourcesMap[finalSource] || {
      code: finalSource,
      label: titleCase(finalSource),
      badgeLabel: titleCase(finalSource),
      title: titleCase(finalSource),
    }
  )
}

export const source2label = (source) => sourceCode2data(source).label

export const getMatchSourceFilterOptions = (sources) => {
  let finalSources = sources

  if (!INTERNAL_USER) {
    finalSources = internalToSummarized(sources)
  }

  return finalSources.map((source) => ({
    value: source,
    label: source2label(source),
  }))
}
