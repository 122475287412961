import { oneOf, string } from "prop-types"
import React, { memo } from "react"
import { useSelector } from "react-redux"

import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
  STATUS_UNMATCHED,
} from "../../util/match-status"
import { DXIcon } from "../dx-icon/DXIcon"

const MatchStatusIcon = memo(({ status, matchId, testID }) => {
  const updatedStatus = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.status,
  )

  const effectiveStatus = updatedStatus || status

  if (effectiveStatus === STATUS_APPROVED) {
    return <DXIcon testID={testID} tooltip="Approved" type={effectiveStatus} />
  }

  if (effectiveStatus === STATUS_DISCARDED) {
    return <DXIcon testID={testID} tooltip="Discarded" type={effectiveStatus} />
  }

  if (effectiveStatus === STATUS_REVIEW) {
    return <DXIcon testID={testID} tooltip="In Review" type={effectiveStatus} />
  }

  if (effectiveStatus === STATUS_UNMATCHED) {
    return <DXIcon testID={testID} tooltip="Unmatched" type={effectiveStatus} />
  }

  return null
})

MatchStatusIcon.propTypes = {
  testID: string,
  status: oneOf([STATUS_APPROVED, STATUS_DISCARDED, STATUS_REVIEW, STATUS_UNMATCHED]),
  matchId: string,
}

MatchStatusIcon.defaultProps = {
  testID: "",
  status: "",
  matchId: "",
}

export { MatchStatusIcon }
