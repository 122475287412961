import { combineEpics } from "redux-observable"
import { epics as user } from "../ducks/user"
import { epics as refProduct } from "../ducks/reference-products"
import { epics as daltixProduct } from "../ducks/daltix-product"
import { epics as category } from "../ducks/category"
import { epics as notification } from "../ducks/notification"
import { epics as fileUpload } from "../ducks/file-upload"
import { epics as productUpload } from "../ducks/product-upload"
import { epics as matches } from "../ducks/matches"
import { epics as dashboard } from "../ducks/dashboard"
import { epics as daltixProductsRepository } from "../ducks/repositories/daltix-products"
import { epics as matchedProductsRepository } from "../ducks/repositories/matched-products"
import { epics as referenceProductsRepository } from "../ducks/repositories/reference-products"
import { epics as matchesOverviewPage } from "../ducks/pages/matches-overview"
import { epics as assortmentAnalysisPage } from "../ducks/pages/assortment-analysis"
import { epics as productComparisonWidget } from "../ducks/widgets/product-comparison"
import { epics as productSearchWidget } from "../ducks/widgets/product-search"

export default combineEpics(
  ...assortmentAnalysisPage,
  ...category,
  ...daltixProduct,
  ...daltixProductsRepository,
  ...dashboard,
  ...fileUpload,
  ...matchedProductsRepository,
  ...matches,
  ...matchesOverviewPage,
  ...notification,
  ...productComparisonWidget,
  ...productSearchWidget,
  ...productUpload,
  ...referenceProductsRepository,
  ...refProduct,
  ...user,
)
