import { normalize } from "normalizr"
import { Subject } from "rxjs"
import { map, mergeMap, tap } from "rxjs/operators"
import { ofType } from "redux-observable"
import mixpanel from "mixpanel-browser"
import { productUploadListSchema as fileUploadListSchema } from "../schema/product-upload"
import { successType, errorType, buildEpic } from "../util/redux-observable-helpers"
import { actionCreators as notificationActions } from "./notification"
import * as api from "../api/service"
import { Logger } from "../util/log"

export const PAGE_SIZE = 10

const log = new Logger("ducks:file-upload")

// Action Types

const FILE_UPLOAD_LIST = "@fileUpload/LIST"
const FILE_UPLOAD_LIST_SUCCESS = successType(FILE_UPLOAD_LIST)
const FILE_UPLOAD_LIST_ERROR = errorType(FILE_UPLOAD_LIST)

const FILE_UPLOAD_UPLOAD = "@fileUpload/UPLOAD"
const FILE_UPLOAD_UPLOAD_ID_READY = "@fileUpload/UPLOAD_ID_READY"
const FILE_UPLOAD_UPLOAD_PROGRESS = "@fileUpload/UPLOAD_PROGRESS"
const FILE_UPLOAD_UPLOAD_SUCCESS = successType(FILE_UPLOAD_UPLOAD)
const FILE_UPLOAD_UPLOAD_ERROR = errorType(FILE_UPLOAD_UPLOAD)
const FILE_UPLOAD_CANCEL_UPLOAD = "@fileUpload/CANCEL_UPLOAD"
const FILE_UPLOAD_CLEAR_UPLOAD = "@fileUpload/CLEAR_UPLOAD"

const actionTypes = {
  FILE_UPLOAD_LIST,
  FILE_UPLOAD_LIST_SUCCESS,
  FILE_UPLOAD_LIST_ERROR,

  FILE_UPLOAD_UPLOAD,
  FILE_UPLOAD_UPLOAD_ID_READY,
  FILE_UPLOAD_UPLOAD_PROGRESS,
  FILE_UPLOAD_UPLOAD_SUCCESS,
  FILE_UPLOAD_UPLOAD_ERROR,
  FILE_UPLOAD_CANCEL_UPLOAD,
  FILE_UPLOAD_CLEAR_UPLOAD,
}

// Action Creators

const listFileUploads = ({ statuses, page = 1, pageSize = 10 } = {}) => ({
  type: FILE_UPLOAD_LIST,
  statuses,
  page,
  pageSize,
})

const uploadFile = ({ file, successNotification, errorNotification }) => ({
  type: FILE_UPLOAD_UPLOAD,
  file,
  successNotification,
  errorNotification,
})

const clearFileUpload = () => ({
  type: FILE_UPLOAD_CLEAR_UPLOAD,
})

const cancelFileUpload = () => ({
  type: FILE_UPLOAD_CANCEL_UPLOAD,
})

const actionCreators = {
  listFileUploads,
  uploadFile,
  clearFileUpload,
  cancelFileUpload,
}

// epics

const listFileUploadsEpic = buildEpic(FILE_UPLOAD_LIST, ({ statuses }) =>
  api.listFileUploads({ statuses }),
)

const uploadFileEpic = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD_UPLOAD),
    mergeMap((action) => {
      const { file } = action

      log.debug(file)
      const listener = new Subject()

      api.uploadFile(file, {
        onFinish: () => {
          listener.next({
            type: FILE_UPLOAD_UPLOAD_SUCCESS,
            parent: action,
          })
          listener.complete()
        },

        onError: (error) =>
          listener.next({
            type: FILE_UPLOAD_UPLOAD_ERROR,
            parent: action,
            error,
          }),

        onIdCreated: (id) =>
          listener.next({
            type: FILE_UPLOAD_UPLOAD_ID_READY,
            parent: action,
            id,
          }),

        onProgress: (progress) =>
          listener.next({
            type: FILE_UPLOAD_UPLOAD_PROGRESS,
            parent: action,
            progress,
          }),
      })
      return listener
    }),
  )

const uploadFileSuccessNotificationEpic = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD_UPLOAD_SUCCESS),
    tap(
      ({
        parent: {
          file: { name, type },
        },
      }) => {
        mixpanel.track("File Upload", {
          name,
          type,
        })
      },
    ),
    map(({ parent: { successNotification } }) =>
      notificationActions.enqueueNotification(successNotification),
    ),
  )

const epics = [listFileUploadsEpic, uploadFileEpic, uploadFileSuccessNotificationEpic]

// reducer

const initalState = {
  list: [],
  uploads: {},
  currentUploadProgress: 0,
  currentUploadId: undefined,
  currentUploadName: "",
  currentUploadError: undefined,
  uploading: false,
}

const reducer = (state = initalState, { type, ...action }) => {
  switch (type) {
    case FILE_UPLOAD_LIST:
      return {
        ...state,
        loadingList: true,
      }
    case FILE_UPLOAD_LIST_SUCCESS: {
      const {
        payload: {
          uploads: newUploads,
          meta: { total },
        },
      } = action

      const processed = newUploads.map((item) => {
        const fileNameParts = item.fileName.split("/")

        return {
          ...item,
          createdAtString: new Date(item.createdAt).toISOString(),
          name: fileNameParts[fileNameParts.length - 1],
        }
      })

      const normalized = normalize(processed, fileUploadListSchema)
      const list = normalized.result || []
      const { uploads } = state

      return {
        ...state,
        loadingList: false,
        list,
        uploads: {
          ...uploads,
          ...normalized.entities.fileUpload,
        },
        total,
        loadingListError: undefined,
      }
    }
    case FILE_UPLOAD_LIST_ERROR:
      return {
        ...state,
        loadingList: false,
        list: [],
        total: 0,
        loadingListError: action.error,
      }

    case FILE_UPLOAD_UPLOAD:
      return {
        ...state,
        uploading: true,
        currentUploadName: action.file.name,
      }

    case FILE_UPLOAD_UPLOAD_ID_READY:
      return {
        ...state,
        currentUploadId: action.id,
      }

    case FILE_UPLOAD_UPLOAD_PROGRESS:
      return {
        ...state,
        currentUploadProgress: action.progress.loaded / action.progress.total,
        currentUploadLoaded: action.progress.loaded,
        currentUploadTotal: action.progress.total,
      }

    case FILE_UPLOAD_UPLOAD_SUCCESS:
      return {
        ...state,
        uploading: false,
        currentUploadError: undefined,
      }

    case FILE_UPLOAD_UPLOAD_ERROR:
      return {
        ...state,
        uploading: false,
        currentUploadError: action.error,
      }

    case FILE_UPLOAD_CLEAR_UPLOAD:
      return {
        ...state,
        uploading: false,
        currentUploadId: undefined,
        currentUploadName: "",
        currentUploadError: undefined,
        currentUploadProgress: 0,
        currentUploadLoaded: 0,
        currentUploadTotal: 0,
      }

    default:
  }

  return state
}

// exports

export { actionTypes, actionCreators, epics }

export default reducer
