import mixpanel from "mixpanel-browser"

/**
 * Converts an event type string into a pretty formatted name.
 *
 * @param {string} eventType - The event type string to format.
 * @returns {string} The formatted event name.
 */
function prettyEventName(eventType) {
  const words = eventType.split(":")

  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  )

  return `Looker Apps - ${formattedWords.join(" ")}`
}

/**
 * @param {string} eventName a mixpanel friendly, human-readable, event name
 * @param {{ dashboard: { absoluteUrl: string, dashboard_filters: string, title: string }}} payload
 *  the event payload containing a `dashboard` property
 * @param {Record<string, unknown>} context (optional)
 *  context to be forwarded to mixpanel along with original event payload
 */
function registerDashboardEvent(
  eventName,
  {
    dashboard: {
      absoluteUrl: dashboardUrl,
      dashboard_filters: dashboardFilters,
      title: dashboardTitle,
    },
  },
  context = {},
) {
  mixpanel.track(eventName, {
    dashboardUrl,
    dashboardFilters,
    dashboardTitle,
    timestamp: Date.now(),
    ...context,
  })
}

const EventLookup = {
  "dashboard:run:complete": registerDashboardEvent,
  "dashboard:run:start": registerDashboardEvent,
  "dashboard:filters:changed": registerDashboardEvent,
}

/**
 * @param {string} type the name of the event which was emitted by Looker
 * @param {Record<string, unknown>} payload data associated to the event type
 * @param {Record<string, unknown>} context (optional)
 *  Extra payload to be merged with the original event payload.
 *
 * @see https://cloud.google.com/looker/docs/embedded-javascript-events#event_type_summary_table
 */
function handleLookerEvent({ type, ...payload }, context = {}) {
  const register = EventLookup[type]

  if (register) {
    register(prettyEventName(type), payload, context)
  }
}

/**
 * Factory function that returns a function to handle Looker events.
 *
 * @param {Record<string, unknown>} context (optional)
 *  Extra payload to be merged with the original event payload.
 * @returns {(payload: { type: string; [key: string]: unknown;}) => void }
 *  Function to handle Looker events.
 */
function lookerEventHandlerFactory(context) {
  return (payload) => handleLookerEvent(payload, context)
}

export { handleLookerEvent, EventLookup, lookerEventHandlerFactory }
