import React, { useEffect, useMemo, useState } from "react"

import {
  Badge,
  Divider,
  Fade,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { IconAdjustmentsHorizontal } from "@tabler/icons-react"
import { func, instanceOf, oneOf } from "prop-types"
import {
  PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY,
  PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS,
} from "../../../../ducks/widgets/product-search/constants"
import { useProductSearch } from "../../../../ducks/widgets/product-search/hooks"
import {
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
  STATUS_UNMATCHED,
} from "../../../../util/match-status"
import {
  AVAILABILITY_ACTIVE,
  AVAILABILITY_INACTIVE,
} from "../../../../util/product-availability"
import { DXIcon } from "../../../dx-icon/DXIcon"
import { DaltixProductAutocomplete } from "./DaltixProductAutocomplete"
import { ProductSearchFilterDialog } from "./FilterDialog"
import { isEmpty } from "../../../../util/filter"

function ProductSearchFilter({ selectedRefProduct: referenceProduct, size, setSize }) {
  const [open, setOpen] = useState(false)
  const {
    state: {
      [PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS]: filters,
      [PRODUCT_SEARCH_WIDGET_PRODUCTS_TOTAL_KEY]: totalProducts,
    },
    getFilters,
    setFilters,
    clearFilters,
  } = useProductSearch()

  useEffect(() => {
    if (!referenceProduct?.name) {
      return
    }

    getFilters(referenceProduct.name)
  }, [referenceProduct, getFilters])

  const totalFilters = useMemo(() => {
    let count = 0

    const selectableFilters = [
      "status",
      "availabilities",
      "source",
      "shops",
      "countries",
    ]

    selectableFilters.forEach((key) => {
      const checks = Object.values(filters[key])
      checks.forEach((isChecked) => {
        count += isChecked ? 1 : 0
      })
    })

    const brands = Object.values(filters.brands)
    if (brands && brands.length > 0) {
      count++
    }

    if (filters.maxPrice || filters.minPrice) {
      count++
    }

    if (!isEmpty(filters.content.min) || !isEmpty(filters.content.max)) {
      count++
    }

    if (filters.minFactor || filters.maxFactor) {
      count++
    }

    if (filters.onlyWithPrice) {
      count++
    }

    if (filters.onlyWithContent) {
      count++
    }

    if (filters.onlyWithMF) {
      count++
    }

    return count
  }, [filters])

  function onChange(filter) {
    return (_, field) => {
      const newValue = {
        ...filters[filter],
        [field]: !filters[filter][field],
      }

      setFilters({ ...filters, [filter]: newValue })
    }
  }

  const statuses = useMemo(
    () => Object.keys(filters.status).filter((selected) => filters.status[selected]),
    [filters.status],
  )

  const availabilities = useMemo(
    () =>
      Object.keys(filters.availabilities).filter(
        (selected) => filters.availabilities[selected],
      ),
    [filters],
  )

  const onChangeStatus = onChange("status")
  const onChangeAvailability = onChange("availabilities")

  const formatResults = (results) => (results > 9999 ? "9999+" : results)

  return (
    <Box
      data-testid="filters-container"
      display="flex"
      flexDirection="column"
      mt={2}
      marginBottom={2}
      gap={2}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="stretch" gap={2}>
          <DaltixProductAutocomplete />

          <Badge
            sx={{ height: "100%" }}
            badgeContent={totalFilters}
            color="primary"
            max={99}
          >
            <Button
              size="small"
              variant="outlined"
              startIcon={<IconAdjustmentsHorizontal size={16} />}
              onClick={() => setOpen(true)}
            >
              Product filters
            </Button>
          </Badge>

          <ToggleButtonGroup size="small" value={statuses} color="primary">
            <Tooltip arrow title="Filter: Approved" placement="bottom">
              <ToggleButton
                data-testid="filter-approved"
                value={STATUS_APPROVED}
                onClick={onChangeStatus}
              >
                <DXIcon type={STATUS_APPROVED} />
              </ToggleButton>
            </Tooltip>
            <Tooltip arrow title="Filter: In Review" placement="bottom">
              <ToggleButton
                data-testid="filter-in-review"
                value={STATUS_REVIEW}
                onClick={onChangeStatus}
              >
                <DXIcon type={STATUS_REVIEW} />
              </ToggleButton>
            </Tooltip>
            <Tooltip arrow title="Filter: Discarded" placement="bottom">
              <ToggleButton
                data-testid="filter-discarded"
                value={STATUS_DISCARDED}
                onClick={onChangeStatus}
              >
                <DXIcon type={STATUS_DISCARDED} />
              </ToggleButton>
            </Tooltip>
            <Tooltip arrow title="Filter: Unmatched" placement="bottom">
              <ToggleButton
                data-testid="filter-unmatched"
                value={STATUS_UNMATCHED}
                onClick={onChangeStatus}
              >
                <DXIcon type={STATUS_UNMATCHED} />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>

          <ToggleButtonGroup size="small" value={availabilities} color="primary">
            <Tooltip arrow title="Filter: Active" placement="bottom">
              <ToggleButton
                data-testid="filter-active"
                value={AVAILABILITY_ACTIVE}
                onClick={onChangeAvailability}
              >
                <DXIcon type="active" />
              </ToggleButton>
            </Tooltip>
            <Tooltip arrow title="Filter: Inactive" placement="bottom">
              <ToggleButton
                data-testid="filter-inactive"
                value={AVAILABILITY_INACTIVE}
                onClick={onChangeAvailability}
              >
                <DXIcon type="inactive" />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>

          <Fade in={totalFilters > 0}>
            <Button
              data-testid="clear-all-filters"
              disableRipple
              onClick={clearFilters}
              size="small"
              variant="text"
            >
              Clear all
            </Button>
          </Fade>

          <Divider orientation="vertical" />
          <Typography display="flex" alignItems="center" variant="subtitle1">
            Results: {formatResults(totalProducts)}
          </Typography>
        </Box>

        <Box display="flex" alignItems="end">
          <Typography display="flex" variant="subtitle1" gap={2}>
            Row size:
            <TextField
              color="primary"
              select
              onChange={(e) => setSize(e.target.value)}
              size="small"
              value={size}
              variant="standard"
            >
              <MenuItem value="small">Small</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="large">Large</MenuItem>
            </TextField>
          </Typography>
        </Box>
      </Box>

      <ProductSearchFilterDialog open={open} onClose={() => setOpen(false)} />
    </Box>
  )
}

ProductSearchFilter.propTypes = {
  size: oneOf(["small", "medium", "large"]),
  setSize: func.isRequired,
  selectedRefProduct: instanceOf(Object).isRequired,
}

ProductSearchFilter.defaultProps = {
  size: "small",
}

export { ProductSearchFilter }
