import { normalize } from "normalizr"
import { Subject } from "rxjs"
import { map, mergeMap, mapTo } from "rxjs/operators"
import { ofType } from "redux-observable"
import { productUploadListSchema } from "../schema/product-upload"
import { successType, errorType, buildEpic } from "../util/redux-observable-helpers"
import { actionCreators as notificationActions } from "./notification"
import * as api from "../api/service"
import { Logger } from "../util/log"

export const PAGE_SIZE = 10

const log = new Logger("ducks:product-upload")

// Action Types

const PRODUCT_UPLOAD_LIST = "@productUpload/LIST"
const PRODUCT_UPLOAD_LIST_SUCCESS = successType(PRODUCT_UPLOAD_LIST)
const PRODUCT_UPLOAD_LIST_ERROR = errorType(PRODUCT_UPLOAD_LIST)

const PRODUCT_UPLOAD_UPLOAD = "@productUpload/UPLOAD"
const PRODUCT_UPLOAD_UPLOAD_ID_READY = "@productUpload/UPLOAD_ID_READY"
const PRODUCT_UPLOAD_UPLOAD_PROGRESS = "@productUpload/UPLOAD_PROGRESS"
const PRODUCT_UPLOAD_UPLOAD_SUCCESS = successType(PRODUCT_UPLOAD_UPLOAD)
const PRODUCT_UPLOAD_UPLOAD_ERROR = errorType(PRODUCT_UPLOAD_UPLOAD)
const PRODUCT_UPLOAD_CANCEL_UPLOAD = "@productUpload/CANCEL_UPLOAD"
const PRODUCT_UPLOAD_CLEAR_UPLOAD = "@productUpload/CLEAR_UPLOAD"

const actionTypes = {
  PRODUCT_UPLOAD_LIST,
  PRODUCT_UPLOAD_LIST_SUCCESS,
  PRODUCT_UPLOAD_LIST_ERROR,

  PRODUCT_UPLOAD_UPLOAD,
  PRODUCT_UPLOAD_UPLOAD_ID_READY,
  PRODUCT_UPLOAD_UPLOAD_PROGRESS,
  PRODUCT_UPLOAD_UPLOAD_SUCCESS,
  PRODUCT_UPLOAD_UPLOAD_ERROR,
  PRODUCT_UPLOAD_CANCEL_UPLOAD,
  PRODUCT_UPLOAD_CLEAR_UPLOAD,
}

// Action Creators

const listProductUploads = ({ statuses, page = 1, pageSize = 10 } = {}) => ({
  type: PRODUCT_UPLOAD_LIST,
  statuses,
  page,
  pageSize,
})

const uploadProductsFile = ({ file, successNotification, errorNotification }) => ({
  type: PRODUCT_UPLOAD_UPLOAD,
  file,
  successNotification,
  errorNotification,
})

const clearProductUpload = () => ({
  type: PRODUCT_UPLOAD_CLEAR_UPLOAD,
})

const cancelProductUpload = () => ({
  type: PRODUCT_UPLOAD_CANCEL_UPLOAD,
})

const actionCreators = {
  listProductUploads,
  uploadProductsFile,
  clearProductUpload,
  cancelProductUpload,
}

// epics

const listProductUploadsEpic = buildEpic(PRODUCT_UPLOAD_LIST, ({ statuses }) =>
  api.listProductUploads({ statuses }),
)

const uploadProductsFileEpic = (action$) =>
  action$.pipe(
    ofType(PRODUCT_UPLOAD_UPLOAD),
    mergeMap((action) => {
      const { file } = action

      log(file)
      const listener = new Subject()

      api.uploadProductsFile(file, {
        onFinish: () => {
          listener.next({
            type: PRODUCT_UPLOAD_UPLOAD_SUCCESS,
            parent: action,
          })
          listener.complete()
        },

        onError: (error) =>
          listener.next({
            type: PRODUCT_UPLOAD_UPLOAD_ERROR,
            parent: action,
            error,
          }),

        onIdCreated: (id) =>
          listener.next({
            type: PRODUCT_UPLOAD_UPLOAD_ID_READY,
            parent: action,
            id,
          }),

        onProgress: (progress) =>
          listener.next({
            type: PRODUCT_UPLOAD_UPLOAD_PROGRESS,
            parent: action,
            progress,
          }),
      })
      return listener
    }),
  )

const uploadProductsFileSuccessEpic = (action$) =>
  action$.pipe(
    ofType(PRODUCT_UPLOAD_UPLOAD_SUCCESS),
    mapTo({
      type: PRODUCT_UPLOAD_LIST,
    }),
  )

const uploadProductsFileSuccessNotificationEpic = (action$) =>
  action$.pipe(
    ofType(PRODUCT_UPLOAD_UPLOAD_SUCCESS),
    map(({ parent: { successNotification } }) =>
      notificationActions.enqueueNotification(successNotification),
    ),
  )

const uploadProductsFileErrorNotificationEpic = (action$) =>
  action$.pipe(
    ofType(PRODUCT_UPLOAD_UPLOAD_ERROR),
    map(({ parent: { errorNotification } }) =>
      notificationActions.enqueueNotification(errorNotification),
    ),
  )

const epics = [
  listProductUploadsEpic,
  uploadProductsFileEpic,
  uploadProductsFileSuccessEpic,
  uploadProductsFileSuccessNotificationEpic,
  uploadProductsFileErrorNotificationEpic,
]

// reducer

const initalState = {
  list: [],
  uploads: {},
  currentUploadProgress: 0,
  currentUploadId: undefined,
  currentUploadName: "",
  currentUploadError: undefined,
  uploading: false,
}

const reducer = (state = initalState, { type, ...action }) => {
  switch (type) {
    case PRODUCT_UPLOAD_LIST:
      return {
        ...state,
        loadingList: true,
      }
    case PRODUCT_UPLOAD_LIST_SUCCESS: {
      const {
        payload: {
          uploads: newUploads,
          meta: { total },
        },
      } = action

      const processed = newUploads.map((item) => {
        const fileNameParts = item.fileName.split("/")

        return {
          ...item,
          createdAtString: new Date(item.createdAt).toISOString(),
          name: fileNameParts[fileNameParts.length - 1],
        }
      })

      const normalized = normalize(processed, productUploadListSchema)
      const list = normalized.result || []
      const { uploads } = state

      return {
        ...state,
        loadingList: false,
        list,
        uploads: {
          ...uploads,
          ...normalized.entities.productUpload,
        },
        total,
        loadingListError: undefined,
      }
    }
    case PRODUCT_UPLOAD_LIST_ERROR:
      return {
        ...state,
        loadingList: false,
        list: [],
        total: 0,
        loadingListError: action.error,
      }

    case PRODUCT_UPLOAD_UPLOAD:
      return {
        ...state,
        uploading: true,
        currentUploadName: action.file.name,
      }

    case PRODUCT_UPLOAD_UPLOAD_ID_READY:
      return {
        ...state,
        currentUploadId: action.id,
      }

    case PRODUCT_UPLOAD_UPLOAD_PROGRESS:
      return {
        ...state,
        currentUploadProgress: action.progress.loaded / action.progress.total,
        currentUploadLoaded: action.progress.loaded,
        currentUploadTotal: action.progress.total,
      }

    case PRODUCT_UPLOAD_UPLOAD_SUCCESS:
      return {
        ...state,
        uploading: false,
        currentUploadError: undefined,
      }

    case PRODUCT_UPLOAD_UPLOAD_ERROR:
      return {
        ...state,
        uploading: false,
        currentUploadError: action.error,
      }

    case PRODUCT_UPLOAD_CLEAR_UPLOAD:
      return {
        ...state,
        uploading: false,
        currentUploadId: undefined,
        currentUploadName: "",
        currentUploadError: undefined,
        currentUploadProgress: 0,
        currentUploadLoaded: 0,
        currentUploadTotal: 0,
      }

    default:
  }

  return state
}

// exports

export { actionTypes, actionCreators, epics }

export default reducer
