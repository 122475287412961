import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { Skeleton, Tooltip, chipClasses } from "@mui/material"
import Box from "@mui/material/Box"
import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import { useQuery } from "@tanstack/react-query"
import { instanceOf, oneOf } from "prop-types"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import * as api from "../../../../api/service"
import * as ActionTypes from "../../../../ducks/widgets/product-search/action-types"
import {
  PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY,
} from "../../../../ducks/widgets/product-search/constants"
import { useProductSearch } from "../../../../ducks/widgets/product-search/hooks"
import { STATUS_APPROVED } from "../../../../util/match-status"
import { DXIcon } from "../../../dx-icon/DXIcon"
import { CounterChip } from "../../../ui/CounterChip/CounterChip"
import { EANS } from "../EANS"
import { ProductImage } from "../ProductImage"
import { Content } from "../Table/Content"
import { RefProductAutocomplete } from "./RefProductAutocomplete"
import { ReferencePrice } from "./ReferencePrice"

function ProductSearchToolbar({ selectedRefProduct: referenceProduct, size }) {
  const theme = useTheme()
  const {
    referenceProductId,
    state: {
      [PRODUCT_SEARCH_WIDGET_REF_PROD_LIST_KEY]: list,
      [PRODUCT_SEARCH_WIDGET_REF_PROD_PAGE_KEY]: index,
      isLoading,
    },
  } = useProductSearch()

  const { data: totalApproved } = useQuery({
    enabled: referenceProductId !== "",
    queryKey: ["match-status-summary", referenceProductId],
    queryFn: async () => {
      const { approved } = await api.getMatchStatusSummary({ referenceProductId })
      return approved
    },
  })

  const dispatch = useDispatch()

  function onNext() {
    dispatch({
      type: ActionTypes.PRODUCT_SEARCH_SET_REF_PRODUCT_PAGE,
      page: index + 1,
    })
  }

  function onPrevious() {
    dispatch({
      type: ActionTypes.PRODUCT_SEARCH_SET_REF_PRODUCT_PAGE,
      page: index - 1,
    })
  }

  useEffect(() => {}, [])

  const isFirst = index === 0
  const isNextDisabled = index === list.length - 1 || list.length === 0

  return (
    <Box
      display="flex"
      pl={4}
      py={1}
      alignItems="center"
      overflow="auto"
      sx={{
        backgroundColor: "#FAFAFA",
        border: `solid 1px ${theme.palette.borderColor}`,
      }}
    >
      <ProductImage
        isLoading={isLoading}
        size={size}
        src={referenceProduct?.images}
        alt={referenceProduct?.brand}
      />
      <Box pl={1} pr={3}>
        <RefProductAutocomplete
          defaultValue={referenceProduct?.name}
          isLoading={isLoading}
        />
      </Box>
      <CounterChip
        isLoading={isLoading}
        tooltip={`${totalApproved} Approved matches (active)`}
        label={totalApproved}
        variant="outlined"
        icon={
          <DXIcon className={chipClasses.icon} size="small" type={STATUS_APPROVED} />
        }
      />
      <Box px={3}>
        <ToggleButtonGroup size="small" color="primary">
          <Tooltip title="Previous" placement="bottom" arrow>
            <ToggleButton disabled={isLoading || isFirst} onClick={onPrevious}>
              <ArrowLeftIcon color="rgba(0, 0, 0, 0.56)" />
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Next" placement="bottom" arrow>
            <ToggleButton disabled={isLoading || isNextDisabled} onClick={onNext}>
              <ArrowRightIcon color="rgba(0, 0, 0, 0.56)" />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Box>
      <Typography
        textTransform="capitalize"
        boxSizing="border-box"
        px={1}
        ml="143px"
        width={160}
        variant="body2"
      >
        {isLoading ? <Skeleton /> : referenceProduct?.brand}
      </Typography>
      <Typography boxSizing="border-box" px={1} width={178}>
        {isLoading ? <Skeleton /> : <EANS eans={referenceProduct?.eans} />}
      </Typography>
      <Typography boxSizing="border-box" px={1} width={96}>
        {isLoading ? <Skeleton /> : <Content content={referenceProduct?.contents_v1} />}
      </Typography>
      <ReferencePrice
        isLoading={isLoading}
        boxSizing="border-box"
        px={1}
        width={95}
        price={referenceProduct?.listings?.[0]?.price}
      />
    </Box>
  )
}
ProductSearchToolbar.propTypes = {
  size: oneOf(["small", "medium", "large"]),
  selectedRefProduct: instanceOf(Object).isRequired,
}

ProductSearchToolbar.defaultProps = {
  size: "small",
}

export { ProductSearchToolbar }
