import {
  dialogClasses,
  dialogTitleClasses,
  iconButtonClasses,
  inputAdornmentClasses,
  tableRowClasses,
} from "@mui/material"
import { createTheme } from "@mui/material/styles"

/**
 * @type {import('@mui/material').Components<import('@mui/material/styles').Theme>['MuiButton']}
 */
const buttonTheme = {
  defaultProps: {
    size: "small",
    variant: "outlined",
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      if (ownerState.variant === "text") {
        return {
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
            textDecorationThickness: 1,
          },
        }
      }

      if (theme.palette[ownerState.color]) {
        return {
          "&:hover": {
            backgroundColor: theme.palette[ownerState.color].main,
            color: "#fff",
          },
        }
      }

      return undefined
    },
  },
}

export const latestTheme = createTheme({
  typography: {
    fontFamily: "Graphie",
    button: {
      textTransform: "none",
    },
    subtitle1: {
      fontSize: "1rem",
      lineHeight: "1.25rem",
      color: "rgba(0, 0, 0, 0.87)",
      letterSpacing: "0.15px",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.5rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      letterSpacing: "0.17px",
    },
  },
  components: {
    MuiLoadingButton: buttonTheme,
    MuiButton: buttonTheme,
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: "1.188rem",
          lineHeight: "1.5rem",
          padding: "1rem",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.dx-full-dialog .${dialogClasses.paper}`]: {
            height: "100vh",
            width: "100vw",
          },
          [`.${dialogTitleClasses.root} .${iconButtonClasses.root} svg`]: {
            color: theme.palette.common.black,
          },
        }),
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          [`&.${inputAdornmentClasses.root} svg`]: {
            color: theme.palette.common.black,
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderCollapse: "separate",
          boxSizing: "border-box",
          "&.bordered td:first-child": {
            borderLeft: `1px solid ${theme.palette.borderColor}`,
          },
          "&.bordered td:last-child, &.bordered th:last-child": {
            borderRight: `1px solid ${theme.palette.borderColor}`,
          },
          "&.bordered th": {
            borderTop: `1px solid ${theme.palette.borderColor}`,
          },
          td: {
            padding: "0.5rem",
          },
          th: {
            padding: "1rem 0.5rem",
            color: "rgba(0, 0, 0, 0.6)",
            weight: 400,
          },
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          [`&.${tableRowClasses.hover}:hover td`]: {
            backgroundColor: "#f4f4f4",
          },
          ".editable-cell-icon": {
            backgroundColor: "#f4f4f4",
            zIndex: 1,
            width: "1.5rem",
            visibility: "hidden",
            position: "absolute",
            cursor: "pointer",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.borderColor,
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: "top",
      },
    },
  },
  palette: {
    common: {
      black: "#111111",
      white: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#fff",
    },
    primary: {
      main: "#524488",
      contrastText: "#fff",
    },
    secondary: {
      main: "#F59C00",
      contrastText: "#fff",
    },
    success: {
      main: "rgba(15, 177, 162, 1)",
      dark: "rgba(13, 149, 136, 1)",
      contrastText: "#fff",
    },
    error: {
      main: "#D12C1A",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },

    // Custom colors
    borderColor: "#EBEBEB",
  },
})
