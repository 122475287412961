import { useSplitTreatments } from "@splitsoftware/splitio-react"
import { featureNamesMap } from "./featureNamesMap"
import { ANONYMOUS_ID, ON, TRAFFIC_TYPE } from "./constants"
import useCustomer from "../../hooks/use-customer"

const names = [
  featureNamesMap.ASSORTMENT_ANALYSIS_CUSTOMER,
  featureNamesMap.PRODUCT_SEARCH_WIDGET,
  featureNamesMap.MATCH_OVERVIEW_FLAGS,
  featureNamesMap.PRODUCT_SEARCH_WIDGET_IN_REVIEW,
]

export function useAccountTreatments() {
  const customer = useCustomer()
  const splitKey = customer.code || ANONYMOUS_ID

  const proxy = useSplitTreatments({
    names,
    splitKey,
    trafficType: TRAFFIC_TYPE.account, // by customer code
  })

  function has(featureName) {
    return proxy.treatments[featureName]?.treatment === ON
  }

  return { ...proxy, has }
}
