import { useMemo } from "react"
import { toBidimensionalStringArray } from "../util/array"
import { Logger } from "../util/log"
import useCustomer from "./use-customer"
import usePermissions from "./use-permissions"
import { useAccountTreatments, useUserTreatments } from "../integrations/split"

const log = new Logger("hooks:use-restricted")

export const defaultRequiredFeatureFlags = []
export const defaultRequiredFeatures = []
export const defaultRequiredPermissions = []

function check({
  requiredFeatures = defaultRequiredFeatures,
  requiredPermissions = defaultRequiredPermissions,
  customer,
  userPermissions,
}) {
  log.debug(
    "checking permissions",
    requiredFeatures,
    requiredPermissions,
    customer,
    userPermissions,
  )

  const requiredCustomerFeatures = toBidimensionalStringArray(requiredFeatures)

  const sufficientFeatures =
    requiredFeatures.length === 0 ||
    requiredCustomerFeatures.some((features) =>
      features.every((feature) => customer[feature]),
    )

  const requiredUserPermissions = toBidimensionalStringArray(requiredPermissions)

  const sufficientPermissions =
    requiredUserPermissions.lenght === 0 ||
    requiredUserPermissions.some((permissions) =>
      permissions.every((permission) => userPermissions.includes(permission)),
    )

  log.debug(
    "permissions result:",
    requiredCustomerFeatures,
    requiredUserPermissions,
    sufficientFeatures,
    sufficientPermissions,
  )

  return sufficientFeatures && sufficientPermissions
}

/**
 * Determines if the user has access based on the required feature flags, features, and permissions.
 *
 * @param {Object} options - The options for access calculation.
 * @param {string[]} options.requiredFeatureFlags
 *  The name(s) of the feature flags the user requires to have access (for experimental features).
 * @param {string[]} options.requiredFeatures
 *  The name(s) of the released features the user requires to have access (for stable features).
 * @param {string[]} options.requiredPermissions
 *  The scope(s) the user requires to have access (authorization).
 *
 * @returns {boolean} - Whether access is granted to the user or not.
 */
function useRestricted({
  requiredFeatureFlags = defaultRequiredFeatureFlags,
  requiredFeatures = defaultRequiredFeatures,
  requiredPermissions = defaultRequiredPermissions,
}) {
  const customer = useCustomer({ defaultValue: {} })
  const userPermissions = usePermissions()

  const userTreatments = useUserTreatments()
  const accountTreatments = useAccountTreatments()

  const requiredFeaturesString = JSON.stringify(requiredFeatures)
  const requiredPermissionsString = JSON.stringify(requiredPermissions)

  const hasFeaturesFlags = requiredFeatureFlags.every(
    (feature) => accountTreatments.has(feature) || userTreatments.has(feature),
  )

  const hasScopes = useMemo(
    () =>
      check({
        requiredFeatures: JSON.parse(requiredFeaturesString),
        requiredPermissions: JSON.parse(requiredPermissionsString),
        customer,
        userPermissions,
      }),
    [requiredFeaturesString, requiredPermissionsString, customer, userPermissions],
  )

  return hasScopes && hasFeaturesFlags
}

export default useRestricted
