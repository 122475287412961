import { successType, errorType } from "../../util/redux-observable-helpers"

export const MATCH_GET_IDS = "@match/GET_IDS"
export const MATCH_GET_IDS_SUCCESS = successType(MATCH_GET_IDS)
export const MATCH_GET_IDS_ERROR = errorType(MATCH_GET_IDS)

export const MATCH_LIST_COUNTRIES = "@match/LIST_COUNTRIES"
export const MATCH_LIST_COUNTRIES_SUCCESS = successType(MATCH_LIST_COUNTRIES)
export const MATCH_LIST_COUNTRIES_ERROR = errorType(MATCH_LIST_COUNTRIES)

export const MATCH_STATUS_SUMMARY = "@match/STATUS_SUMMARY"
export const MATCH_STATUS_SUMMARY_SUCCESS = successType(MATCH_STATUS_SUMMARY)
export const MATCH_STATUS_SUMMARY_ERROR = errorType(MATCH_STATUS_SUMMARY)

export const MATCH_LIST_SOURCES = "@match/LIST_SOURCES"
export const MATCH_LIST_SOURCES_SUCCESS = successType(MATCH_LIST_SOURCES)
export const MATCH_LIST_SOURCES_ERROR = errorType(MATCH_LIST_SOURCES)

export const MATCH_TRIGGER_LIST = "@match/TRIGGER_LIST"

export const MATCH_LIST = "@match/LIST"
export const MATCH_LIST_SUCCESS = successType(MATCH_LIST)
export const MATCH_LIST_ERROR = errorType(MATCH_LIST)

export const MATCH_GET_SHOPS = "@match/GET_SHOPS"
export const MATCH_GET_SHOPS_SUCCESS = successType(MATCH_GET_SHOPS)
export const MATCH_GET_SHOPS_ERROR = errorType(MATCH_GET_SHOPS)

export const MATCH_GET_REF_PROD_CATEGORIES = "@match/GET_REF_PROD_CATEGORIES"
export const MATCH_GET_REF_PROD_CATEGORIES_SUCCESS = successType(
  MATCH_GET_REF_PROD_CATEGORIES,
)
export const MATCH_GET_REF_PROD_CATEGORIES_ERROR = errorType(
  MATCH_GET_REF_PROD_CATEGORIES,
)

export const MATCH_CLEAR_LIST = "@match/CLEAR_LIST"

export const MATCH_MATCH = "@match/MATCH"
export const MATCH_MATCH_SUCCESS = successType(MATCH_MATCH)
export const MATCH_MATCH_ERROR = errorType(MATCH_MATCH)

export const MATCH_CREATE_MANUAL = "@match/CREATE_MANUAL"
export const MATCH_CREATE_MANUAL_SUCCESS = successType(MATCH_CREATE_MANUAL)
export const MATCH_CREATE_MANUAL_ERROR = errorType(MATCH_CREATE_MANUAL)

export const MATCH_UPDATE_BASIC = "@match/UPDATE_BASIC"
export const MATCH_UPDATE_BASIC_SUCCESS = successType(MATCH_UPDATE_BASIC)
export const MATCH_UPDATE_BASIC_ERROR = errorType(MATCH_UPDATE_BASIC)

export const MATCH_APPROVE = "@match/APPROVE"
export const MATCH_APPROVE_SUCCESS = successType(MATCH_APPROVE)
export const MATCH_APPROVE_ERROR = errorType(MATCH_APPROVE)

export const MATCH_REMOVE_APPROVAL = "@match/REMOVE_APPROVAL"
export const MATCH_REMOVE_APPROVAL_SUCCESS = successType(MATCH_REMOVE_APPROVAL)
export const MATCH_REMOVE_APPROVAL_ERROR = errorType(MATCH_REMOVE_APPROVAL)

export const MATCH_DISCARD = "@match/DISCARD"
export const MATCH_DISCARD_SUCCESS = successType(MATCH_DISCARD)
export const MATCH_DISCARD_ERROR = errorType(MATCH_DISCARD)

export const MATCH_RESTORE = "@match/RESTORE"
export const MATCH_RESTORE_SUCCESS = successType(MATCH_RESTORE)
export const MATCH_RESTORE_ERROR = errorType(MATCH_RESTORE)

export const MATCH_FILTER_BY_SOURCE = "@match/FILTER_BY_SOURCE"
export const MATCH_FILTER_BY_STATUS = "@match/FILTER_BY_STATUS"
export const MATCH_FILTER_BY_COUNTRY = "@match/FILTER_BY_COUNTRY"
export const MATCH_FILTER_BY_BRAND = "@match/FILTER_BY_BRAND"
export const MATCH_FILTER_BY_FACTOR = "@match/MATCH_FILTER_BY_FACTOR"
export const MATCH_FILTER_BY_SHOP = "@match/FILTER_BY_SHOP"
export const MATCH_FILTER_BY_AVAILABILITY = "@match/FILTER_BY_AVAILABILITY"
export const MATCH_FILTER_BY_REF_PROD_QUERY = "@match/FILTER_BY_REF_PROD_QUERY"
export const MATCH_START_FILTER_BY_REF_PROD_COUNTRY =
  "@match/START_FILTER_BY_REF_PROD_COUNTRY"
export const MATCH_FILTER_BY_REF_PROD_COUNTRY = "@match/FILTER_BY_REF_PROD_COUNTRY"
export const MATCH_START_FILTER_BY_REF_PROD_FAMILY =
  "@match/START_FILTER_BY_REF_PROD_FAMILY"
export const MATCH_FILTER_BY_REF_PROD_FAMILY = "@match/FILTER_BY_REF_PROD_FAMILY"
export const MATCH_START_FILTER_BY_REF_PROD_L1_CATEGORIES =
  "@match/START_FILTER_BY_REF_PROD_L1_CATEGORIES"
export const MATCH_START_FILTER_BY_REF_PROD_L2_CATEGORIES =
  "@match/START_FILTER_BY_REF_PROD_L2_CATEGORIES"
export const MATCH_START_FILTER_BY_REF_PROD_L3_CATEGORIES =
  "@match/START_FILTER_BY_REF_PROD_L3_CATEGORIES"
export const MATCH_START_FILTER_BY_REF_PROD_L4_CATEGORIES =
  "@match/START_FILTER_BY_REF_PROD_L4_CATEGORIES"
export const MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES =
  "@match/FILTER_BY_REF_PROD_L1_CATEGORIES"
export const MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES =
  "@match/FILTER_BY_REF_PROD_L2_CATEGORIES"
export const MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES =
  "@match/FILTER_BY_REF_PROD_L3_CATEGORIES"
export const MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES =
  "@match/FILTER_BY_REF_PROD_L4_CATEGORIES"
export const MATCH_UPDATE_REF_PROD_L2_CATEGORIES =
  "@match/UPDATE_REF_PROD_L2_CATEGORIES"
export const MATCH_UPDATE_REF_PROD_L3_CATEGORIES =
  "@match/UPDATE_REF_PROD_L3_CATEGORIES"
export const MATCH_UPDATE_REF_PROD_L4_CATEGORIES =
  "@match/UPDATE_REF_PROD_L4_CATEGORIES"

export const MATCH_SET_REVIEW_PAGE_SETTINGS = "@match/SET_REVIEW_PAGE_SETTINGS"

export const MATCH_SET_PAGE = "@match/SET_PAGE"
