/* eslint-disable max-len */
import {
  successType,
  errorType,
  invalidType,
  cancelType,
} from "../../util/redux-observable-helpers"

// Action Types

export const REF_PRODUCT_SUMMARY = "@referenceProduct/SUMMARY"
export const REF_PRODUCT_SUMMARY_SUCCESS = successType(REF_PRODUCT_SUMMARY)
export const REF_PRODUCT_SUMMARY_ERROR = errorType(REF_PRODUCT_SUMMARY)

export const REF_PRODUCT_GET_CATEGORIES = "@referenceProduct/GET_CATEGORIES"
export const REF_PRODUCT_GET_CATEGORIES_SUCCESS = successType(
  REF_PRODUCT_GET_CATEGORIES,
)
export const REF_PRODUCT_GET_CATEGORIES_ERROR = errorType(REF_PRODUCT_GET_CATEGORIES)

export const REF_PRODUCT_TRIGGER_LIST = "@referenceProduct/TRIGGER_LIST"

export const REF_PRODUCT_LIST = "@referenceProduct/LIST"
export const REF_PRODUCT_LIST_SUCCESS = successType(REF_PRODUCT_LIST)
export const REF_PRODUCT_LIST_ERROR = errorType(REF_PRODUCT_LIST)

export const REF_PRODUCT_CLEAR_CACHE = "@referenceProduct/CLEAR_CACHE"

export const REF_PRODUCT_LIST_SUGGESTIONS = "@referenceProduct/LIST_SUGGESTIONS"
export const REF_PRODUCT_LIST_SUGGESTIONS_CANCEL = cancelType(
  REF_PRODUCT_LIST_SUGGESTIONS,
)
export const REF_PRODUCT_LIST_SUGGESTIONS_SUCCESS = successType(
  REF_PRODUCT_LIST_SUGGESTIONS,
)
export const REF_PRODUCT_LIST_SUGGESTIONS_ERROR = errorType(
  REF_PRODUCT_LIST_SUGGESTIONS,
)

export const REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS =
  "@referenceProduct/LIST_AUTO_COMPLETE_SUGGESTIONS"
export const REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_CANCEL = cancelType(
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS,
)
export const REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_SUCCESS = successType(
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS,
)
export const REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_ERROR = errorType(
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS,
)

export const REF_PRODUCT_GET_BY_IDS = "@referenceProduct/GET_BY_IDS"
export const REF_PRODUCT_GET_BY_IDS_SUCCESS = successType(REF_PRODUCT_GET_BY_IDS)
export const REF_PRODUCT_GET_BY_IDS_ERROR = errorType(REF_PRODUCT_GET_BY_IDS)

export const REF_PRODUCT_SORT_BY = "@referenceProduct/SORT_BY"

export const REF_PRODUCT_FILTER_BY_STATUS = "@referenceProduct/FILTER_BY_STATUS"
export const REF_PRODUCT_FILTER_BY_COUNTRY = "@referenceProduct/FILTER_BY_COUNTRY"
export const REF_PRODUCT_FILTER_BY_FAMILY = "@referenceProduct/FILTER_BY_FAMILY"
export const REF_PRODUCT_FILTER_BY_QUERY = "@referenceProduct/FILTER_BY_QUERY"
export const REF_PRODUCT_FILTER_BY_ID = "@referenceProduct/FILTER_BY_ID"
export const REF_PRODUCT_START_FILTER_BY_L1_CATEGORIES =
  "@referenceProduct/START_FILTER_BY_L1_CATEGORIES"
export const REF_PRODUCT_START_FILTER_BY_L2_CATEGORIES =
  "@referenceProduct/START_FILTER_BY_L2_CATEGORIES"
export const REF_PRODUCT_START_FILTER_BY_L3_CATEGORIES =
  "@referenceProduct/START_FILTER_BY_L3_CATEGORIES"
export const REF_PRODUCT_START_FILTER_BY_L4_CATEGORIES =
  "@referenceProduct/START_FILTER_BY_L4_CATEGORIES"
export const REF_PRODUCT_FILTER_BY_L1_CATEGORIES =
  "@referenceProduct/FILTER_BY_L1_CATEGORIES"
export const REF_PRODUCT_FILTER_BY_L2_CATEGORIES =
  "@referenceProduct/FILTER_BY_L2_CATEGORIES"
export const REF_PRODUCT_FILTER_BY_L3_CATEGORIES =
  "@referenceProduct/FILTER_BY_L3_CATEGORIES"
export const REF_PRODUCT_FILTER_BY_L4_CATEGORIES =
  "@referenceProduct/FILTER_BY_L4_CATEGORIES"

export const REF_PRODUCT_UPDATE_L2_CATEGORIES = "@referenceProduct/UPDATE_L2_CATEGORIES"
export const REF_PRODUCT_UPDATE_L3_CATEGORIES = "@referenceProduct/UPDATE_L3_CATEGORIES"
export const REF_PRODUCT_UPDATE_L4_CATEGORIES = "@referenceProduct/UPDATE_L4_CATEGORIES"

export const REF_PRODUCT_CLEAR_LIST = "@referenceProduct/CLEAR_LIST"

export const REF_PRODUCT_START_SELECT = "@referenceProduct/START_SELECT"
export const REF_PRODUCT_SELECT = "@referenceProduct/SELECT"
export const REF_PRODUCT_SELECT_ERROR = "@referenceProduct/SELECT_ERROR"

export const REF_PRODUCT_USE_FALLBACK_IMAGE = "@referenceProduct/USE_FALLBACK_IMAGE"

export const REF_PRODUCT_UPDATE_CONTENT = "@referenceProduct/UPDATE_CONTENT"

export const REF_PRODUCT_LIST_EXISTING_MATCHES =
  "@referenceProduct/LIST_EXISTING_MATCHES"
export const REF_PRODUCT_LIST_EXISTING_MATCHES_SUCCESS = successType(
  REF_PRODUCT_LIST_EXISTING_MATCHES,
)
export const REF_PRODUCT_LIST_EXISTING_MATCHES_ERROR = errorType(
  REF_PRODUCT_LIST_EXISTING_MATCHES,
)
export const REF_PRODUCT_LIST_EXISTING_MATCHES_INVALID = invalidType(
  REF_PRODUCT_LIST_EXISTING_MATCHES,
)

export const REF_PRODUCT_LIST_SUGGESTED_MATCHES =
  "@referenceProduct/LIST_SUGGESTED_MATCHES"
export const REF_PRODUCT_LIST_SUGGESTED_MATCHES_SUCCESS = successType(
  REF_PRODUCT_LIST_SUGGESTED_MATCHES,
)
export const REF_PRODUCT_LIST_SUGGESTED_MATCHES_ERROR = errorType(
  REF_PRODUCT_LIST_SUGGESTED_MATCHES,
)
export const REF_PRODUCT_LIST_SUGGESTED_MATCHES_INVALID = invalidType(
  REF_PRODUCT_LIST_SUGGESTED_MATCHES,
)

export const REF_PRODUCT_SELECT_MATCHED_SHOPS = "@referenceProduct/SELECT_MATCHED_SHOPS"

export const REF_PRODUCT_SELECT_MATCHED_BRANDS =
  "@referenceProduct/SELECT_MATCHED_BRANDS"

export const REF_PRODUCT_SELECT_MATCHED_COUNTRIES =
  "@referenceProduct/SELECT_MATCHED_COUNTRIES"

export const REF_PRODUCT_SET_SHOW_DISCARDED = "@referenceProduct/SET_SHOW_DISCARDED"

export const REF_PRODUCT_SELECT_MATCHED_PRODUCTS_AVAILABILITY =
  "@referenceProduct/SELECT_MATCHED_PRODUCTS_AVAILABILITY"

export const REF_PRODUCT_SELECT_MATCHED_PRODUCTS_FACTOR =
  "@referenceProduct/SELECT_MATCHED_PRODUCTS_FACTOR"
