import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied"
import { Tooltip, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"
import { useInfiniteQuery } from "@tanstack/react-query"
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"
import { oneOf } from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { useDispatch, useSelector } from "react-redux"
import { actionCreators as notificationActions } from "../../../../ducks/notification"
import { getOneMatchedProductById } from "../../../../ducks/repositories/matched-products/action-creators"
import {
  PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY,
  PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY,
  PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS,
} from "../../../../ducks/widgets/product-search/constants"
import { useProductSearch } from "../../../../ducks/widgets/product-search/hooks"
import { buildLexicographicallySortedMatchId } from "../../../../util/match"
import useProductComparisonCompare from "../../../product-comparison/hooks/use-product-comparison-compare"
import { columns } from "./Columns"
import { ProductSearchActions } from "./TableActions"
import { TableLoading } from "./TableLoading"
import { STATUS_UNMATCHED } from "../../../../util/match-status"

function getImageSize({ size }) {
  if (size === "medium") {
    return 48
  }

  if (size === "large") {
    return 48 * 2
  }

  return 0
}

function getPinStyle({ column, theme, isHeader }) {
  const isPinned = column.getIsPinned()
  const isLastLeft = column.columnDef.accessorKey === "status"
  const isLastRight = column.columnDef.id === "actions"

  const width = column.getSize()

  const isCheckbox = column.columnDef.id === "select"
  return {
    width,
    minWidth: width,
    maxWidth: width,
    backgroundColor: isPinned || isHeader ? theme.palette.common.white : undefined,
    borderRight: isLastLeft ? `1px solid ${theme.palette.borderColor}` : undefined,
    borderLeft:
      isLastRight || isCheckbox ? `1px solid ${theme.palette.borderColor}` : undefined,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getStart("right")}px` : undefined,
    position: isPinned ? "sticky" : undefined,
    zIndex: isPinned ? 1 : 0,
    padding: isCheckbox ? "0 !important" : undefined,
    boxSizing: isLastRight ? "content-box" : undefined,
  }
}

function ProductSearchTable({ size }) {
  const theme = useTheme()
  const tableRef = useRef()
  const { ref, inView } = useInView()
  const [rowSelection, setRowSelection] = React.useState({})
  const [sorting, setSorting] = useState([])
  const [newMatches, setNewMatches] = useState({})
  const { setSort, state, query, listProducts, referenceProduct } = useProductSearch()
  const dispatch = useDispatch()
  const {
    [PRODUCT_SEARCH_WIDGET_SELECTED_FILTERS]: filters,
    [PRODUCT_SEARCH_WIDGET_REF_PRODUCT_KEY]: referenceProductId,
    [PRODUCT_SEARCH_WIDGET_FILTERS_OPTIONS_KEY]: filterOptions,
  } = state
  const { open: openProductComparisonWidget } = useProductComparisonCompare()

  const { data, isFetching, hasNextPage, fetchNextPage, error } = useInfiniteQuery(
    ["product-seach", query, referenceProductId, filters],
    {
      queryFn: async ({ pageParam: page = 1 }) => {
        const pageSize = 20
        const { rows, total } = await listProducts(referenceProductId, {
          query,
          filters: {
            ...filters,
            page,
            pageSize,
          },
        })

        const nextPage = page * pageSize < total ? page + 1 : undefined
        return { rows, nextPage }
      },
      initialData: { pages: [] },
      getNextPageParam: (lastPage) => lastPage?.nextPage,
    },
  )

  const dataFlatten = useMemo(() => data.pages.flatMap((e) => e.rows), [data])

  const createdMatch = useSelector(
    ({ refProduct }) => refProduct?.matchedSuccess?.[referenceProductId],
  )

  useEffect(() => {
    if (!createdMatch || newMatches[createdMatch]) {
      return
    }

    const matchId = buildLexicographicallySortedMatchId(
      referenceProductId,
      createdMatch,
    )
    dispatch(getOneMatchedProductById({ id: matchId, invalidateCache: true }))
    setNewMatches((oldMatches) => ({
      ...oldMatches,
      [createdMatch]: matchId,
    }))
  }, [createdMatch, dispatch, newMatches, referenceProductId])

  useEffect(() => {
    setRowSelection({})
  }, [filters])

  // remove country column if only one country is avaliable
  const showCountry = useMemo(() => {
    if (!filterOptions.countries || Object.keys(filterOptions.countries).length <= 1) {
      return false
    }
    return true
  }, [filterOptions.countries])

  const table = useReactTable({
    data: dataFlatten,
    columns,
    enableMultiSort: false,
    enableSortingRemoval: true,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    sortDescFirst: false,
    initialState: {
      columnPinning: {
        right: ["actions"],
        left: ["select", "name", "status"],
      },
      columnVisibility: {
        country: showCountry,
      },
    },
    state: {
      sorting,
      columnSizing: {
        name: getImageSize({ size }) + 320,
      },
      rowSelection,
    },
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: (updater) => {
      setRowSelection({})

      const nextSorting = updater(sorting)
      setSorting(nextSorting)
      const [sort] = nextSorting
      const direction = sort?.desc ? "desc" : "asc"
      setSort(sort?.id, direction)
    },
  })

  const { rows } = table.getRowModel()

  const virtualizer = useVirtualizer({
    count: hasNextPage ? rows.length + 1 : rows.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => {
      switch (size) {
        case "large":
          return 170
        case "medium":
          return 130
        default:
          return 80
      }
    },
    fetchNextPage,
    measureElement:
      typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, inView])

  useEffect(() => {
    if (!error) {
      return
    }

    dispatch(
      notificationActions.enqueueNotification({
        message: "An error occurred while fetching products.",
        options: {
          variant: "error",
          persist: false,
        },
      }),
    )
  }, [dispatch, error])

  useEffect(() => {
    virtualizer?.measure?.()
  }, [size, virtualizer])

  const { toggleAllRowsSelected } = table
  useEffect(() => toggleAllRowsSelected(false), [toggleAllRowsSelected, filters])

  const headerPosition = virtualizer.scrollOffset
  const noResults = virtualizer.getVirtualItems().length === 0

  const canCompare = table.getSelectedRowModel().rows.length > 0
  const onCompare = (daltixId) => {
    openProductComparisonWidget(referenceProductId, [{ daltixId }])
  }
  const multiCompare = () => {
    const indexes = Object.keys(rowSelection)
    const daltixIds = indexes.map((idx) => ({ daltixId: rows[idx].original.id }))
    openProductComparisonWidget(referenceProductId, daltixIds)
  }

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <ProductSearchActions disabled={!canCompare} onClick={multiCompare} />
      <TableContainer sx={{ flexGrow: 1 }} ref={tableRef}>
        <Box height={virtualizer.getTotalSize()}>
          <Table
            className="bordered"
            size={size === "large" ? "medium" : size}
            sx={{ position: "relative" }}
          >
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow
                  key={headerGroup.id}
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    transition: "transform 0.5s",
                    transform: virtualizer.isScrolling
                      ? "translateY(0px)"
                      : `translateY(${headerPosition}px)`,
                  }}
                >
                  {headerGroup.headers.map((header) => (
                    <TableCell
                      align={header.column.columnDef.align}
                      sx={getPinStyle({
                        column: header.column,
                        theme,
                        isHeader: true,
                      })}
                      key={header.id}
                      width={header.column.columnDef.size}
                    >
                      {header.column.getCanSort() && (
                        <Tooltip
                          title={
                            header.column.columnDef.accessorKey === "factor"
                              ? 'Sorting by "MF" uses the suggested factor value when unknown.'
                              : ""
                          }
                          placement="bottom"
                        >
                          <TableSortLabel
                            active={header.column.getIsSorted() !== false}
                            direction={header.column.getIsSorted() || undefined}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {header.column.columnDef.header}
                          </TableSortLabel>
                        </Tooltip>
                      )}

                      {!header.column.getCanSort() && header.column.columnDef.header}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {virtualizer.getVirtualItems().map((virtualRow, index) => {
                const isLoaderRow = virtualRow.index === rows.length
                const sx = {
                  height: virtualRow.size,
                  transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                }

                if (isLoaderRow) {
                  return (
                    <TableRow
                      key="loader"
                      ref={ref}
                      data-index={virtualRow.index}
                      sx={sx}
                    >
                      <TableCell
                        sx={{ border: "none !important" }}
                        colSpan={columns.length}
                        align="center"
                      >
                        {!hasNextPage && "Nothing more to load"}
                      </TableCell>
                    </TableRow>
                  )
                }

                const row = rows[virtualRow.index]

                if (!row) {
                  return null
                }

                return (
                  <TableRow
                    hover
                    key={row.original.id}
                    data-index={virtualRow.index}
                    sx={sx}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        align={cell.column.columnDef.align}
                        sx={getPinStyle({
                          column: cell.column,
                          theme,
                        })}
                        key={cell.id}
                      >
                        {flexRender(cell.column.columnDef.cell, {
                          ...cell.getContext(),
                          size,
                          referenceProduct: referenceProduct?.data,
                          newMatches,
                          onCompare,
                        })}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <TableLoading isLoading={isFetching} />
      {!isFetching && noResults && (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography variant="h6" align="center" color="warning">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <SentimentVeryDissatisfiedIcon />
              No products for the selected filters
            </Box>
          </Typography>
        </Box>
      )}
    </Box>
  )
}

ProductSearchTable.propTypes = {
  size: oneOf(["small", "medium", "large"]),
}

ProductSearchTable.defaultProps = {
  size: "small",
}

export { ProductSearchTable }
