import PropTypes from "prop-types"
import React from "react"
import clsx from "clsx"
import { Badge } from "@mui/material"
import styles from "./ArticleDetailEntry.module.scss"

export function ArticleDetailEntry({ title, value, slot, debug, unsure }) {
  return (
    <div className={styles.container}>
      <Badge color="error" variant="dot" invisible={!unsure} sx={{ paddingRight: 1 }}>
        <h1 className={styles.title}>{title}</h1>
      </Badge>
      <div className={styles.content}>
        {slot}
        <p className={clsx(styles.value, debug && styles.debug)}>{value}</p>
      </div>
    </div>
  )
}

ArticleDetailEntry.propTypes = {
  title: PropTypes.string.isRequired,
  slot: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  debug: PropTypes.bool,
  unsure: PropTypes.bool,
}

ArticleDetailEntry.defaultProps = {
  slot: null,
  value: "",
  debug: false,
  unsure: false,
}
