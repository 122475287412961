import PropTypes from "prop-types"
import React, { useState } from "react"

import { titleCase } from "text-case"
import Badge from "@mui/material/Badge"
import Box from "@mui/material/Box"
import CloseIcon from "@mui/icons-material/Close"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import styles from "./AssortmentAnalysisArticleDataCell.module.scss"
import { ArticlePropType } from "../../prop-types"
import { Table } from "../../../ui"
import { EUR_SYMBOL, INFINITY_SYMBOL } from "../../../../util/constants"
import { ArticleDetail } from "./ArticleDetail"

function Article({ brand, content, imageSources, name, pricePerItem }) {
  return (
    <div className={styles.article}>
      <div className={styles.image}>
        <Table.CellImage sources={imageSources} />
      </div>
      <div className={styles.data}>
        <h1>{name}</h1>
        <h3>{brand}</h3>
        <p>
          {pricePerItem}
          {EUR_SYMBOL}
        </p>
        <p>{content}</p>
      </div>
    </div>
  )
}

Article.propTypes = {
  brand: PropTypes.string,
  content: PropTypes.string,
  imageSources: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  pricePerItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Article.defaultProps = {
  brand: "",
  content: "",
  imageSources: null,
  pricePerItem: null,
}

function AssortmentAnalysisArticleDataCell({ articles }) {
  const [open, setOpen] = useState("")

  const handleClose = () => {
    setOpen("")
  }

  return (
    <ul className={styles.items}>
      {articles.map((article) => {
        const {
          attributes,
          regularSuPrice: price,
          regularUnitPrice: unitPrice,
          unit,
          unsure,
        } = article
        const brand = attributes.find((a) => a.name === "brand")?.value
        const format = attributes.find((a) => a.name === "format")?.value
        const formatUnit = attributes.find((a) => a.name === "format_unit")?.value
        const image = attributes.find((a) => a.name === "image")?.value
        const name = attributes.find((a) => a.name === "name")?.value

        const images = typeof image === "string" ? [image] : null
        const pricePerItem = price ? Number(price).toFixed(2) : INFINITY_SYMBOL
        const pricePerUnit = unitPrice ? Number(unitPrice).toFixed(2) : INFINITY_SYMBOL
        const title = titleCase(name || "")

        const itemContent = format ? `${format}${formatUnit || unit || ""}` : null

        const key = `${article.shop}-${article.baseProductId}-${article.articleId}`
        return (
          <li key={key} className={styles.item}>
            <Badge
              component="div"
              color="error"
              variant="dot"
              invisible={!unsure}
              sx={{ margin: 0, padding: 0, width: "100%" }}
            >
              <Box
                className={styles["article-detail-trigger"]}
                onClick={() => setOpen(key)}
              >
                <Article
                  brand={brand}
                  content={itemContent}
                  imageSources={images}
                  name={title}
                  pricePerItem={pricePerItem}
                />
              </Box>
            </Badge>
            <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open === key}>
              <DialogTitle sx={{ textTransform: "capitalize" }}>
                <Badge
                  color="error"
                  variant="dot"
                  invisible={!unsure}
                  sx={{ paddingRight: 1 }}
                >
                  {title}
                </Badge>
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                <ArticleDetail
                  attributes={article.attributes}
                  imageSources={images}
                  pricePerItem={pricePerItem}
                  pricePerUnit={pricePerUnit}
                  unit={unit}
                  shop={article.shop}
                  articleIds={article.articleIds}
                  baseProductId={article.baseProductId}
                  turnover={article.turnover}
                  rotation={article.rotation}
                  year={article.year}
                />
              </DialogContent>
            </Dialog>
          </li>
        )
      })}
    </ul>
  )
}

AssortmentAnalysisArticleDataCell.propTypes = {
  articles: PropTypes.arrayOf(ArticlePropType).isRequired,
}
AssortmentAnalysisArticleDataCell.defaultProps = {}

export { AssortmentAnalysisArticleDataCell }
