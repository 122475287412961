import { NEW } from "../../util/statuses"

import {
  REF_PRODUCT_LIST,
  REF_PRODUCT_LIST_SUCCESS,
  REF_PRODUCT_LIST_ERROR,
  REF_PRODUCT_GET_BY_IDS_SUCCESS,
  REF_PRODUCT_GET_BY_IDS_ERROR,
  REF_PRODUCT_FILTER_BY_STATUS,
  REF_PRODUCT_SELECT,
  REF_PRODUCT_SELECT_ERROR,
  REF_PRODUCT_UPDATE_CONTENT,
  REF_PRODUCT_USE_FALLBACK_IMAGE,
  REF_PRODUCT_LIST_EXISTING_MATCHES,
  REF_PRODUCT_LIST_EXISTING_MATCHES_SUCCESS,
  REF_PRODUCT_LIST_EXISTING_MATCHES_ERROR,
  REF_PRODUCT_LIST_EXISTING_MATCHES_INVALID,
  REF_PRODUCT_LIST_SUGGESTED_MATCHES,
  REF_PRODUCT_LIST_SUGGESTED_MATCHES_SUCCESS,
  REF_PRODUCT_LIST_SUGGESTED_MATCHES_ERROR,
  REF_PRODUCT_LIST_SUGGESTED_MATCHES_INVALID,
  REF_PRODUCT_SELECT_MATCHED_SHOPS,
  REF_PRODUCT_SELECT_MATCHED_BRANDS,
  REF_PRODUCT_SELECT_MATCHED_COUNTRIES,
  REF_PRODUCT_SET_SHOW_DISCARDED,
  REF_PRODUCT_SELECT_MATCHED_PRODUCTS_AVAILABILITY,
  REF_PRODUCT_SELECT_MATCHED_PRODUCTS_FACTOR,
  REF_PRODUCT_FILTER_BY_ID,
  REF_PRODUCT_FILTER_BY_QUERY,
  REF_PRODUCT_FILTER_BY_COUNTRY,
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS,
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_SUCCESS,
  REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_ERROR,
  REF_PRODUCT_SUMMARY_SUCCESS,
  REF_PRODUCT_CLEAR_CACHE,
  REF_PRODUCT_SORT_BY,
  REF_PRODUCT_FILTER_BY_L1_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L2_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L3_CATEGORIES,
  REF_PRODUCT_FILTER_BY_L4_CATEGORIES,
  REF_PRODUCT_UPDATE_L2_CATEGORIES,
  REF_PRODUCT_UPDATE_L3_CATEGORIES,
  REF_PRODUCT_UPDATE_L4_CATEGORIES,
  REF_PRODUCT_CLEAR_LIST,
  REF_PRODUCT_GET_CATEGORIES,
  REF_PRODUCT_GET_CATEGORIES_SUCCESS,
  REF_PRODUCT_GET_CATEGORIES_ERROR,
  REF_PRODUCT_FILTER_BY_FAMILY,
} from "./action-types"

import {
  MATCH_CREATE_MANUAL,
  MATCH_UPDATE_BASIC,
  MATCH_APPROVE,
  MATCH_REMOVE_APPROVAL,
  MATCH_DISCARD,
  MATCH_RESTORE,
  MATCH_CREATE_MANUAL_SUCCESS,
  MATCH_UPDATE_BASIC_SUCCESS,
  MATCH_APPROVE_SUCCESS,
  MATCH_REMOVE_APPROVAL_SUCCESS,
  MATCH_DISCARD_SUCCESS,
  MATCH_RESTORE_SUCCESS,
  MATCH_CREATE_MANUAL_ERROR,
  MATCH_UPDATE_BASIC_ERROR,
  MATCH_APPROVE_ERROR,
  MATCH_REMOVE_APPROVAL_ERROR,
  MATCH_DISCARD_ERROR,
  MATCH_RESTORE_ERROR,
} from "../matches/action-types"

import {
  handleListReferenceProducts,
  handleListReferenceProductsSuccess,
  handleGetByIdsError,
  handleGetByIdsSuccess,
  handleListReferenceProductsError,
} from "./handlers/list-reference-products"

import {
  handleFilterReferenceProductsByStatus,
  handleFilterReferenceProductsById,
  handleFilterReferenceProductsByQuery,
  handleFilterReferenceProductsByCountry,
  handleFilterReferenceProductsByFamily,
  handleFilterReferenceProductsByL1Categories,
  handleFilterReferenceProductsByL2Categories,
  handleFilterReferenceProductsByL3Categories,
  handleFilterReferenceProductsByL4Categories,
  handleUpdateReferenceProductsL2Categories,
  handleUpdateReferenceProductsL3Categories,
  handleUpdateReferenceProductsL4Categories,
} from "./handlers/filter-reference-products"

import {
  handleListReferenceProductAutoCompleteSuggestionsError,
  handleListReferenceProductAutoCompleteSuggestionsSuccess,
  handleListReferenceProductAutoCompleteSuggestions,
} from "./handlers/suggestions"

import {
  handleSelectReferenceProduct,
  handleSelectReferenceProductError,
} from "./handlers/select-reference-product"

import { handleUseFallbackImage } from "./handlers/use-fallback-image"

import {
  handleListExistingMatches,
  handleListExistingMatchesSuccess,
  handleListExistingMatchesError,
  handleListExistingMatchesInvalid,
  handleListSuggestedMatches,
  handleListSuggestedMatchesSuccess,
  handleListSuggestedMatchesError,
  handleListSuggestedMatchesInvalid,
  handleSelectMatchedShops,
  handleSelectMatchedBrands,
  handleSelectMatchedCountries,
  handleSetShowDiscardedMatches,
  handleSelectMatchedProductsAvailability,
  handleSelectMatchedProductsFactor,
} from "./handlers/list-matches"

import {
  handleMatch,
  handleMatchSuccess,
  handleMatchError,
} from "./handlers/match-product"

import { handleGetReferenceProductsSummarySuccess } from "./handlers/summary"
import { handleClearRefProductsCache } from "./handlers/clear-cache"
import { handleSortBy } from "./handlers/sort-by"
import { handleClearReferenceProductsList } from "./handlers/clear-ref-prof-list"
import categoriesReducer from "../category"
import {
  handleGetCategories,
  handleGetCategoriesError,
  handleGetCategoriesSuccess,
} from "./handlers/get-categories-tree"
import { getSettings } from "../../util/local-storage"
import { defaultProductAvailabilityValues } from "../../util/product-availability"
import { defaultProductFactorValues } from "../../util/product-factor"

const settings = getSettings()

// reducer

const initialState = {
  list: [],
  loadingProductPages: [],
  prevPageList: [],
  nextPageList: [],
  referenceProducts: {},
  existingMatches: {},
  suggestedMatches: {},
  detailedProductId: undefined,
  detailedProduct: undefined,
  detailedProductIndex: undefined,
  productCount: undefined,
  page: undefined,
  loadingExistingMatches: [],
  loadingSuggestedMatches: [],
  referenceProductsStatus: NEW,
  selectedMatchedShops: [],
  selectedMatchedBrands: [],
  selectedMatchedCountries: [],
  selectedMatchedProductsAvailability:
    settings.pageMakeMatchesDefaultMatchedProductsAvailability ||
    defaultProductAvailabilityValues,
  selectedMatchedProductsFactor: defaultProductFactorValues,
  suggestions: [],
  autoCompleteSuggestions: [],
  suggestionsTotal: 0,
  selectedCountries: [],
  selectedFamilies: [],
  l2CategoriesOptions: [],
  l3CategoriesOptions: [],
  l4CategoriesOptions: [],
  selectedL1Categories: [],
  selectedL2Categories: [],
  selectedL3Categories: [],
  selectedL4Categories: [],
  sortBy: "name",
  categories: categoriesReducer(undefined, {}),
}

const handlerMap = {
  [REF_PRODUCT_SUMMARY_SUCCESS]: handleGetReferenceProductsSummarySuccess,

  [REF_PRODUCT_GET_CATEGORIES]: handleGetCategories,
  [REF_PRODUCT_GET_CATEGORIES_SUCCESS]: handleGetCategoriesSuccess,
  [REF_PRODUCT_GET_CATEGORIES_ERROR]: handleGetCategoriesError,

  [REF_PRODUCT_LIST]: handleListReferenceProducts,
  [REF_PRODUCT_LIST_SUCCESS]: handleListReferenceProductsSuccess,
  [REF_PRODUCT_LIST_ERROR]: handleListReferenceProductsError,

  [REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS]:
    handleListReferenceProductAutoCompleteSuggestions,
  [REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_SUCCESS]:
    handleListReferenceProductAutoCompleteSuggestionsSuccess,
  [REF_PRODUCT_LIST_AUTO_COMPLETE_SUGGESTIONS_ERROR]:
    handleListReferenceProductAutoCompleteSuggestionsError,

  [REF_PRODUCT_CLEAR_CACHE]: handleClearRefProductsCache,
  [REF_PRODUCT_CLEAR_LIST]: handleClearReferenceProductsList,

  [REF_PRODUCT_GET_BY_IDS_ERROR]: handleGetByIdsError,
  [REF_PRODUCT_GET_BY_IDS_SUCCESS]: handleGetByIdsSuccess,

  [REF_PRODUCT_SORT_BY]: handleSortBy,
  [REF_PRODUCT_FILTER_BY_STATUS]: handleFilterReferenceProductsByStatus,
  [REF_PRODUCT_FILTER_BY_QUERY]: handleFilterReferenceProductsByQuery,
  [REF_PRODUCT_FILTER_BY_ID]: handleFilterReferenceProductsById,
  [REF_PRODUCT_FILTER_BY_COUNTRY]: handleFilterReferenceProductsByCountry,
  [REF_PRODUCT_FILTER_BY_FAMILY]: handleFilterReferenceProductsByFamily,
  [REF_PRODUCT_FILTER_BY_L1_CATEGORIES]: handleFilterReferenceProductsByL1Categories,
  [REF_PRODUCT_FILTER_BY_L2_CATEGORIES]: handleFilterReferenceProductsByL2Categories,
  [REF_PRODUCT_FILTER_BY_L3_CATEGORIES]: handleFilterReferenceProductsByL3Categories,
  [REF_PRODUCT_FILTER_BY_L4_CATEGORIES]: handleFilterReferenceProductsByL4Categories,

  [REF_PRODUCT_UPDATE_L2_CATEGORIES]: handleUpdateReferenceProductsL2Categories,
  [REF_PRODUCT_UPDATE_L3_CATEGORIES]: handleUpdateReferenceProductsL3Categories,
  [REF_PRODUCT_UPDATE_L4_CATEGORIES]: handleUpdateReferenceProductsL4Categories,

  [REF_PRODUCT_SELECT]: handleSelectReferenceProduct,
  [REF_PRODUCT_SELECT_ERROR]: handleSelectReferenceProductError,

  [REF_PRODUCT_UPDATE_CONTENT]: (
    { referenceProducts, ...state },
    { productId, unit, multiplier, value },
  ) => ({
    ...state,
    referenceProducts: {
      ...referenceProducts,
      [productId]: {
        ...referenceProducts[productId],
        content_value: value,
        content_unit: unit,
        content_multiplier: multiplier,
        content_user_provided: true,
      },
    },
  }),

  [REF_PRODUCT_USE_FALLBACK_IMAGE]: handleUseFallbackImage,

  [REF_PRODUCT_LIST_EXISTING_MATCHES]: handleListExistingMatches,
  [REF_PRODUCT_LIST_EXISTING_MATCHES_SUCCESS]: handleListExistingMatchesSuccess,
  [REF_PRODUCT_LIST_EXISTING_MATCHES_ERROR]: handleListExistingMatchesError,
  [REF_PRODUCT_LIST_EXISTING_MATCHES_INVALID]: handleListExistingMatchesInvalid,

  [REF_PRODUCT_LIST_SUGGESTED_MATCHES]: handleListSuggestedMatches,
  [REF_PRODUCT_LIST_SUGGESTED_MATCHES_SUCCESS]: handleListSuggestedMatchesSuccess,
  [REF_PRODUCT_LIST_SUGGESTED_MATCHES_ERROR]: handleListSuggestedMatchesError,
  [REF_PRODUCT_LIST_SUGGESTED_MATCHES_INVALID]: handleListSuggestedMatchesInvalid,

  [MATCH_CREATE_MANUAL]: handleMatch,
  [MATCH_UPDATE_BASIC]: handleMatch,
  [MATCH_APPROVE]: handleMatch,
  [MATCH_REMOVE_APPROVAL]: handleMatch,
  [MATCH_DISCARD]: handleMatch,
  [MATCH_RESTORE]: handleMatch,

  [MATCH_CREATE_MANUAL_SUCCESS]: handleMatchSuccess,
  [MATCH_UPDATE_BASIC_SUCCESS]: handleMatchSuccess,
  [MATCH_APPROVE_SUCCESS]: handleMatchSuccess,
  [MATCH_REMOVE_APPROVAL_SUCCESS]: handleMatchSuccess,
  [MATCH_DISCARD_SUCCESS]: handleMatchSuccess,
  [MATCH_RESTORE_SUCCESS]: handleMatchSuccess,

  [MATCH_CREATE_MANUAL_ERROR]: handleMatchError,
  [MATCH_UPDATE_BASIC_ERROR]: handleMatchError,
  [MATCH_APPROVE_ERROR]: handleMatchError,
  [MATCH_REMOVE_APPROVAL_ERROR]: handleMatchError,
  [MATCH_DISCARD_ERROR]: handleMatchError,
  [MATCH_RESTORE_ERROR]: handleMatchError,

  [REF_PRODUCT_SELECT_MATCHED_SHOPS]: handleSelectMatchedShops,
  [REF_PRODUCT_SELECT_MATCHED_BRANDS]: handleSelectMatchedBrands,
  [REF_PRODUCT_SELECT_MATCHED_COUNTRIES]: handleSelectMatchedCountries,
  [REF_PRODUCT_SET_SHOW_DISCARDED]: handleSetShowDiscardedMatches,
  [REF_PRODUCT_SELECT_MATCHED_PRODUCTS_AVAILABILITY]:
    handleSelectMatchedProductsAvailability,
  [REF_PRODUCT_SELECT_MATCHED_PRODUCTS_FACTOR]: handleSelectMatchedProductsFactor,
}

const reducer = (state = initialState, action) => {
  const enhancedState = {
    ...initialState,
    ...state,
  }

  if (handlerMap[action.type]) {
    return handlerMap[action.type](enhancedState, action)
  }

  return enhancedState
}

export default reducer
