import React, { useMemo } from "react"
import "./LoginWidget.sass"

function LoginWidgetPresenter({
  user: { email, name: userName, customer: { code } = {}, customers = [] } = {},
  onSelectAccount,
}) {
  const customer = useMemo(
    () => customers.find(({ code: customerCode }) => code === customerCode) || {},
    [customers, code],
  )

  const { country, name } = customer

  let changeableProps

  let displayName
  if (userName && userName !== email) {
    displayName = `${userName} (${email})`
  } else {
    displayName = email
  }

  if (customers.length > 1) {
    changeableProps = {
      title: "Click to change the account you're connected to",
      style: {
        cursor: "pointer",
      },
      onClick: onSelectAccount,
    }
  }

  return (
    <div className="login-widget">
      <span className="email">
        {displayName && (
          <span>
            <strong>user:</strong> {displayName}
          </span>
        )}
        {code && (
          <span
            className={`customer ${customers.length > 1 ? "multiple" : ""}`}
            {...changeableProps}
          >
            <strong>account:</strong>
            <span className="name"> {name || code.toUpperCase()}</span>
            {country && (
              <span className="customer-country">
                <span className="country-name">({country})</span>
              </span>
            )}
          </span>
        )}
      </span>
    </div>
  )
}

export default LoginWidgetPresenter
