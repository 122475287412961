import React, { useState } from "react"
import html2canvas from "html2canvas"
import { IconDownload } from "@tabler/icons-react"
import LoadingButton from "@mui/lab/LoadingButton"
import { proxyURL } from "../../util/env"

function AssortmentAnalysisExportButton() {
  const [exporting, setExporting] = useState(false)

  async function handleExportClick() {
    setExporting(true)

    const element = document.querySelector(".aatoolCaptureDiv")
    element.scrollTo(0, 0)

    try {
      const canvas = await html2canvas(element, {
        imageTimeout: 300000,
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight + 200,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        proxy: proxyURL,
      })

      const link = document.createElement("a")
      link.download = "Assortment Analysis.png"
      link.href = canvas.toDataURL()
      link.click()
      setExporting(false)
    } catch (e) {
      setExporting(false)
      throw e
    }
  }

  return (
    <LoadingButton
      size="small"
      onClick={handleExportClick}
      color="primary"
      variant="outlined"
      loading={exporting}
      startIcon={<IconDownload size={16} />}
    >
      Download
    </LoadingButton>
  )
}

export { AssortmentAnalysisExportButton }
