import _PropTypes from "prop-types"
import React, { useState } from "react"
import ReactPlaceholder from "react-placeholder"

import { Autocomplete } from "../ui/Combobox"

import { buildAnalyticsEventName } from "../../util/user-monitoring"

import { useProductGroupFilter, useProductGroupsOutline } from "./hooks"

const analyticsEventName = buildAnalyticsEventName({
  feature: "Assortment Analysis",
  target: "Product Group",
  userAction: "Selected",
})

const autocompletePlaceholder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 256, height: 32, margin: "0" }}
    type="rect"
  />
)

export function AssortmentAnalysisProductSegmentAutocomplete() {
  const [search, setSearch] = useState("")

  const [productGroupId, setProductGroupId] = useProductGroupFilter()

  const { data: productGroups, isIdle, isFetching, isError } = useProductGroupsOutline()

  if (isIdle || isFetching) {
    return autocompletePlaceholder
  }

  if (isError) {
    return null
  }

  const productGroup =
    productGroups.find((opt) => opt.id === productGroupId) || productGroups[0]

  const productGroupChoices = productGroups.map(({ id, title }) => ({
    id,
    displayValue: <Autocomplete.Option search={search} text={title} />,
    value: { id, title },
  }))

  const productGroupsFiltered =
    search === ""
      ? productGroupChoices
      : productGroupChoices.filter((p) =>
          p.value.title.toLowerCase().includes(search.toLowerCase()),
        )

  return (
    <Autocomplete.Root
      analyticsEventName={analyticsEventName}
      value={productGroup}
      onChange={setProductGroupId}
      compareBy="title"
    >
      <Autocomplete.Input
        label="search reference products"
        placeholder=""
        displayValue={(option) => option?.title}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Autocomplete.Expander label="show reference product suggestions" />
      <Autocomplete.Options items={productGroupsFiltered} />
    </Autocomplete.Root>
  )
}

AssortmentAnalysisProductSegmentAutocomplete.propTypes = {}

AssortmentAnalysisProductSegmentAutocomplete.defaultProps = {}
