import React, { Suspense, memo } from "react"
import PropTypes from "prop-types"
import { Link, Route, Switch } from "react-router-dom"
import IconButton from "@material-ui/core/IconButton"
import PublishIcon from "@material-ui/icons/Publish"
import { TopMenu } from "@daltix/ui-react"
import mixpanel from "mixpanel-browser"
import Header from "../../components/header/Header"
import MatchingPanel from "../../components/matching-panel/MatchingPanel"
import MatchesReviewPanel from "../../components/matches-review-panel/MatchesReviewPanel"
import { isDebug } from "../../util/env"
import HtmlTitle from "../../components/html-title/HtmlTitle"
import { PortalFallback } from "../portal/Portal"
import MatchesOverviewPanel from "../../components/matches-overview/MatchesOverviewPanel"
import RestrictedRoute from "../../hocs/restricted-route"

function MatchingEnginePresenter({ customerDbName, loading, pathname, routes }) {
  const debugProps = {}

  if (isDebug()) {
    debugProps["data-db-name"] = customerDbName
  }

  if (loading) {
    return <PortalFallback />
  }

  return (
    <>
      <Header
        className="page-header"
        title="Matching"
        buttons={[
          <IconButton component={Link} to="/upload" key="upload">
            <PublishIcon />
          </IconButton>,
        ]}
      />
      <HtmlTitle>Matches</HtmlTitle>
      <TopMenu {...debugProps}>
        <TopMenu.Item
          active={pathname === routes.review}
          component={Link}
          to={routes.review}
          onClick={() => mixpanel.track("MT - Review Matches Tab Clicked")}
        >
          Review Matches
        </TopMenu.Item>
        <TopMenu.Item
          active={pathname === routes.match}
          component={Link}
          to={routes.match}
          onClick={() => mixpanel.track("MT - Make Matches Tab Clicked")}
        >
          Make Matches
        </TopMenu.Item>
        <TopMenu.Item
          active={pathname === routes.overview}
          component={Link}
          to={routes.overview}
          onClick={() => mixpanel.track("MT - Matches Overview Tab Clicked")}
        >
          Matches Overview
        </TopMenu.Item>
      </TopMenu>
      <Suspense>
        <Switch>
          <Route path={routes.review} component={MatchesReviewPanel} />
          <Route path={routes.match} component={MatchingPanel} />
          <RestrictedRoute path={routes.overview} component={MatchesOverviewPanel} />
        </Switch>
      </Suspense>
    </>
  )
}

MatchingEnginePresenter.propTypes = {
  routes: PropTypes.shape({
    match: PropTypes.string.isRequired,
    overview: PropTypes.string.isRequired,
    review: PropTypes.string.isRequired,
  }).isRequired,
  customerDbName: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default memo(MatchingEnginePresenter)
