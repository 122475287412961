import React, { useCallback, useMemo } from "react"
import { useDispatch } from "react-redux"
import { push } from "connected-react-router"
import Badge from "@material-ui/core/Badge"
import UploadIcon from "@material-ui/icons/CloudUploadRounded"
import { TopBar, Icon } from "@daltix/ui-react"
import mixpanel from "mixpanel-browser"
import PropTypes from "prop-types"
import LoginWidget from "../login-widget/LoginWidget"
import { actionCreators as userActions } from "../../ducks/user"
import { useAuth0 } from "../../hooks/use-authentication"
import { envLabel } from "../../util/env"
import RestrictedContent from "../restricted-content/RestrictedContent"
import {
  FEAT_APPS,
  FEAT_MATCHING_TOOL,
  FEAT_REPORTS,
  FEAT_UPLOAD_FILE,
} from "../../util/features"
import {
  PERM_READ_APPS,
  PERM_READ_MATCHES,
  PERM_READ_REPORTS,
  PERM_UPLOAD_FILES,
} from "../../util/permissions"
import "./Header.sass"
import { HELP_CENTER_URL } from "../../util/constants"

function Header({ title }) {
  const dispatch = useDispatch()
  const auth0 = useAuth0()

  const handleClickHome = useCallback(() => {
    dispatch(push("/portal"))
  }, [dispatch])

  const handleClickUpload = useCallback(() => {
    dispatch(push("/upload"))
  }, [dispatch])

  const handleClickDashboards = useCallback(() => {
    dispatch(push("/dashboards"))
  }, [dispatch])

  const handleClickMatching = useCallback(() => {
    dispatch(push("/matches"))
  }, [dispatch])

  const handleClickHelp = useCallback(() => {
    mixpanel.track("Open: Help Center")
    window.open(HELP_CENTER_URL, "help_center")
  }, [])

  function handleLogout(e) {
    e.preventDefault()

    mixpanel.track("Log Out")

    dispatch(userActions.logOut())

    auth0.logout({ returnTo: `${window.location.protocol}//${window.location.host}` })
  }

  const label = useMemo(() => envLabel(), [])

  const titleEl = (
    <span>
      {title && label && (
        <Badge
          className=""
          classes={{ badge: "badge-environment" }}
          badgeContent={envLabel()}
          color="secondary"
          style={{ verticalAlign: "baseline" }}
        >
          {title || " "}
        </Badge>
      )}
      {title && !label && <span>{title || " "}</span>}
    </span>
  )

  return (
    <TopBar appName={titleEl} className="mt-top-bar">
      <TopBar.IconsBar>
        <LoginWidget />
        <button type="button" onClick={handleClickHome}>
          <span className="sr-only">home button</span>
          <TopBar.Icon title="Home">
            <Icon type="home" />
          </TopBar.Icon>
        </button>

        <RestrictedContent
          requiredFeatures={FEAT_UPLOAD_FILE}
          requiredPermissions={PERM_UPLOAD_FILES}
        >
          <button type="button" onClick={handleClickUpload}>
            <span className="sr-only">upload button</span>
            <TopBar.Icon title="Upload">
              <UploadIcon />
            </TopBar.Icon>
          </button>
        </RestrictedContent>

        <RestrictedContent
          requiredFeatures={[
            [FEAT_APPS], // or
            [FEAT_REPORTS],
          ]}
          requiredPermissions={[
            [PERM_READ_APPS], // or
            [PERM_READ_REPORTS],
          ]}
        >
          <button type="button" onClick={handleClickDashboards}>
            <span className="sr-only">dashboards button</span>
            <TopBar.Icon title="Apps">
              <Icon type="chart" />
            </TopBar.Icon>
          </button>
        </RestrictedContent>

        <RestrictedContent
          requiredFeatures={FEAT_MATCHING_TOOL}
          requiredPermissions={PERM_READ_MATCHES}
        >
          <button type="button" onClick={handleClickMatching}>
            <span className="sr-only">matching button</span>
            <TopBar.Icon title="Matching">
              <Icon type="puzzle" />
            </TopBar.Icon>
          </button>
        </RestrictedContent>
        <button type="button" onClick={handleClickHelp}>
          <span className="sr-only">help center button</span>
          <TopBar.Icon title="Help Center">
            <Icon type="question" />
          </TopBar.Icon>
        </button>
        <button type="button" onClick={handleLogout}>
          <span className="sr-only">logout button</span>
          <TopBar.Icon title="Log Out">
            <Icon type="logout" />
          </TopBar.Icon>
        </button>
      </TopBar.IconsBar>
    </TopBar>
  )
}

Header.propTypes = {
  title: PropTypes.string,
}

Header.defaultProps = {
  title: "",
}

export default Header
