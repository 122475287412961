import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import "react-placeholder/lib/reactPlaceholder.css"

import mixpanel from "mixpanel-browser"
import userflow from "userflow.js"
import App from "./App"
import { initSentry } from "./util/error"
import * as serviceWorker from "./serviceWorker"
import "leaflet/dist/leaflet.css"
// import '@daltix/ui/dist/css/main.css';
import "@daltix/ui/dist/css/dx-buttons.css"
import "@daltix/ui/dist/css/dx-top-bar.css"
import "@daltix/ui/dist/css/dx-top-menu.css"
import "@daltix/ui/dist/css/dx-icon-font.css"
import "@daltix/ui/dist/css/dx-area-bar.css"
import "@daltix/ui/dist/css/dx-app-area.css"
import "@daltix/ui/dist/css/dx-cards.css"
import "@daltix/ui/dist/css/dx-errors.css"
import "@daltix/ui/dist/css/dx-labels.css"
import "@daltix/ui/dist/css/dx-expansion-panel.css"
// import '@daltix/ui/dist/css/dx-pagination.css';
import "@daltix/ui/dist/css/dx-factor.css"
import "@daltix/ui/dist/css/dx-forms.css"
import "@daltix/ui/dist/css/dx-material-form-components.css"
import "@daltix/ui/dist/css/dx-basic-styles.css"
import "@daltix/ui/dist/css/dx-matching-review.css"
import "@daltix/ui/dist/css/dx-biko.css"
import "@daltix/ui/dist/css/dx-svg-icons.css"
import "./styles/global.scss"
import "./styles/index.scss"
import "./styles/dx-hacks.scss"
import { getQueryParam } from "./util/query-param"
import { getEnv, isDebug, userflow as userflowConfig } from "./util/env"

initSentry()

mixpanel.init("d77acddaec563154aae9eb85340c9704", {
  debug: isDebug(),
  ignore_dnt: true,
})

mixpanel.unregister("utm_source")

mixpanel.register({
  source: getQueryParam("__source__") || "$direct",
  env: getEnv(),
})

userflow.init(userflowConfig.token)
userflow.setPageTrackingDisabled(true)

const app = (
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)

ReactDOM.render(app, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
// defineCustomElements(window);
