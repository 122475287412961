import {
  MATCH_MATCH,
  MATCH_GET_IDS,
  MATCH_LIST,
  MATCH_FILTER_BY_STATUS,
  MATCH_FILTER_BY_BRAND,
  MATCH_FILTER_BY_COUNTRY,
  MATCH_FILTER_BY_SHOP,
  MATCH_FILTER_BY_SOURCE,
  MATCH_FILTER_BY_AVAILABILITY,
  MATCH_LIST_COUNTRIES,
  MATCH_STATUS_SUMMARY,
  MATCH_CLEAR_LIST,
  MATCH_LIST_SOURCES,
  MATCH_TRIGGER_LIST,
  MATCH_SET_PAGE,
  MATCH_FILTER_BY_REF_PROD_QUERY,
  MATCH_START_FILTER_BY_REF_PROD_COUNTRY,
  MATCH_START_FILTER_BY_REF_PROD_FAMILY,
  MATCH_FILTER_BY_REF_PROD_COUNTRY,
  MATCH_FILTER_BY_REF_PROD_FAMILY,
  MATCH_START_FILTER_BY_REF_PROD_L1_CATEGORIES,
  MATCH_START_FILTER_BY_REF_PROD_L2_CATEGORIES,
  MATCH_START_FILTER_BY_REF_PROD_L3_CATEGORIES,
  MATCH_START_FILTER_BY_REF_PROD_L4_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES,
  MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES,
  MATCH_UPDATE_REF_PROD_L2_CATEGORIES,
  MATCH_UPDATE_REF_PROD_L3_CATEGORIES,
  MATCH_UPDATE_REF_PROD_L4_CATEGORIES,
  MATCH_SET_REVIEW_PAGE_SETTINGS,
  MATCH_GET_SHOPS,
  MATCH_CREATE_MANUAL,
  MATCH_UPDATE_BASIC,
  MATCH_APPROVE,
  MATCH_REMOVE_APPROVAL,
  MATCH_DISCARD,
  MATCH_RESTORE,
  MATCH_GET_REF_PROD_CATEGORIES,
  MATCH_FILTER_BY_FACTOR,
} from "./action-types"
import { DISCARDED } from "../../util/matching-types"
import { buildErrorNotification } from "../../util/notificationBuilder"
import { ORIGIN_EXISTING } from "../../util/match-origin"
import {
  defaultMatchStatusesForReview,
  STATUS_APPROVED,
  STATUS_DISCARDED,
  STATUS_REVIEW,
} from "../../util/match-status"

// match
export function match({
  referenceProductId,
  source,
  daltixProductId,
  shop,
  country,
  status,
  factor,
  reloadList,
}) {
  return {
    type: MATCH_MATCH,
    referenceProductId,
    source,
    daltixProductId,
    shop,
    country,
    status,
    factor,
    reloadList,
    errorNotification: () => {
      let message
      switch (status) {
        case DISCARDED:
          message = `Error discarding ${
            source === ORIGIN_EXISTING ? "match" : "suggestion"
          }`
          break
        default:
          message = `Error changing ${
            source === ORIGIN_EXISTING ? "match" : "suggestion"
          } status`
          break
      }

      return buildErrorNotification({
        message,
      })
    },
  }
}

export function createManualMatch({
  referenceProductId,
  source,
  daltixProductId,
  shop,
  country,
  factor,
  status,
}) {
  return {
    type: MATCH_CREATE_MANUAL,
    referenceProductId,
    source,
    daltixProductId,
    shop,
    country,
    factor,
    status,
    errorNotification: () =>
      buildErrorNotification({
        message: "Error creating manual match",
      }),
  }
}

export function updateMatchBasicInfo({
  source,
  referenceProductId,
  daltixProductId,
  factor,
  labels,
  comment,
}) {
  return {
    type: MATCH_UPDATE_BASIC,
    source,
    referenceProductId,
    daltixProductId,
    factor,
    labels,
    comment,
    errorNotification: () =>
      buildErrorNotification({
        message: "Error updating match",
      }),
  }
}

export function approveMatch({
  source,
  referenceProductId,
  daltixProductId,
  reloadList,
}) {
  return {
    type: MATCH_APPROVE,
    source,
    referenceProductId,
    daltixProductId,
    reloadList,
    status: STATUS_APPROVED,
    errorNotification: () =>
      buildErrorNotification({
        message: "Error approving match",
      }),
  }
}

export function removeMatchApproval({
  source,
  referenceProductId,
  daltixProductId,
  reloadList,
}) {
  return {
    type: MATCH_REMOVE_APPROVAL,
    source,
    referenceProductId,
    daltixProductId,
    reloadList,
    status: STATUS_REVIEW,
    errorNotification: () =>
      buildErrorNotification({
        message: "Error removing match approval",
      }),
  }
}

export function discardMatch({
  source,
  referenceProductId,
  daltixProductId,
  reloadList,
}) {
  return {
    type: MATCH_DISCARD,
    source,
    referenceProductId,
    daltixProductId,
    reloadList,
    status: STATUS_DISCARDED,
    errorNotification: () =>
      buildErrorNotification({
        message: "Error discarding match",
      }),
  }
}

export function restoreMatch({
  source,
  referenceProductId,
  daltixProductId,
  reloadList,
}) {
  return {
    type: MATCH_RESTORE,
    source,
    referenceProductId,
    daltixProductId,
    reloadList,
    status: STATUS_APPROVED,
    errorNotification: () =>
      buildErrorNotification({
        message: "Error restoring match",
      }),
  }
}

export function getMatchesByIds({ ids }) {
  return {
    type: MATCH_GET_IDS,
    ids,
  }
}

export function getMatchStatusSummary() {
  return {
    type: MATCH_STATUS_SUMMARY,
  }
}

export function listMatchSources() {
  return {
    type: MATCH_LIST_SOURCES,
  }
}

export function listCountriesWithMatches() {
  return {
    type: MATCH_LIST_COUNTRIES,
  }
}

export function triggerlistMatches() {
  return {
    type: MATCH_TRIGGER_LIST,
  }
}

export function listMatches({ clearTotal } = {}) {
  return {
    type: MATCH_LIST,
    requestId: Date.now(), // deprecated
    clearTotal,
  }
}

export function getShopsFromMatches() {
  return {
    type: MATCH_GET_SHOPS,
  }
}

export function getReferenceProductsCategoriesFromMatches() {
  return {
    type: MATCH_GET_REF_PROD_CATEGORIES,
  }
}

export function clearMatchesList(options = {}) {
  return {
    type: MATCH_CLEAR_LIST,
    options,
  }
}

export function filterMatchesBySource(sources = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_SOURCE,
    sources,
    options,
  }
}

export function filterMatchesByStatus(
  statuses = defaultMatchStatusesForReview,
  options = {},
) {
  return {
    type: MATCH_FILTER_BY_STATUS,
    statuses,
    options,
  }
}

export function filterMatchesByBrand(brands = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_BRAND,
    brands,
    options,
  }
}

export function filterMatchesByCountry(countries = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_COUNTRY,
    countries,
    options,
  }
}

export function filterMatchesByShop(shops = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_SHOP,
    shops,
    options,
  }
}

export function filterMatchesByAvailability(availabilities = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_AVAILABILITY,
    availabilities,
    options,
  }
}

export function filterMatchesByFactor(factors = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_FACTOR,
    factors,
    options,
  }
}

export function filterMatchesByRefProdQuery(query, options = {}) {
  return {
    type: MATCH_FILTER_BY_REF_PROD_QUERY,
    query,
    options,
  }
}

export function startFilterMatchesByRefProdCountries(countries = [], options = {}) {
  return {
    type: MATCH_START_FILTER_BY_REF_PROD_COUNTRY,
    countries,
    options,
  }
}

export function startFilterMatchesByRefProdFamilies(families = [], options = {}) {
  return {
    type: MATCH_START_FILTER_BY_REF_PROD_FAMILY,
    families,
    options,
  }
}

export function filterMatchesByRefProdCountries(countries = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_REF_PROD_COUNTRY,
    countries,
    options,
  }
}

export function filterMatchesByRefProdFamilies(families = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_REF_PROD_FAMILY,
    families,
    options,
  }
}

export function startFilterMatchesByRefProdL1Categories(categories = [], options = {}) {
  return {
    type: MATCH_START_FILTER_BY_REF_PROD_L1_CATEGORIES,
    categories,
    options,
  }
}

export function filterMatchesByRefProdL1Categories(
  categories = [],
  tree,
  options = {},
) {
  return {
    type: MATCH_FILTER_BY_REF_PROD_L1_CATEGORIES,
    categories,
    tree,
    options,
  }
}

export function startFilterMatchesByRefProdL2Categories(categories = [], options = {}) {
  return {
    type: MATCH_START_FILTER_BY_REF_PROD_L2_CATEGORIES,
    categories,
    options,
  }
}

export function filterMatchesByRefProdL2Categories(categories = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_REF_PROD_L2_CATEGORIES,
    categories,
    options,
  }
}

export function updateRefProdL2Categories(tree, options = {}) {
  return {
    type: MATCH_UPDATE_REF_PROD_L2_CATEGORIES,
    tree,
    options,
  }
}

export function startFilterMatchesByRefProdL3Categories(categories = [], options = {}) {
  return {
    type: MATCH_START_FILTER_BY_REF_PROD_L3_CATEGORIES,
    categories,
    options,
  }
}

export function filterMatchesByRefProdL3Categories(categories = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_REF_PROD_L3_CATEGORIES,
    categories,
    options,
  }
}

export function updateRefProdL3Categories(tree, options = {}) {
  return {
    type: MATCH_UPDATE_REF_PROD_L3_CATEGORIES,
    tree,
    options,
  }
}

export function startFilterMatchesByRefProdL4Categories(categories = [], options = {}) {
  return {
    type: MATCH_START_FILTER_BY_REF_PROD_L4_CATEGORIES,
    categories,
    options,
  }
}

export function filterMatchesByRefProdL4Categories(categories = [], options = {}) {
  return {
    type: MATCH_FILTER_BY_REF_PROD_L4_CATEGORIES,
    categories,
    options,
  }
}

export function updateRefProdL4Categories(tree, options = {}) {
  return {
    type: MATCH_UPDATE_REF_PROD_L4_CATEGORIES,
    tree,
    options,
  }
}

export function setMatchesListPage(page, options = {}) {
  return {
    type: MATCH_SET_PAGE,
    page,
    options,
  }
}

export function setMatchesReviewPageSettings(settings = {}, options = {}) {
  return {
    type: MATCH_SET_REVIEW_PAGE_SETTINGS,
    settings,
    options,
  }
}
