import {
  Box,
  CircularProgress,
  TextField,
  Tooltip,
  inputBaseClasses,
  useTheme,
} from "@mui/material"
import { debounce } from "lodash"
import { string } from "prop-types"
import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { actionCreators as matchProductActions } from "../../../../ducks/matches"
import useRestricted from "../../../../hooks/use-restricted"
import { ORIGIN_SEARCH } from "../../../../util/match-origin"
import { PERM_UPDATE_MATCHES } from "../../../../util/permissions"

function Comment({ comment, daltixProductId, referenceProductId, matchId }) {
  const theme = useTheme()
  const isUpdater = useRestricted({ requiredPermissions: PERM_UPDATE_MATCHES })
  const isDisabled = !matchId
  // TODO: loading state
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const updatedComment = useSelector(
    ({ repositories }) =>
      repositories.MatchedProductsRepository.documents[matchId]?.data?.comment,
  )

  const effectiveComment = updatedComment || comment
  const [value, setValue] = useState(effectiveComment)

  const update = useRef(
    debounce((newComment) => {
      dispatch(
        matchProductActions.updateMatchBasicInfo({
          source: ORIGIN_SEARCH,
          referenceProductId,
          daltixProductId,
          comment: newComment,
        }),
      )
    }, 1500),
  )

  function handleChange({ target }) {
    setValue(target.value)
    update.current(target.value)
  }

  return (
    <Tooltip title={value?.length > 25 ? value : ""} arrow placement="bottom">
      <TextField
        value={value}
        disabled={!isUpdater || isDisabled}
        onChange={handleChange}
        fullWidth
        sx={{
          [`& .${inputBaseClasses.input}`]: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: theme.typography.body2.fontSize,
          },
        }}
        InputProps={{
          size: "small",
          endAdornment: loading ? (
            <Box>
              <CircularProgress size={16} />
            </Box>
          ) : undefined,
        }}
      />
    </Tooltip>
  )
}

Comment.propTypes = {
  comment: string,
  daltixProductId: string.isRequired,
  referenceProductId: string.isRequired,
  matchId: string,
}

Comment.defaultProps = {
  comment: undefined,
  matchId: "",
}

export { Comment }
