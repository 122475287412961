import { map } from "rxjs/operators"
import { ofType } from "redux-observable"
import { Logger } from "../util/log"
import {
  successType,
  errorType,
  buildEpic,
  cancelType,
} from "../util/redux-observable-helpers"
import * as api from "../api/service"
import * as refProdActionTypes from "./reference-products/action-types"
import {
  MATCH_CREATE_MANUAL,
  MATCH_UPDATE_BASIC,
  MATCH_APPROVE_SUCCESS,
  MATCH_APPROVE,
  MATCH_REMOVE_APPROVAL_SUCCESS,
  MATCH_REMOVE_APPROVAL,
  MATCH_DISCARD,
  MATCH_RESTORE,
  MATCH_CREATE_MANUAL_SUCCESS,
  MATCH_DISCARD_SUCCESS,
  MATCH_RESTORE_SUCCESS,
  MATCH_UPDATE_BASIC_SUCCESS,
  MATCH_CREATE_MANUAL_ERROR,
  MATCH_UPDATE_BASIC_ERROR,
  MATCH_APPROVE_ERROR,
  MATCH_REMOVE_APPROVAL_ERROR,
  MATCH_DISCARD_ERROR,
  MATCH_RESTORE_ERROR,
} from "./matches/action-types"
import { ORIGIN_SEARCH, ORIGIN_COMPARISON } from "../util/match-origin"
import { INTERNAL_SOURCE_CUST_MANUAL } from "../util/match-source"

const log = new Logger("ducks:daltix-product")

// action types

const DX_PRODUCT_GET_BY_IDS = "@daltixProduct/GET_BY_IDS"
const DX_PRODUCT_GET_BY_IDS_SUCCESS = successType(DX_PRODUCT_GET_BY_IDS)
const DX_PRODUCT_GET_BY_IDS_ERROR = errorType(DX_PRODUCT_GET_BY_IDS)

const DX_PRODUCT_SET_SEARCH_TERM = "@daltixProduct/SET_SEARCH_TERM"

const DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS = "@daltixProduct/AUTOCOMPLETE_SUGGESTIONS"
const DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS_CANCEL = cancelType(
  DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS,
)
const DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS_SUCCESS = successType(
  DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS,
)
const DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS_ERROR = errorType(
  DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS,
)

const DX_PRODUCT_SEARCH = "@daltixProduct/SEARCH"
const DX_PRODUCT_SEARCH_SUCCESS = successType(DX_PRODUCT_SEARCH)
const DX_PRODUCT_SEARCH_ERROR = errorType(DX_PRODUCT_SEARCH)

const DX_PRODUCT_GET_SEARCH_FILTERS = "@daltixProduct/GET_SEARCH_FILTERS"
const DX_PRODUCT_GET_SEARCH_FILTERS_SUCCESS = successType(DX_PRODUCT_GET_SEARCH_FILTERS)
const DX_PRODUCT_GET_SEARCH_FILTERS_ERROR = errorType(DX_PRODUCT_GET_SEARCH_FILTERS)

const DX_PRODUCT_CLEAR_NEW_SEARCH_MATCHES = "@daltixProduct/CLEAR_NEW_SEARCH_MATCHES"
const DX_PRODUCT_CLEAR_SEARCH_RESULT = "@daltixProduct/CLEAR_SEARCH_RESULT"

const actionTypes = {
  DX_PRODUCT_GET_BY_IDS,
  DX_PRODUCT_GET_BY_IDS_SUCCESS,
  DX_PRODUCT_GET_BY_IDS_ERROR,

  DX_PRODUCT_SET_SEARCH_TERM,

  DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS,
  DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS_SUCCESS,
  DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS_ERROR,

  DX_PRODUCT_SEARCH,
  DX_PRODUCT_SEARCH_SUCCESS,
  DX_PRODUCT_SEARCH_ERROR,

  DX_PRODUCT_GET_SEARCH_FILTERS,
  DX_PRODUCT_GET_SEARCH_FILTERS_SUCCESS,
  DX_PRODUCT_GET_SEARCH_FILTERS_ERROR,

  DX_PRODUCT_CLEAR_NEW_SEARCH_MATCHES,
  DX_PRODUCT_CLEAR_SEARCH_RESULT,
}

// action creators

const getDxProductsByIds = (ids) => ({
  type: DX_PRODUCT_GET_BY_IDS,
  ids,
})

const setSearchTerm = (value) => ({
  type: DX_PRODUCT_SET_SEARCH_TERM,
  value,
})

const getDxProductsAutoCompleteSuggestions = (query, options) => ({
  type: DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS,
  query,
  options: {
    ...options,
    fields: options.fields ?? [
      "name",
      "images",
      "downloaded_images",
      "brand",
      "shop",
      "eans",
    ],
  },
})

const searchDxProducts = (query, options) => ({
  type: DX_PRODUCT_SEARCH,
  query,
  options: {
    ...options,
    fields: ["_id"],
  },
})

const getSearchFilters = (query) => ({
  type: DX_PRODUCT_GET_SEARCH_FILTERS,
  query,
})

const clearSearchResult = (id) => ({
  type: DX_PRODUCT_CLEAR_SEARCH_RESULT,
  id,
})

const clearNewSearchMatches = (id) => ({
  type: DX_PRODUCT_CLEAR_NEW_SEARCH_MATCHES,
  id,
})

const actionCreators = {
  getDxProductsByIds,
  getSearchFilters,
  setSearchTerm,
  getDxProductsAutoCompleteSuggestions,
  searchDxProducts,
  clearSearchResult,
  clearNewSearchMatches,
}

// epics

const getDxProductsByIdsEpic = buildEpic(DX_PRODUCT_GET_BY_IDS, ({ ids }) =>
  api.getDaltixProducts(ids.map((id) => ({ id }))),
)

const getDxProductsAutoCompleteSuggestionsEpic = buildEpic(
  DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS,
  ({ query, options }) => api.searchDaltixProducts(query, options),
)

const searchDxProductsEpic = buildEpic(
  DX_PRODUCT_SEARCH,
  (
    { query, options },
    {
      refProduct: {
        selectedMatchedBrands = [],
        selectedMatchedShops = [],
        selectedMatchedCountries = [],
        selectedMatchedProductsAvailability = [],
        selectedMatchedProductsFactor = [],
      },
    },
  ) => {
    const finalOptions = { ...options }

    if (selectedMatchedBrands.length > 0) {
      finalOptions.brands = selectedMatchedBrands
    }

    if (selectedMatchedShops.length > 0) {
      finalOptions.shops = selectedMatchedShops
    }

    if (selectedMatchedCountries.length > 0) {
      finalOptions.countries = selectedMatchedCountries
    }

    if (selectedMatchedProductsAvailability.length > 0) {
      finalOptions.availabilities = selectedMatchedProductsAvailability
    }

    if (selectedMatchedProductsFactor.length > 0) {
      finalOptions.factors = selectedMatchedProductsFactor
    }

    return api.searchDaltixProducts(query, finalOptions)
  },
)

const getSearchFiltersEpic = buildEpic(DX_PRODUCT_GET_SEARCH_FILTERS, ({ query }) =>
  api.getSearchFilters(query),
)

// const loadInfoAfterSearchSuccessEpic = (action$, state$) => action$.pipe(
//   ofType(DX_PRODUCT_SEARCH_SUCCESS),
//   withLatestFrom(state$),
//   map(([{ payload: { products = [] } }, { daltixProduct: { daltixProducts } }]) => {
//     const missing = products.filter(({ daltix_id: daltixId }) => !daltixProducts[daltixId]);
//     log.debug('missing searched products info:', missing);
//     return missing;
//   }),
//   filter(missing => missing.length > 0),
//   map((missing) => getDxProductsByIds(missing.map(({ daltix_id: daltixId }) => daltixId))),
// );

const cancelSuggestionsOnSearch = (action$) =>
  action$.pipe(
    ofType(DX_PRODUCT_SEARCH),
    map(() => ({
      type: DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS_CANCEL,
    })),
  )

const epics = [
  getDxProductsByIdsEpic,
  getDxProductsAutoCompleteSuggestionsEpic,
  getSearchFiltersEpic,
  searchDxProductsEpic,
  cancelSuggestionsOnSearch,
  // loadInfoAfterSearchSuccessEpic,
]

// handlers

const handleGetByIdsSuccess = (state, { payload }) => {
  const { daltixProducts } = state

  const mergedDaltixProducts = {
    ...daltixProducts,
  }

  payload.forEach((prod) => {
    mergedDaltixProducts[prod.id] = {
      id: prod.id,
      ...daltixProducts[prod.id],
      ...prod,
      error: prod.error && new Error(prod.error),
    }
  })

  return {
    ...state,
    daltixProducts: mergedDaltixProducts,
  }
}

const handleGetByIdsError = (state, { error, parent: { ids } }) => {
  log.error("Error loading products:", error.message)

  const { daltixProducts } = state

  const mergedDaltixProducts = {
    ...daltixProducts,
  }

  ids.forEach((id) => {
    mergedDaltixProducts[id] = {
      id,
      ...daltixProducts[id],
      error,
    }
  })

  return {
    ...state,
    daltixProducts: mergedDaltixProducts,
  }

  // return state;
}

// search term

const handleSetSearchTerm = (state, { value }) => ({
  ...state,
  searchTerm: value,
})

// auto complete suggestions
const handleAutoCompleteSuggestionsSuccess = (
  state,
  {
    payload: {
      meta: { total },
      products,
    },
  },
) => ({
  ...state,
  autoCompleteSuggestions: products,
  totalAutoCompleteSuggestions: total,
})

const handleAutoCompleteSuggestionsError = (state) => ({
  ...state,
  autoCompleteSuggestions: [],
  totalAutoCompleteSuggestions: undefined,
})

const handleSearch = (state, { options: { page = 1 } }) => {
  const { searchResults } = state

  return {
    ...state,
    searchInProgress: true,
    autoCompleteSuggestions: [],
    totalAutoCompleteSuggestions: undefined,
    searchResults: page === 1 ? [] : searchResults,
  }
}

const handleSearchSuccess = (
  state,
  {
    parent: {
      query,
      options: { page = 1 },
    },
    payload: {
      meta: { total, filters },
      products,
    },
  },
) => {
  const { searchResults } = state
  const existing = {}
  searchResults.forEach((id) => {
    existing[id] = true
  })
  let newResults = products.map(({ id }) => id)

  if (page !== 1) {
    newResults = newResults.filter((id) => !existing[id])
  }

  return {
    ...state,
    searchInProgress: false,
    totalSearchResults: total,
    searchResults: page === 1 ? newResults : [...searchResults, ...newResults],
    searchResultsFilters: filters,
    lastSearchedPage: page,
    lastSearchTerm: query,
  }
}

const handleSearchError = (state) => ({
  ...state,
  searchInProgress: false,
  totalSearchResults: undefined,
  searchResults: [],
  lastSearchedPage: undefined,
})

const handleGetSearchFilters = (state) => ({
  ...state,
})

const handleGetSearchFiltersSuccess = (
  state,
  {
    payload: {
      meta: { total },
      options,
    },
  },
) => ({
  ...state,
  searchFilterOptions: options,
  noFilterTotalSearchResults: total,
})

const handleGetSearchFiltersError = (state) => ({
  ...state,
  searchFilterOptions: undefined,
  nonFilterTotalSearchResults: undefined,
})

const handleReferenceProductSelect = (state) => ({
  ...state,
  searchTerm: "",
  searchInProgress: false,
  totalSearchResults: undefined,
  noFilterTotalSearchResults: undefined,
  searchResults: [],
  searchResultsFilters: undefined,
  searchFilterOptions: undefined,
  autoCompleteSuggestions: [],
  totalAutoCompleteSuggestions: undefined,
  pendingMatches: {},
})

const handleMatch = (
  state,
  { source, referenceProductId, daltixProductId, status, factor, labels, comment },
) => {
  if (source === ORIGIN_SEARCH) {
    const { searchResultsMatchingStatus, pendingMatches } = state

    // if (status === DISCARDED) {
    //   status = STATUS_NEW;
    //   // factor = undefined;
    // }

    const previousStatus = searchResultsMatchingStatus[daltixProductId]

    return {
      ...state,
      searchResultsMatchingStatus: {
        ...searchResultsMatchingStatus,
        [referenceProductId]: {
          ...searchResultsMatchingStatus[referenceProductId],
          [daltixProductId]: {
            status:
              status ||
              (
                (searchResultsMatchingStatus[referenceProductId] || {})[
                  daltixProductId
                ] || {}
              ).status,
            mult_factor:
              factor ||
              (
                (searchResultsMatchingStatus[referenceProductId] || {})[
                  daltixProductId
                ] || {}
              ).mult_factor,
            labels:
              labels ||
              (
                (searchResultsMatchingStatus[referenceProductId] || {})[
                  daltixProductId
                ] || {}
              ).labels,
            comment:
              comment ||
              (
                (searchResultsMatchingStatus[referenceProductId] || {})[
                  daltixProductId
                ] || {}
              ).comment,
            match_source:
              (
                (searchResultsMatchingStatus[referenceProductId] || {})[
                  daltixProductId
                ] || {}
              ).match_source || INTERNAL_SOURCE_CUST_MANUAL,
          },
        },
      },
      pendingMatches: {
        ...pendingMatches,
        [daltixProductId]: previousStatus,
      },
    }
  }

  return state
}

const handleMatchSuccess = (state, { parent: { source, daltixProductId } }) => {
  if (source === ORIGIN_SEARCH || source === ORIGIN_COMPARISON) {
    const {
      pendingMatches: { [daltixProductId]: match, ...pendingMatches },
    } = state

    return {
      ...state,
      pendingMatches,
    }
  }
  return state
}

const handleMatchError = (
  state,
  { parent: { source, referenceProductId, daltixProductId } },
) => {
  if (source === ORIGIN_SEARCH) {
    const {
      searchResultsMatchingStatus,
      pendingMatches: { [daltixProductId]: previousStatus, ...pendingMatches },
    } = state

    return {
      ...state,
      searchResultsMatchingStatus: {
        ...searchResultsMatchingStatus,
        [referenceProductId]: {
          ...searchResultsMatchingStatus[referenceProductId],
          [daltixProductId]: {
            ...searchResultsMatchingStatus[referenceProductId][daltixProductId],
            ...previousStatus,
          },
        },
      },
      pendingMatches,
    }
  }

  return state
}

const handleClearSearchResult = (state, { id }) => {
  const { searchResults } = state
  return {
    ...state,
    searchResults: searchResults.filter((value) => value !== id),
  }
}

const handleClearNewSearchMatches = (state, { id }) => {
  const {
    searchResultsMatchingStatus: { [id]: newMatches, ...searchResultsMatchingStatus },
  } = state
  return {
    ...state,
    searchResultsMatchingStatus,
  }
}

// reducer

const initalState = {
  daltixProducts: {},
  autoCompleteSuggestions: [],
  searchTerm: "",
  searchResults: [],
  pendingMatches: {},
  searchResultsMatchingStatus: {},
}

const reducer = (state = initalState, { type, ...action }) => {
  switch (type) {
    case DX_PRODUCT_GET_BY_IDS_SUCCESS:
      return handleGetByIdsSuccess(state, action)
    case DX_PRODUCT_GET_BY_IDS_ERROR:
      return handleGetByIdsError(state, action)

    case DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS_SUCCESS:
      return handleAutoCompleteSuggestionsSuccess(state, action)
    case DX_PRODUCT_AUTOCOMPLETE_SUGGESTIONS_ERROR:
      return handleAutoCompleteSuggestionsError(state, action)

    case DX_PRODUCT_SET_SEARCH_TERM:
      return handleSetSearchTerm(state, action)

    case DX_PRODUCT_GET_SEARCH_FILTERS:
      return handleGetSearchFilters(state, action)
    case DX_PRODUCT_GET_SEARCH_FILTERS_SUCCESS:
      return handleGetSearchFiltersSuccess(state, action)
    case DX_PRODUCT_GET_SEARCH_FILTERS_ERROR:
      return handleGetSearchFiltersError(state, action)

    case DX_PRODUCT_SEARCH:
      return handleSearch(state, action)
    case DX_PRODUCT_SEARCH_SUCCESS:
      return handleSearchSuccess(state, action)
    case DX_PRODUCT_SEARCH_ERROR:
      return handleSearchError(state, action)

    case refProdActionTypes.REF_PRODUCT_SELECT:
      return handleReferenceProductSelect(state, action)

    case MATCH_CREATE_MANUAL:
    case MATCH_UPDATE_BASIC:
    case MATCH_APPROVE:
    case MATCH_REMOVE_APPROVAL:
    case MATCH_DISCARD:
    case MATCH_RESTORE:
      return handleMatch(state, action)

    case MATCH_CREATE_MANUAL_SUCCESS:
    case MATCH_UPDATE_BASIC_SUCCESS:
    case MATCH_APPROVE_SUCCESS:
    case MATCH_REMOVE_APPROVAL_SUCCESS:
    case MATCH_DISCARD_SUCCESS:
    case MATCH_RESTORE_SUCCESS:
      return handleMatchSuccess(state, action)

    case MATCH_CREATE_MANUAL_ERROR:
    case MATCH_UPDATE_BASIC_ERROR:
    case MATCH_APPROVE_ERROR:
    case MATCH_REMOVE_APPROVAL_ERROR:
    case MATCH_DISCARD_ERROR:
    case MATCH_RESTORE_ERROR:
      return handleMatchError(state, action)

    case DX_PRODUCT_CLEAR_SEARCH_RESULT:
      return handleClearSearchResult(state, action)

    case DX_PRODUCT_CLEAR_NEW_SEARCH_MATCHES:
      return handleClearNewSearchMatches(state, action)

    default:
  }

  return state
}

// exports

export { actionTypes, actionCreators, epics }

export default reducer
