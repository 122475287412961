import { filter, tap } from "rxjs/operators"
import { ofType } from "redux-observable"
import mixpanel from "mixpanel-browser"
import { successType, errorType, buildEpic } from "../util/redux-observable-helpers"
import * as api from "../api/service"
import { DASHBOARD, REPORT } from "../util/dashboard-types"

// Action Types

const DASHBOARD_LIST = "@dashboard/LIST"
const DASHBOARD_LIST_SUCCESS = successType(DASHBOARD_LIST)
const DASHBOARD_LIST_ERROR = errorType(DASHBOARD_LIST)

const DASHBOARD_DETAIL = "@dashboard/DETAIL"
const DASHBOARD_DETAIL_SUCCESS = successType(DASHBOARD_DETAIL)
const DASHBOARD_DETAIL_ERROR = errorType(DASHBOARD_DETAIL)

const DASHBOARD_SET_PAGE = "@dashboard/SET_PAGE"
const DASHBOARD_SELECT_TYPES = "@dashboard/SELECT_TYPES"

const actionTypes = {
  DASHBOARD_LIST,
  DASHBOARD_LIST_SUCCESS,
  DASHBOARD_LIST_ERROR,

  DASHBOARD_DETAIL,
  DASHBOARD_DETAIL_SUCCESS,
  DASHBOARD_DETAIL_ERROR,

  DASHBOARD_SET_PAGE,
  DASHBOARD_SELECT_TYPES,
}

// Action Creators

const listDashboards = () => ({
  type: DASHBOARD_LIST,
})

const setDashboardsPage = (page) => ({
  type: DASHBOARD_SET_PAGE,
  page,
})

const selectDashboardTypes = (types) => ({
  type: DASHBOARD_SELECT_TYPES,
  types,
})

const detailDashboard = (id) => ({
  type: DASHBOARD_DETAIL,
  id,
})

const actionCreators = {
  listDashboards,
  detailDashboard,
  setDashboardsPage,
  selectDashboardTypes,
}

// Epics

const listDashboardsEpic = buildEpic(DASHBOARD_LIST, () =>
  api.listDashboards({ pageSize: 1000 }),
)

const detailDashboardEpic = buildEpic(DASHBOARD_DETAIL, ({ id }) =>
  api.detailDashboard(id),
)

const trackDashboardTypeSelectEpic = (action$) =>
  action$.pipe(
    ofType(DASHBOARD_SELECT_TYPES),
    tap(({ types }) => {
      mixpanel.track("Select Dashboard Types", {
        types,
      })
    }),
    filter(() => false),
  )

const epics = [listDashboardsEpic, detailDashboardEpic, trackDashboardTypeSelectEpic]

// reducer

const initalState = {
  list: [],
  dashboards: {},
  selectedTypes: [DASHBOARD, REPORT],
}

const reducer = (state = initalState, { type, ...action }) => {
  switch (type) {
    case DASHBOARD_LIST:
      return {
        ...state,
        loadingList: true,
      }

    case DASHBOARD_LIST_SUCCESS: {
      const ids = []
      const dashboards = {}

      action.payload.dashboards.forEach((dashboard) => {
        ids.push(dashboard.id)
        dashboards[dashboard.id] = dashboard
      })

      return {
        ...state,
        list: ids,
        dashboards,
        loadingList: false,
      }
    }

    case DASHBOARD_LIST_ERROR:
      return {
        ...state,
        list: [],
        page: undefined,
        total: undefined,
        loadingList: false,
      }

    case DASHBOARD_DETAIL_SUCCESS:
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [action.parent.id]: {
            ...state.dashboards[action.parent.id],
            ...action.payload,
            ready: true,
            error: undefined,
          },
        },
      }

    case DASHBOARD_SET_PAGE:
      return {
        ...state,
        page: action.page,
      }

    case DASHBOARD_SELECT_TYPES:
      return {
        ...state,
        selectedTypes: action.types,
      }

    case DASHBOARD_DETAIL_ERROR:
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [action.parent.id]: {
            ...state.dashboards[action.parent.id],
            ready: undefined,
            error: action.error,
          },
        },
      }

    default:
      return state
  }
}

// exports

export { actionTypes, actionCreators, epics }

export default reducer
